import {
  landingUserGuide,
  userGuide1,
  userGuide2,
  userGuide3,
  userGuide4,
  userGuide5
} from '../../../assets';
import { GradientImage } from '../../../components';
import './index.css';
import { Col, Container, Image, Nav, Row } from 'react-bootstrap';
import { FaChevronRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { shorthand } from '../../../utils';

const UserGuide = () => {
  const { t } = useTranslation();
  return (
    <Col>
      <GradientImage
        image={landingUserGuide}
        caption={t('ABOUT-009')}
        description={t('ABOUT-010')}
      />

      <Container className="my-5">
        <Row xxl="3" xl="3" lg="3" md="2" sm="2" xs="1">
          {[
            {
              id: 'about-partnership',
              image: userGuide1,
              title: t('ABOUT-004'),
              description: t('ABOUT-012')
            },
            {
              id: 'partnership-steps',
              image: userGuide2,
              title: t('ABOUT-008'),
              description: t('ABOUT-013')
            },
            {
              id: 'register',
              image: userGuide3,
              title: t('ABOUT-011'),
              description: t('ABOUT-014')
            },
            {
              id: 'reset-password',
              image: userGuide4,
              title: t('ABOUT-006'),
              description: t('ABOUT-015')
            },
            {
              id: 'upload-legal-doc',
              image: userGuide5,
              title: t('ABOUT-030'),
              description: t('ABOUT-031')
            }
          ].map((x, i) => (
            <Col
              key={i.toString()}
              className="mt-5"
              data-aos="fade-right"
              data-aos-duration={shorthand.aosDuration}>
              <div className="d-flex justify-content-between align-items-center card-guide">
                <Image
                  src={x.image}
                  fluid
                  className="img-fluid shadow-2-strong mx-2 ms-xxl-5 ms-xl-3 ms-lg-3 ms-md-3"
                />
                <div className="px-5 card-guide-text">{x.title}</div>
              </div>
              <Col>
                <Col>
                  <p style={{ marginTop: 20 }}>{x.description}</p>
                  <Nav.Link href={`/guides/${x.id}`} className="link-text-timeline fw-bold">
                    {t('ABOUT-016')}{' '}
                    <FaChevronRight className="align-self-center link-text-timeline" />
                  </Nav.Link>
                </Col>
              </Col>
            </Col>
          ))}
        </Row>
      </Container>
    </Col>
  );
};

export default UserGuide;
