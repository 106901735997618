import api from '../../../apis';
import { errorParser } from '../../../utils';
import { FETCH_TERMS_AND_CONDITIONS_LIST } from '../../types/termsandconditions';

const request = () => ({
  type: FETCH_TERMS_AND_CONDITIONS_LIST.REQUEST
});

const success = (payload) => ({
  type: FETCH_TERMS_AND_CONDITIONS_LIST.SUCCESS,
  payload
});

const failed = (error) => ({
  type: FETCH_TERMS_AND_CONDITIONS_LIST.FAILED,
  payload: error
});

const fetchData = (params, isExternal) => async (dispatch) => {
  try {
    dispatch(request());
    const { data } = await (!isExternal
      ? api.termsAndConditions.getAll(params)
      : api.termsAndConditions.getAllExternal(params));
    dispatch(success(data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(failed(message));
  }
};

export { fetchData };
