import { PRODUCTCATEGORY } from '../../types';

const initialState = {
  data: null,
  error: null,
  meta: null,
  isLoading: null
};

export const productCategoryReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES.SUCCESS:
      return {
        ...initialState,
        data: payload.data,
        meta: payload.meta
      };
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES.FAILED:
      return {
        ...initialState,
        error: payload
      };
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES.RESET:
      return initialState;
    default:
      return state;
  }
};

export const productCategoryDetailReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_DETAIL.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_DETAIL.SUCCESS:
      return {
        ...initialState,
        data: payload
      };
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_DETAIL.FAILED:
      return {
        ...initialState,
        error: payload
      };
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_DETAIL.RESET:
      return initialState;
    default:
      return state;
  }
};

export const productCategoryAddReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_ADD.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_ADD.SUCCESS:
      return {
        ...initialState,
        data: payload.data,
        type: type
      };
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_ADD.FAILED:
      return {
        ...initialState,
        error: payload,
        type: type
      };
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_ADD.RESET:
      return initialState;
    default:
      return state;
  }
};

export const productCategoryUpdateReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_UPDATE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_UPDATE.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_UPDATE.FAILED:
      return {
        ...initialState,
        error: payload,
        type: type
      };
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_UPDATE.RESET:
      return initialState;
    default:
      return state;
  }
};

export const productCategoryRemoveReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_REMOVE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_REMOVE.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_REMOVE.FAILED:
      return {
        ...initialState,
        type: type,
        error: payload
      };
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_REMOVE.RESET:
      return initialState;
    default:
      return state;
  }
};

export const productCategoryAddImageReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_ADD_IMAGE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_ADD_IMAGE.SUCCESS:
      return {
        ...initialState,
        data: payload
      };
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_ADD_IMAGE.FAILED:
      return {
        ...initialState,
        error: payload
      };
    default:
      return state;
  }
};

export const productCategoryRemoveImageReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_REMOVE_IMAGE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_REMOVE_IMAGE.SUCCESS:
      return {
        ...initialState,
        data: payload
      };
    case PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_REMOVE_IMAGE.FAILED:
      return {
        ...initialState,
        error: payload
      };
    default:
      return state;
  }
};
