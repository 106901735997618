import api from '../../../apis';
import { errorParser } from '../../../utils';

import { PARTNER } from '../../types';

export const fetchRequest = (type) => ({
  type: PARTNER[type].REQUEST
});

export const fetchSuccess = (type, data) => ({
  type: PARTNER[type].SUCCESS,
  payload: data
});

export const fetchFail = (type, error) => ({
  type: PARTNER[type].FAILED,
  payload: error
});

export const fetchDataPartner = (params, isExternal) => async (dispatch) => {
  const type = 'FETCH_PARTNER';
  try {
    dispatch(fetchRequest(type));
    const { data } = await (!isExternal
      ? api.partner.getAll(params)
      : api.partner.getAllExternal(params));
    dispatch(fetchSuccess(type, data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataDetailPartner = (id, isExternal) => async (dispatch) => {
  const type = 'FETCH_PARTNER_DETAIL';
  try {
    dispatch(fetchRequest(type));
    const { data } = await (!isExternal
      ? api.partner.getById(id)
      : api.partner.getByIdExternal(id));
    dispatch(fetchSuccess(type, data.data)); //
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataAddPartner = (payload) => async (dispatch) => {
  const type = 'FETCH_PARTNER_ADD';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.partner.add(payload);
    dispatch(fetchSuccess(type, data.data)); //
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataUpdatePartner = (payload) => async (dispatch) => {
  const type = 'FETCH_PARTNER_UPDATE';
  try {
    dispatch(fetchRequest(type));
    await Promise.all(
      payload.map(async (x) => {
        await api.partner.update(x.id, x.params);
      })
    );
    dispatch(fetchSuccess(type, { message: 'Successfully to update partner' }));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataReorderPartner = (payload) => async (dispatch) => {
  const type = 'FETCH_PARTNER_REORDER';
  try {
    dispatch(fetchRequest(type));
    await api.partner.reorder(payload);
    dispatch(fetchSuccess(type, { message: 'Successfully to reorder partner' }));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataRemovePartner = (payload) => async (dispatch) => {
  const type = 'FETCH_PARTNER_REMOVE';
  try {
    dispatch(fetchRequest(type));
    await Promise.all(
      payload.map(async (x) => {
        await api.partner.remove(x._id);
      })
    );
    dispatch(fetchSuccess(type, { message: 'Successfully to delete partner' }));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataAddImagePartner = (id, payload) => async (dispatch) => {
  const type = 'FETCH_PARTNER_ADD_IMAGE';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.partner.addImage(id, payload);
    dispatch(fetchSuccess(type, data.data)); //
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataRemoveImagePartner = (id, attachmentId) => async (dispatch) => {
  const type = 'FETCH_PARTNER_REMOVE_IMAGE';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.partner.removeImage(id, attachmentId);
    dispatch(fetchSuccess(type, data.data)); //
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const resetAddPartner = () => ({
  type: PARTNER.FETCH_PARTNER_ADD.RESET
});
export const resetDeletePartner = () => ({
  type: PARTNER.FETCH_PARTNER_REMOVE.RESET
});

export const resetUpdatePartner = () => ({
  type: PARTNER.FETCH_PARTNER_UPDATE.RESET
});

export const resetPartner = () => ({
  type: PARTNER.FETCH_PARTNER.RESET
});

export const resetDetailPartner = () => ({
  type: PARTNER.FETCH_PARTNER_DETAIL.RESET
});
