export const FETCH_TERMS_AND_CONDITIONS_LIST = {
  REQUEST: 'FETCH_TERMS_AND_CONDITIONS_LIST_REQUEST',
  SUCCESS: 'FETCH_TERMS_AND_CONDITIONS_LIST_SUCCESS',
  FAILED: 'FETCH_TERMS_AND_CONDITIONS_LIST_FAILED',
  RESET: 'FETCH_TERMS_AND_CONDITIONS_LIST_RESET'
};
export const FETCH_TERMS_AND_CONDITIONS_DETAIL = {
  REQUEST: 'FETCH_TERMS_AND_CONDITIONS_DETAIL_REQUEST',
  SUCCESS: 'FETCH_TERMS_AND_CONDITIONS_DETAIL_SUCCESS',
  FAILED: 'FETCH_TERMS_AND_CONDITIONS_DETAIL_FAILED',
  RESET: 'FETCH_TERMS_AND_CONDITIONS_DETAIL_RESET'
};
export const FETCH_TERMS_AND_CONDITIONS_CREATE = {
  REQUEST: 'FETCH_TERMS_AND_CONDITIONS_CREATE_REQUEST',
  SUCCESS: 'FETCH_TERMS_AND_CONDITIONS_CREATE_SUCCESS',
  FAILED: 'FETCH_TERMS_AND_CONDITIONS_CREATE_FAILED',
  RESET: 'FETCH_TERMS_AND_CONDITIONS_CREATE_RESET'
};
export const FETCH_TERMS_AND_CONDITIONS_UPDATE = {
  REQUEST: 'FETCH_TERMS_AND_CONDITIONS_UPDATE_REQUEST',
  SUCCESS: 'FETCH_TERMS_AND_CONDITIONS_UPDATE_SUCCESS',
  FAILED: 'FETCH_TERMS_AND_CONDITIONS_UPDATE_FAILED',
  RESET: 'FETCH_TERMS_AND_CONDITIONS_UPDATE_RESET'
};
export const FETCH_TERMS_AND_CONDITIONS_UPDATE_STATUS = {
  REQUEST: 'FETCH_TERMS_AND_CONDITIONS_UPDATE_STATUS_REQUEST',
  SUCCESS: 'FETCH_TERMS_AND_CONDITIONS_UPDATE_STATUS_SUCCESS',
  FAILED: 'FETCH_TERMS_AND_CONDITIONS_UPDATE_STATUS_FAILED',
  RESET: 'FETCH_TERMS_AND_CONDITIONS_UPDATE_STATUS_RESET'
};
export const FETCH_TERMS_AND_CONDITIONS_DELETE = {
  REQUEST: 'FETCH_TERMS_AND_CONDITIONS_DELETE_REQUEST',
  SUCCESS: 'FETCH_TERMS_AND_CONDITIONS_DELETE_SUCCESS',
  FAILED: 'FETCH_TERMS_AND_CONDITIONS_DELETE_FAILED',
  RESET: 'FETCH_TERMS_AND_CONDITIONS_DELETE_RESET'
};
