import { useTranslation } from 'react-i18next';
import {
  forgotPassGuide1,
  forgotPassGuide2,
  forgotPassGuide3,
  forgotPassGuide4,
  forgotPassGuide5
} from '../../assets';
import TimelineWithimage from '../TimelineWithImage';

const ForgotPasswordSteps = () => {
  const { t } = useTranslation();
  const data = [
    {
      image: forgotPassGuide1,
      title: t('PSSWD-001'),
      description: t('PSSWD-002'),
      onPress: {
        title: t('LANG-020'),
        link: '/forgot-password'
      }
    },
    {
      image: forgotPassGuide2,
      title: t('PSSWD-003'),
      description: t('PSSWD-004')
    },
    {
      image: forgotPassGuide3,
      title: t('RGSTR-008'),
      description: t('PSSWD-004')
    },
    {
      image: forgotPassGuide4,
      title: t('PSSWD-006'),
      description: t('PSSWD-007')
    },
    {
      image: forgotPassGuide5,
      title: t('PSSWD-009'),
      description: t('PSSWD-008')
    }
  ];
  return <TimelineWithimage data={data} xxl={6} xl={6} lg={7} md={8} sm={10} xs={11} />;
};

export default ForgotPasswordSteps;
