export const FETCH_NEWS = {
  REQUEST: 'FETCH_NEWS_REQUEST',
  SUCCESS: 'FETCH_NEWS_SUCCESS',
  FAILED: 'FETCH_NEWS_FAILED',
  RESET: 'FETCH_NEWS_RESET'
};

export const FETCH_NEWS_MOST_VIEWED = {
  REQUEST: 'FETCH_NEWS_MOST_VIEWED_REQUEST',
  SUCCESS: 'FETCH_NEWS_MOST_VIEWED_SUCCESS',
  FAILED: 'FETCH_NEWS_MOST_VIEWED_FAILED',
  RESET: 'FETCH_NEWS_MOST_VIEWED_RESET'
};

export const FETCH_NEWS_DETAIL = {
  REQUEST: 'FETCH_NEWS_DETAIL_REQUEST',
  SUCCESS: 'FETCH_NEWS_DETAIL_SUCCESS',
  FAILED: 'FETCH_NEWS_DETAIL_FAILED',
  RESET: 'FETCH_NEWS_DETAIL_RESET'
};

export const FETCH_NEWS_ADD = {
  REQUEST: 'FETCH_NEWS_ADD_REQUEST',
  SUCCESS: 'FETCH_NEWS_ADD_SUCCESS',
  FAILED: 'FETCH_NEWS_ADD_FAILED',
  RESET: 'FETCH_NEWS_ADD_RESET'
};

export const FETCH_NEWS_UPDATE = {
  REQUEST: 'FETCH_NEWS_UPDATE_REQUEST',
  SUCCESS: 'FETCH_NEWS_UPDATE_SUCCESS',
  FAILED: 'FETCH_NEWS_UPDATE_FAILED',
  RESET: 'FETCH_NEWS_UPDATE_RESET'
};

export const FETCH_NEWS_REORDER = {
  REQUEST: 'FETCH_NEWS_REORDER_REQUEST',
  SUCCESS: 'FETCH_NEWS_REORDER_SUCCESS',
  FAILED: 'FETCH_NEWS_REORDER_FAILED',
  RESET: 'FETCH_NEWS_REORDER_RESET'
};

export const FETCH_NEWS_REMOVE = {
  REQUEST: 'FETCH_NEWS_REMOVE_REQUEST',
  SUCCESS: 'FETCH_NEWS_REMOVE_SUCCESS',
  FAILED: 'FETCH_NEWS_REMOVE_FAILED',
  RESET: 'FETCH_NEWS_REMOVE_RESET'
};

export const FETCH_NEWS_ADD_IMAGE = {
  REQUEST: 'FETCH_NEWS_ADD_IMAGE_REQUEST',
  SUCCESS: 'FETCH_NEWS_ADD_IMAGE_SUCCESS',
  FAILED: 'FETCH_NEWS_ADD_IMAGE_FAILED'
};

export const FETCH_NEWS_REMOVE_IMAGE = {
  REQUEST: 'FETCH_NEWS_REMOVE_IMAGE_REQUEST',
  SUCCESS: 'FETCH_NEWS_REMOVE_IMAGE_SUCCESS',
  FAILED: 'FETCH_NEWS_REMOVE_IMAGE_FAILED'
};
