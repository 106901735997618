import React from 'react';

const PageNotPermitted = () => {
  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="text-center">
        <h1 className="display-1 fw-bold">403</h1>
        <p className="fs-3">
          {' '}
          <span className="text-danger">Opps!</span> Page not Permitted.
        </p>
        <p className="lead">You do not have access to this page!</p>
        <span className="btn btn-primary text-uppercase" onClick={() => history.back()}>
          GO BACK
        </span>
      </div>
    </div>
  );
};

export default PageNotPermitted;
