import { AUTH } from '../../types';

const initialState = {
  auth: null,
  isLoading: false,
  error: null,
  type: null
};

export const authReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case AUTH.FETCH_AUTH_LOGIN.REQUEST:
    case AUTH.FETCH_LOGIN_REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case AUTH.FETCH_VERIFY_OTP.REQUEST:
    case AUTH.FETCH_SEND_OTP.REQUEST:
      return {
        ...state,
        isLoading: true,
        type: null,
        error: null
      };
    case AUTH.FETCH_SEND_OTP.SUCCESS:
      return {
        ...state,
        type: action.type,
        otpNewRequest: payload,
        isLoading: false
      };
    case AUTH.FETCH_AUTH_LOGIN.SUCCESS:
    case AUTH.FETCH_VERIFY_OTP.SUCCESS:
    case AUTH.FETCH_LOGIN_SUCCESS:
      if (payload?.access_token) {
        localStorage.setItem('user', JSON.stringify(payload.user));
        localStorage.setItem('token', payload.access_token);
        localStorage.setItem('refreshToken', payload.refresh_token);
        localStorage.setItem('tokenType', payload.token_type);
        localStorage.setItem('menus', JSON.stringify(payload.menus));
      }
      return {
        ...initialState,
        auth: payload
          ? {
              ...payload,
              menus: payload.menus
            }
          : null,
        isLoading: false,
        type: action.type
      };
    case AUTH.FETCH_VERIFY_OTP.FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
        type: action.type
      };
    case AUTH.FETCH_SEND_OTP.FAILED:
    case AUTH.FETCH_AUTH_LOGIN.FAILED:
    case AUTH.FETCH_LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
        type: action.type
      };

    case AUTH.LOGOUT:
      return {
        ...initialState
      };

    case AUTH.REFRESH_TOKEN:
      return {
        ...state,
        auth: {
          ...state.auth,
          access_token: payload
        }
      };
    case AUTH.FETCH_AUTH_LOGIN.RESET_TYPE:
      return {
        ...state,
        type: null
      };
    default:
      return state;
  }
};
