import moment from 'moment';
import api from '../../../apis';
import { errorParser } from '../../../utils';

import { AUTH } from '../../types';
import { FETCH_VERIFY_OTP } from '../../types/auth';

const {
  FETCH_LOGIN_FAILED,
  FETCH_LOGIN_REQUEST,
  FETCH_LOGIN_SUCCESS,
  LOGOUT,
  REFRESH_TOKEN,
  FETCH_AUTH_LOGIN,
  FETCH_SEND_OTP
} = AUTH;

export const fetchLoginRequest = () => ({
  type: FETCH_LOGIN_REQUEST
});

export const fetchLoginSuccess = (auth) => ({
  type: FETCH_LOGIN_SUCCESS,
  payload: auth
});

export const fetchLoginFail = (error) => ({
  type: FETCH_LOGIN_FAILED,
  payload: error
});
export const fetchAuthLoginRequest = () => ({
  type: FETCH_AUTH_LOGIN.REQUEST
});
export const fetchAuthLoginSuccess = (auth) => ({
  type: FETCH_AUTH_LOGIN.SUCCESS,
  payload: auth
});

export const fetchAuthLoginFail = (error) => ({
  type: FETCH_AUTH_LOGIN.FAILED,
  payload: error
});

export const fetchResetType = () => ({
  type: FETCH_AUTH_LOGIN.RESET_TYPE
});

export const fetchDataLogin =
  ({ email, password, captcha }) =>
  async (dispatch) => {
    try {
      dispatch(fetchAuthLoginRequest());
      const { data } = await api.auth.login({ email, password, captcha });
      if (!data.data.access_token) dispatch(fetchVerifyOtpSuccess(data.data));
      else dispatch(fetchAuthLoginSuccess(data.data));
    } catch (error) {
      const message = errorParser(error);
      dispatch(fetchAuthLoginFail(message));
    }
  };

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};

export const refreshToken = (token) => (dispatch) => {
  dispatch({ type: REFRESH_TOKEN, payload: token });
};

export const fetchSendOtpRequest = () => ({
  type: FETCH_SEND_OTP.REQUEST
});
export const fetchSendOtpSuccess = (payload) => ({
  type: FETCH_SEND_OTP.SUCCESS,
  payload: payload
});
export const fetchSendOtpFailed = (payload) => ({
  type: FETCH_SEND_OTP.FAILED,
  payload: payload
});
export const fetchSendOtpData = (platform) => async (dispatch, getStore) => {
  const params = {
    platform: platform,
    userId: getStore().authReducer?.auth?.user?._id
  };
  try {
    dispatch(fetchSendOtpRequest());
    await api.auth.requestOtp(params);
    const canResendIn = moment().add('3', 'm');
    dispatch(fetchSendOtpSuccess({ resendAfter: canResendIn, platform: platform }));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchSendOtpFailed(message));
  }
};

export const fetchVerifyOtpRequest = () => ({
  type: FETCH_VERIFY_OTP.REQUEST
});

export const fetchVerifyOtpSuccess = (payload) => ({
  type: FETCH_VERIFY_OTP.SUCCESS,
  payload
});

export const fetchVerifyOtpFailed = (payload) => ({
  type: FETCH_VERIFY_OTP.FAILED,
  payload
});

export const fetchVerifyOtp = (data) => async (dispatch, getStore) => {
  const params = {
    token: data,
    userId: getStore().authReducer?.auth?.user?._id
  };
  try {
    dispatch(fetchVerifyOtpRequest());
    const { data } = await api.auth.verifyOtp(params);
    dispatch(fetchVerifyOtpSuccess(data.data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchVerifyOtpFailed(message));
  }
};
