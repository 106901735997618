import { NEWSCATEGORY } from '../../types';

const initialState = {
  data: [],
  error: null,
  meta: null,
  isLoading: false
};

export const newsCategoryReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES.SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        data: payload.data,
        meta: payload.meta
      };
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES.FAILED:
      return {
        ...initialState,
        isLoading: false,
        error: payload
      };
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES.RESET:
      return initialState;
    default:
      return state;
  }
};

export const newsCategoryDetailReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_DETAIL.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_DETAIL.SUCCESS:
      return {
        ...initialState,
        data: payload
      };
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_DETAIL.FAILED:
      return {
        ...initialState,
        error: payload
      };
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_DETAIL.RESET:
      return initialState;
    default:
      return state;
  }
};

export const newsCategoryAddReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_ADD.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_ADD.SUCCESS:
      return {
        ...initialState,
        data: payload.data,
        type: type
      };
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_ADD.FAILED:
      return {
        ...initialState,
        error: payload,
        type: type
      };
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_ADD.RESET:
      return initialState;
    default:
      return state;
  }
};

export const newsCategoryUpdateReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_UPDATE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_UPDATE.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_UPDATE.FAILED:
      return {
        ...initialState,
        error: payload,
        type: type
      };
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_UPDATE.RESET:
      return initialState;
    default:
      return state;
  }
};

export const newsCategoryRemoveReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_REMOVE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_REMOVE.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_REMOVE.FAILED:
      return {
        ...initialState,
        type: type,
        error: payload
      };
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_REMOVE.RESET:
      return initialState;
    default:
      return state;
  }
};

export const newsCategoryAddImageReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_ADD_IMAGE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_ADD_IMAGE.SUCCESS:
      return {
        ...initialState,
        data: payload
      };
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_ADD_IMAGE.FAILED:
      return {
        ...initialState,
        error: payload
      };
    default:
      return state;
  }
};

export const newsCategoryRemoveImageReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_REMOVE_IMAGE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_REMOVE_IMAGE.SUCCESS:
      return {
        ...initialState,
        data: payload
      };
    case NEWSCATEGORY.FETCH_NEWS_CATEGORIES_REMOVE_IMAGE.FAILED:
      return {
        ...initialState,
        error: payload
      };
    default:
      return state;
  }
};
