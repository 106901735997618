import { termsAndConditionsExternalUrl, termsAndConditionsUrl } from './constant';
import { httpHelper } from '../utils';

export const getAll = (params) => httpHelper.get(termsAndConditionsUrl, { params });
export const getById = (id) => httpHelper.get(`${termsAndConditionsUrl}/${id}`);
export const add = (data) => httpHelper.post(termsAndConditionsUrl, data);
export const update = (id, data) => httpHelper.put(`${termsAndConditionsUrl}/${id}`, data);
export const updateStatus = (id, data) =>
  httpHelper.put(`${termsAndConditionsUrl}/${id}/update-status`, data);
export const remove = (id) => httpHelper.remove(`${termsAndConditionsUrl}/${id}`);

// external
export const getAllExternal = (params) =>
  httpHelper.get(termsAndConditionsExternalUrl, { params, isExternalApi: true });
export const getByIdExternal = (id) =>
  httpHelper.get(`${termsAndConditionsExternalUrl}/${id}`, { isExternalApi: true });
