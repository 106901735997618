import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const CountdownTimer = ({ targetDate, keyLocalstorage, callback }) => {
  const countDownDate = new Date(targetDate).getTime();
  const [countDown, setCountDown] = useState(
    countDownDate -
      new Date(moment().utcOffset('7').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')).getTime()
  );
  const myInterval = useRef();

  useEffect(() => {
    myInterval.current = setInterval(() => {
      setCountDown(
        countDownDate -
          new Date(moment().utcOffset('7').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')).getTime()
      );
    }, 1000);

    return () => clearInterval(myInterval.current);
  }, [countDownDate]);
  const minutes = countDown < 0 ? 0 : Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = countDown < 0 ? 0 : Math.floor((countDown % (1000 * 60)) / 1000);
  if (countDown < 0) {
    callback(null);
    localStorage.removeItem(keyLocalstorage);
  }
  return (
    <span>
      Resent Verification ( 0{minutes}:{seconds < 10 ? `0${seconds}` : seconds} )
    </span>
  );
};

CountdownTimer.propTypes = {
  targetDate: PropTypes.date,
  keyLocalstorage: PropTypes.string,
  callback: PropTypes.func
};
export default CountdownTimer;
