import * as constant from './constant';
import * as auth from './auth';
import * as news from './news';
import * as product from './product';
import * as productCategory from './productCategory';
import * as newsCategory from './newsCategory';
import * as partner from './partner';
import * as information from './information';
import * as organization from './organization';
import * as termsAndConditions from './termsAndConditions';
import * as privacyPolicy from './privacyPolicy';

export default {
  constant,
  auth,
  news,
  product,
  productCategory,
  newsCategory,
  partner,
  information,
  organization,
  termsAndConditions,
  privacyPolicy
};
