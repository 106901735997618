/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Timer = ({ onTimerEnd, withText = 'Resent Verification', initialTime }) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      } else {
        clearInterval(timer); // Clear the interval when countdown finishes
        onTimerEnd(); // Call the callback function
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, onTimerEnd]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  return <span>{formatTime(timeLeft)}</span>;
};

Timer.propTypes = {
  endDate: PropTypes.any,
  withText: PropTypes.string,
  onTimerEnd: PropTypes.func,
  initialTime: PropTypes.number
};
export default Timer;
