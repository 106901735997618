import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import PropTypes from 'prop-types';

const FilePicker = (
  {
    onSuccess,
    text,
    required = false,
    label = 'Photo',
    sublabel = 'Extensions: .png, .jpg, .jpeg, .gif'
  },
  ref
) => {
  const inputFile = useRef();
  const [file, setFile] = useState();
  const onButtonClick = () => {
    inputFile.current.value = null;
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  // after file change
  const onChangeFile = (e) => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0]);
      onSuccess(e.target.files[0]);
    }
  };

  const getFiles = () => file;

  // export file

  useImperativeHandle(ref, () => ({ getFiles }), [file]);

  return (
    <Form.Group>
      <Form.Label className="fw-bold" style={{ color: '#5C5E61' }}>
        {required && <span className="input-text-color"> *</span>}
        {label}
      </Form.Label>
      <InputGroup>
        <Form.Control
          defaultValue={file?.name || text}
          disabled
          className="bg-white border-end-0"
        />
        <InputGroup.Text
          className="bg-white border-start-0"
          onClick={onButtonClick}
          role={'button'}>
          <AiOutlinePlusCircle />
          <input
            onChange={onChangeFile}
            type="file"
            id="file"
            name="file"
            ref={inputFile}
            style={{ display: 'none' }}
            accept="image/*"
          />
        </InputGroup.Text>
      </InputGroup>
      <div style={{ fontSize: 12, color: '#8C8F93' }}>{sublabel}</div>
    </Form.Group>
  );
};

FilePicker.propTypes = {
  onSuccess: PropTypes.func,
  text: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  sublabel: PropTypes.string
};

export default forwardRef(FilePicker);
