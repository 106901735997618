import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import './index.css';

const Input = (props) => {
  const { label = '', placeholder = '', required = false, error = '', text = '' } = props;
  const [showErr, setShowErr] = useState(false);
  const [isInvalid, setIsInvalid] = useState(true);

  useEffect(() => {
    setIsInvalid(validate());
  }, [text]);

  const validate = () => {
    if (!text) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div>
      <Form.Label className="fw-bold" style={{ color: '#5C5E61' }}>
        {required && <span className="input-text-color"> *</span>}
        {label}
      </Form.Label>
      <Form.Control
        type="text"
        placeholder={placeholder}
        defaultValue={text}
        onFocus={() => setShowErr(true)}
        required={required}
        isInvalid={showErr && required && isInvalid}
        {...props}
      />

      {showErr && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  text: PropTypes.string
};

export default Input;
