import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { TbAlertCircle } from 'react-icons/tb';
import './index.css';

const PopUpConfirmation = (props) => {
  const { onHide, onSubmit, text, textcancel, textsubmit, disabled, title } = props;
  return (
    <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className="d-flex justify-content-center">
          <TbAlertCircle size={'10rem'} color="#FFB020" className="py-3" />
        </div>
        <h3 className="d-flex justify-content-center mb-3">{title || 'Confirmation'}</h3>
        <div className="text-center">{text}</div>
      </Modal.Body>
      <Modal.Footer className="justify-content-between border-0 text-uppercase">
        <Button
          onClick={onHide}
          variant="light"
          className="popup-confirmation border-0 fw-bold text-uppercase"
          disabled={disabled}>
          {textcancel}
        </Button>
        <Button
          onClick={onSubmit}
          variant="danger"
          disabled={disabled}
          className="fw-bold text-uppercase">
          {textsubmit}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

PopUpConfirmation.defaultProps = {
  text: 'Are you sure?',
  textcancel: 'CANCEL',
  textsubmit: 'SUBMIT',
  disabled: false
};

PopUpConfirmation.propTypes = {
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
  text: PropTypes.string,
  textsubmit: PropTypes.string,
  textcancel: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string
};

export default PopUpConfirmation;
