import axios from 'axios';
import api from '../apis';
import { store } from '../stores';
import { auth as authAction } from '../stores/actions';
import Swal from 'sweetalert2';

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (token) => {
  refreshSubscribers.forEach((cb) => cb(token));
};

const instance = axios.create();

const get = (url, config = {}) => instance.get(url, config);
const post = (url, data, config = {}) => instance.post(url, data, config);
const put = (url, data, config = {}) => instance.put(url, data, config);
const remove = (url, config = {}) => instance.delete(url, config);

instance.interceptors.request.use(
  async (config) => {
    const { isExternalApi, isRetry, noToken, noAlert } = config || {};
    try {
      if (window.navigator.onLine) {
        const state = store.getState();
        if (config && isExternalApi) {
          config.headers.Authorization = process.env.REACT_APP_API_KEY;
          config.timeout = 20000;
          config.timeoutErrorMessage = 'Request Timeout';
          return config;
        }
        if (config && !isRetry && state.authReducer.auth && !noToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${state.authReducer.auth?.access_token}`;
          // eslint-disable-next-line no-param-reassign
          config.timeout = 20000;
          // eslint-disable-next-line no-param-reassign
          config.timeoutErrorMessage = 'Request Timeout';
        }
        return config;
      }
      if (!noAlert) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'No Internet Connection'
        });
      }
      return Promise.reject(new Error('No Internet Connection'));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const state = store.getState();
    const originalRequest = error.config;
    const contentType = error.response?.headers?.['content-type'];
    const unauthorized = error.response?.data;
    const isUnauthorized =
      contentType?.includes?.('text/plain') || unauthorized?.includes?.('Unauthorized');
    if (originalRequest.isExternalApi) {
      return Promise.reject(error);
    }
    if (error.response?.status === 401 && isUnauthorized) {
      if (!isRefreshing) {
        refreshSubscribers = [];
        isRefreshing = true;
        instance
          .post(
            api.constant.refreshUrl,
            {
              refreshToken: state.authReducer.auth.refresh_token,
              userId: state.authReducer.auth.user?._id
            },
            { isRetry: true, noAlert: originalRequest?.noAlert }
          )
          .then((newToken) => {
            isRefreshing = false;
            const token = newToken.data.data.access_token;
            store.dispatch(authAction.refreshToken(token));
            onRefreshed(token);
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Your Session Has Expired. Please re-login!'
            }).then(() => store.dispatch(authAction.logout()));
            Promise.reject(error);
          });
      }
      const retryOrigReq = new Promise((resolve) => {
        subscribeTokenRefresh((token) => {
          // replace the expired token and retry
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
      return retryOrigReq;
    }
    // if (!originalRequest?.noAlert) Alert.alert('Error', errorParser(error), [{ text: 'OK' }]);
    return Promise.reject(error);
  }
);

export default {
  get,
  post,
  put,
  remove,
  instance
};
