export const FETCH_INFORMATION = {
  REQUEST: 'FETCH_INFORMATION_REQUEST',
  SUCCESS: 'FETCH_INFORMATION_SUCCESS',
  FAILED: 'FETCH_INFORMATION_FAILED',
  RESET: 'FETCH_INFORMATION_RESET'
};

export const FETCH_INFORMATION_DETAIL = {
  REQUEST: 'FETCH_INFORMATION_DETAIL_REQUEST',
  SUCCESS: 'FETCH_INFORMATION_DETAIL_SUCCESS',
  FAILED: 'FETCH_INFORMATION_DETAIL_FAILED',
  RESET: 'FETCH_INFORMATION_DETAIL_RESET'
};

export const FETCH_INFORMATION_ADD = {
  REQUEST: 'FETCH_INFORMATION_ADD_REQUEST',
  SUCCESS: 'FETCH_INFORMATION_ADD_SUCCESS',
  FAILED: 'FETCH_INFORMATION_ADD_FAILED',
  RESET: 'FETCH_INFORMATION_ADD_RESET'
};

export const FETCH_INFORMATION_UPDATE = {
  REQUEST: 'FETCH_INFORMATION_UPDATE_REQUEST',
  SUCCESS: 'FETCH_INFORMATION_UPDATE_SUCCESS',
  FAILED: 'FETCH_INFORMATION_UPDATE_FAILED',
  RESET: 'FETCH_INFORMATION_UPDATE_RESET'
};

export const FETCH_INFORMATION_REORDER = {
  REQUEST: 'FETCH_INFORMATION_REORDER_REQUEST',
  SUCCESS: 'FETCH_INFORMATION_REORDER_SUCCESS',
  FAILED: 'FETCH_INFORMATION_REORDER_FAILED',
  RESET: 'FETCH_INFORMATION_REORDER_RESET'
};

export const FETCH_INFORMATION_REMOVE = {
  REQUEST: 'FETCH_INFORMATION_REMOVE_REQUEST',
  SUCCESS: 'FETCH_INFORMATION_REMOVE_SUCCESS',
  FAILED: 'FETCH_INFORMATION_REMOVE_FAILED',
  RESET: 'FETCH_INFORMATION_REMOVE_RESET'
};

export const FETCH_INFORMATION_ADD_IMAGE = {
  REQUEST: 'FETCH_INFORMATION_ADD_IMAGE_REQUEST',
  SUCCESS: 'FETCH_INFORMATION_ADD_IMAGE_SUCCESS',
  FAILED: 'FETCH_INFORMATION_ADD_IMAGE_FAILED',
  RESET: 'FETCH_INFORMATION_ADD_IMAGE_RESET'
};

export const FETCH_INFORMATION_REMOVE_IMAGE = {
  REQUEST: 'FETCH_INFORMATION_REMOVE_IMAGE_REQUEST',
  SUCCESS: 'FETCH_INFORMATION_REMOVE_IMAGE_SUCCESS',
  FAILED: 'FETCH_INFORMATION_REMOVE_IMAGE_FAILED',
  RESET: 'FETCH_INFORMATION_REMOVE_IMAGE_RESET'
};
