import { informationUrl } from './constant';
import { httpHelper } from '../utils';

export const getAll = (params) => httpHelper.get(informationUrl, { params });
export const getById = (id) => httpHelper.get(`${informationUrl}/${id}`);
export const add = (data) => httpHelper.post(informationUrl, data);
export const update = (id, data) => httpHelper.put(`${informationUrl}/${id}`, data);
export const reorder = (data) => httpHelper.put(`${informationUrl}/reorder`, data);
export const remove = (id) => httpHelper.remove(`${informationUrl}/${id}`);
export const addImage = (id, data) => httpHelper.post(`${informationUrl}/${id}/attachment`, data);
export const removeImage = (id, attachmentId) =>
  httpHelper.remove(`${informationUrl}/${id}/attachment/${attachmentId}`);
