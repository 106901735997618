import api from '../../../apis';
import { errorParser } from '../../../utils';

import { INFORMATION } from '../../types';

export const fetchRequest = (type) => ({
  type: INFORMATION[type].REQUEST
});

export const fetchSuccess = (type, data) => ({
  type: INFORMATION[type].SUCCESS,
  payload: data
});

export const fetchFail = (type, error) => ({
  type: INFORMATION[type].FAILED,
  payload: error
});

export const fetchDataInformation = (params) => async (dispatch) => {
  const type = 'FETCH_INFORMATION';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.information.getAll(params);
    dispatch(fetchSuccess(type, data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataDetailInformation = (id) => async (dispatch) => {
  const type = 'FETCH_INFORMATION_DETAIL';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.information.getById(id);
    dispatch(fetchSuccess(type, data.data)); //
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataAddInformation = (payload) => async (dispatch) => {
  const type = 'FETCH_INFORMATION_ADD';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.information.add(payload);
    dispatch(fetchSuccess(type, data.data)); //
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataUpdateInformation = (payload) => async (dispatch) => {
  const type = 'FETCH_INFORMATION_UPDATE';
  try {
    dispatch(fetchRequest(type));
    await Promise.all(
      payload.map(async (x) => {
        await api.information.update(x.id, x.params);
      })
    );
    dispatch(fetchSuccess(type, { message: 'Successfully to update information' }));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataReorderInformation = (payload) => async (dispatch) => {
  const type = 'FETCH_INFORMATION_REORDER';
  try {
    dispatch(fetchRequest(type));
    await api.information.reorder(payload);
    dispatch(fetchSuccess(type, { message: 'Successfully to reorder information' }));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataRemoveInformation = (payload) => async (dispatch) => {
  const type = 'FETCH_INFORMATION_REMOVE';
  try {
    dispatch(fetchRequest(type));
    await Promise.all(
      payload.map(async (x) => {
        await api.information.remove(x._id);
      })
    );
    dispatch(fetchSuccess(type, { message: 'Successfully to delete information' }));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataAddImageInformation = (id, payload) => async (dispatch) => {
  const type = 'FETCH_INFORMATION_ADD_IMAGE';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.information.addImage(id, payload);
    dispatch(fetchSuccess(type, data.data)); //
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataRemoveImageInformation = (id, attachmentId) => async (dispatch) => {
  const type = 'FETCH_INFORMATION_REMOVE_IMAGE';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.information.removeImage(id, attachmentId);
    dispatch(fetchSuccess(type, data.data)); //
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const resetAddInformation = () => ({
  type: INFORMATION.FETCH_INFORMATION_ADD.RESET
});
export const resetDeleteInformation = () => ({
  type: INFORMATION.FETCH_INFORMATION_REMOVE.RESET
});

export const resetUpdateInformation = () => ({
  type: INFORMATION.FETCH_INFORMATION_UPDATE.RESET
});

export const resetInformation = () => ({
  type: INFORMATION.FETCH_INFORMATION.RESET
});

export const resetDetailInformation = () => ({
  type: INFORMATION.FETCH_INFORMATION_DETAIL.RESET
});

export const resetAddImageInformation = () => ({
  type: INFORMATION.FETCH_INFORMATION_ADD_IMAGE.RESET
});

export const resetRemoveImageInformation = () => ({
  type: INFORMATION.FETCH_INFORMATION_REMOVE_IMAGE.RESET
});
