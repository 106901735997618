import api from '../../../apis';
import { errorParser } from '../../../utils';

import { RESET } from '../../types';

const { FETCH_RESET_FAILED, FETCH_RESET_REQUEST, FETCH_RESET_SUCCESS } = RESET;

export const fetchResetRequest = () => ({
  type: FETCH_RESET_REQUEST
});

export const fetchResetSuccess = (payload) => ({
  type: FETCH_RESET_SUCCESS,
  payload
});

export const fetchResetFail = (error) => ({
  type: FETCH_RESET_FAILED,
  payload: error
});

export const fetchDataReset =
  ({ uuid, email, password }) =>
  async (dispatch) => {
    try {
      dispatch(fetchResetRequest());
      const { data } = await api.auth.resetPassword({ uuid, email, password });
      dispatch(fetchResetSuccess(data));
    } catch (error) {
      const message = errorParser(error);
      dispatch(fetchResetFail(message));
    }
  };
