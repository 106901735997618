import { Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaChevronRight } from 'react-icons/fa';
import { RiDoubleQuotesL } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import {
  AboutPartnership,
  PartnershipSteps,
  RegisterSteps,
  UploadLegalDoc
} from '../../../components';
import ForgotPasswordSteps from '../../../components/ForgotPasswordStep';
import { shorthand } from '../../../utils';
import './index.css';

const Detail = () => {
  const { t } = useTranslation();
  const route = useParams();
  const ABOUT_PARTNERSHIP = 'about-partnership';
  const getTitle = (data) => {
    const result = {
      title: '',
      buttonText: '',
      directTo: '',
      quotes: ''
    };
    switch (data) {
      case ABOUT_PARTNERSHIP:
        result.title = t('ABOUT-004');
        break;
      case 'register':
        result.title = t('ABOUT-005');
        result.buttonText = t('ABOUT-007');
        result.quotes = t('ABOUT-017');
        result.directTo = '/contact';
        break;
      case 'upload-legal-doc':
        result.title = t('LEGAL-015');
        result.buttonText = t('ABOUT-007');
        result.quotes = t('ABOUT-017');
        result.directTo = '/contact';
        break;
      case 'reset-password':
        result.title = t('ABOUT-006');
        result.buttonText = t('ABOUT-007');
        result.quotes = t('ABOUT-017');
        result.directTo = '/contact';
        break;
      case 'partnership-steps':
        result.title = t('ABOUT-008');
        result.buttonText = t('ABOUT-003');
        result.quotes = t('ABOUT-002');
        result.directTo = '/login';
        break;
      default:
        break;
    }
    return result;
  };
  return (
    <Fragment>
      <h4
        className="d-flex mx-5 my-5 px-xl-5 title fw-bold"
        data-aos="fade-right"
        data-aos-duration={shorthand.aosDuration}>
        {t('ABOUT-009')}
      </h4>
      <h2
        className="d-flex m-5 px-xl-5 fw-bold"
        data-aos="fade-right"
        data-aos-duration={shorthand.aosDuration}>
        {getTitle(route?.types)?.title}
      </h2>
      {route?.types === ABOUT_PARTNERSHIP && <AboutPartnership />}
      {route?.types !== ABOUT_PARTNERSHIP && (
        <>
          <div className="pt-5" data-aos="fade-right" data-aos-duration={shorthand.aosDuration}>
            {route?.types === 'partnership-steps' && <PartnershipSteps />}
            {route?.types === 'register' && <RegisterSteps />}
            {route?.types === 'reset-password' && <ForgotPasswordSteps />}
            {route?.types === 'upload-legal-doc' && <UploadLegalDoc />}
          </div>
          <div data-aos="fade-right" data-aos-duration={shorthand.aosDuration}>
            <div className="d-flex justify-content-center align-items-center">
              <RiDoubleQuotesL color="red" size={'2.5rem'} />
              <span className="mx-4 mt-5 text-quote text-center card-guide-quote">
                {getTitle(route?.types)?.quotes}
              </span>
              <RiDoubleQuotesL color="red" size={'3rem'} />
            </div>
            <div className="d-flex justify-content-center">
              <Button
                as="a"
                className="my-5 button-main-guide"
                onClick={() => (location.href = getTitle(route?.types)?.directTo)}>
                {getTitle(route?.types)?.buttonText}
                <FaChevronRight className="ms-2" size={10} />
              </Button>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default Detail;
