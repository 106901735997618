import api from '../../../apis';
import { errorParser } from '../../../utils';
import { FETCH_PRIVACY_POLICY_UPDATE_STATUS } from '../../types/privacyPolicy';

const request = () => ({
  type: FETCH_PRIVACY_POLICY_UPDATE_STATUS.REQUEST
});

const success = (payload) => ({
  type: FETCH_PRIVACY_POLICY_UPDATE_STATUS.SUCCESS,
  payload
});

const failed = (error) => ({
  type: FETCH_PRIVACY_POLICY_UPDATE_STATUS.FAILED,
  payload: error
});

const fetchData = (id, params) => async (dispatch) => {
  try {
    dispatch(request());
    const { data } = await api.privacyPolicy.updateStatus(id, params);
    dispatch(success(data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(failed(message));
  }
};

export { fetchData };
