import React from 'react';
import PropTypes from 'prop-types';

const InformationScreen = ({ title, image, caption, button, children }) => (
  <div className="d-flex align-items-center justify-content-center vh-100">
    <div className="text-center w-50">
      <h1 className="mb-3 fw-bold">{title}</h1>
      <img src={image} height={200} width={200} />
      <p className="text-secondary">{caption}</p>
      <span className="btn btn-danger banner-button mt-3" onClick={button.action}>
        {button.text}
      </span>
      {children}
    </div>
  </div>
);

InformationScreen.propTypes = {
  title: PropTypes.string,
  image: PropTypes.any,
  caption: PropTypes.string,
  button: PropTypes.object,
  children: PropTypes.any
};

export default InformationScreen;
