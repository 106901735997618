const DATA = [
  {
    id: 1,
    title: {
      en: 'News',
      id: 'Berita'
    },
    categoryId: 'news'
  },
  {
    id: 2,
    title: 'Apa Ya',
    categoryId: 'apaya'
  },
  {
    id: 3,
    title: 'Testing 1',
    categoryId: 'testing1'
  },
  {
    id: 4,
    title: 'Testing 2',
    categoryId: 'testing2'
  }
];

export default DATA;
