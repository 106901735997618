import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Input, PopUpConfirmation, PopUpSuccess, PopUpFailed } from '../../../../../components';
import { news, newsCategory } from '../../../../../stores/actions';
import { useNavigate } from 'react-router-dom';
import { FETCH_NEWS_CATEGORIES_ADD } from '../../../../../stores/types/newsCategory';

const NewsCategoryCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reducer = useSelector((state) => state.newsCategoryAddReducer);
  const [titleEng, setTitleEng] = useState('');
  const [titleId, setTitleId] = useState('');
  const [type, setType] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalFailed, setModalFailed] = useState(false);
  const handleSubmit = () => {
    const payload = {
      title: {
        en: titleEng,
        id: titleId
      },
      type
    };
    dispatch(newsCategory.fetchDataAddNewsCategory(payload));
  };

  useEffect(() => {
    const params = {
      perpage: 100
    };
    dispatch(newsCategory.fetchDataNewsCategory(params));
    return () => {
      dispatch(newsCategory.resetNewsCategory());
      dispatch(newsCategory.resetAddNewsCategory());
    };
  }, []);

  useEffect(() => {
    if (reducer.type === FETCH_NEWS_CATEGORIES_ADD.SUCCESS) {
      setModalConfirmation(false);
      setModalSuccess(true);
    }
    if (reducer.type === FETCH_NEWS_CATEGORIES_ADD.FAILED) {
      setModalConfirmation(false);
      setModalFailed(true);
    }
  }, [reducer?.type]);

  useEffect(() => {
    setCanSubmit(validate());
  }, [titleEng, titleId]);

  const validate = () => {
    if (!titleEng) {
      return false;
    }
    if (!titleId) {
      return false;
    }
    return true;
  };

  return (
    <Container fluid>
      <Form>
        <Row className="my-4">
          <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <Form.Group>
              <Form.Label className="fw-bold" style={{ color: '#5C5E61' }}>
                Type<span className="text-danger"> *</span>
              </Form.Label>
              <Form.Select onChange={(x) => setType(x.target.value)} value={type}>
                <option value="" disabled hidden>
                  Choose between News or Information
                </option>
                <option value="news">News</option>
                <option value="information">Information</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <Form.Group>
              <Input
                label="Title (English)"
                text={titleEng}
                required={true}
                onChange={(e) => setTitleEng(e.target.value)}
                error="Please fill the news title in english"
                as="textarea"
              />
            </Form.Group>
          </Col>
          <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <Form.Group>
              <Input
                label="Title (Bahasa)"
                text={titleId}
                required={true}
                onChange={(e) => setTitleId(e.target.value)}
                error="Please fill the news title in bahasa"
                as="textarea"
              />
            </Form.Group>
          </Col>
        </Row>
        <Col className="text-end my-5 mx-2">
          <Button
            type="button"
            className="btn btn-danger py-2 px-3 text-uppercase"
            disabled={!canSubmit}
            onClick={() => {
              setModalConfirmation(true);
            }}>
            ADD
          </Button>
        </Col>
      </Form>
      <PopUpConfirmation
        show={modalConfirmation}
        text="Are you sure to add new news?"
        textsubmit="YES, ADD"
        onHide={() => setModalConfirmation(false)}
        disabled={reducer.isLoading}
        onSubmit={handleSubmit}
      />
      <PopUpSuccess
        show={modalSuccess}
        textsubmit="OKAY"
        onSubmit={() => {
          setModalSuccess(false);
          navigate(-1);
          dispatch(news.resetAddNews());
        }}
        text="You can change news status that you published by edit the news"
      />
      <PopUpFailed
        show={modalFailed}
        textsubmit="Back"
        text={reducer?.error}
        onSubmit={() => {
          setModalFailed(false);
          dispatch(news.resetAddNews());
          navigate(-1);
        }}
      />
    </Container>
  );
};

export default NewsCategoryCreate;
