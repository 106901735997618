import { useState, useCallback } from 'react';
const reg1 = /^\w+([\\.-]?\w+)*@\w+/;
const reg2 = /([\\.-]?\w+)*(\.\w{2,3})+$/;
const flags = (reg1.flags + reg2.flags)
  .split('')
  .sort()
  .join('')
  .replace(/(.)(?=.*\1)/g, '');
const reg3 = new RegExp(reg1.source + reg2.source, flags);

const emailTest = (email) => reg3.test(email);

const validPassword = (pswd) => {
  const regexUpper = new RegExp('.*[A-Z]');
  const regexLower = new RegExp('.*[a-z]');
  const regexNumb = new RegExp('.*[0-9]');
  if (pswd.length >= 8 && regexUpper.test(pswd) && regexLower.test(pswd) && regexNumb.test(pswd)) {
    return true;
  }
  return false;
};
export default function form(initialValue, validationFunc) {
  if (validationFunc === 'email') {
    validationFunc = emailTest;
  }
  if (validationFunc === 'password') validationFunc = validPassword;
  const [state, setstate] = useState(initialValue);
  const [isValid, setIsValid] = useState(() => validationFunc(state));
  const [isTouched, setIsTouched] = useState(false);

  const onChange = useCallback(
    (nextState) => {
      const value = typeof nextState === 'function' ? nextState(state) : nextState;
      setstate(value);
      setIsValid(validationFunc(value));
    },
    [validationFunc]
  );

  // eslint-disable-next-line react/prop-types
  const ErrorLabel = ({ message }) =>
    !isValid && isTouched && <span className="text-danger">{message}</span>;
  return [state, onChange, isValid, isTouched, setIsTouched, ErrorLabel, setIsValid];
}
