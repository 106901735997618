import { partnerUrl } from './constant';
import { httpHelper } from '../utils';

export const getAll = (params) => httpHelper.get(partnerUrl, { params });
export const getById = (id) => httpHelper.get(`${partnerUrl}/${id}`);
export const add = (data) => httpHelper.post(partnerUrl, data);
export const update = (id, data) => httpHelper.put(`${partnerUrl}/${id}`, data);
export const reorder = (data) => httpHelper.put(`${partnerUrl}/reorder`, data);
export const remove = (id) => httpHelper.remove(`${partnerUrl}/${id}`);
export const addImage = (id, data) => httpHelper.post(`${partnerUrl}/${id}/attachment`, data);
export const removeImage = (id, attachmentId) =>
  httpHelper.remove(`${partnerUrl}/${id}/attachment/${attachmentId}`);
