import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  FilePicker,
  Input,
  PopUpConfirmation,
  PopUpSuccess,
  PopUpFailed,
  RichEditor
} from '../../../../components';
import { news, newsCategory } from '../../../../stores/actions';
import { useNavigate } from 'react-router-dom';
import { FETCH_NEWS_ADD } from '../../../../stores/types/news';
import { fetchDataAddNews } from '../../../../stores/actions/news';
import ImagePreview from '../Components/PickedImage';

const NewsCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reducer = useSelector((state) => state.newsAddReducer);
  const newsReducer = useSelector((state) => state.newsCategoryReducer);
  const [author, setAuthor] = useState('');
  const [titleEng, setTitleEng] = useState('');
  const [titleId, setTitleId] = useState('');
  const [detailEng, setDetailEng] = useState('');
  const [detailId, setDetailId] = useState('');
  const [canUpload, setCanUpload] = useState(false);
  const [categories, setCategories] = useState('');
  const [date, setDate] = useState(new Date());
  const [status, setStatus] = useState('Inactive');
  const [images, setImages] = useState([]);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalFailed, setModalFailed] = useState(false);
  const fileRef = useRef();
  const handleSubmit = () => {
    const data = new FormData();
    const newDate = new Date(date).toISOString();
    images.forEach((x) => data.append('media', x.file));
    data.append('title[en]', titleEng);
    data.append('title[id]', titleId);
    data.append('description[en]', detailEng);
    data.append('description[id]', detailId);
    data.append('categories', JSON.stringify([categories]));
    data.append('status', status === 'Active');
    data.append('published[at]', newDate);
    data.append('author', author);
    dispatch(fetchDataAddNews(data));
  };

  useEffect(() => {
    const params = {
      perpage: 100,
      'filter-type': 'news',
      'bool-status': true
    };
    dispatch(newsCategory.fetchDataNewsCategory(params));
    return () => {
      dispatch(newsCategory.resetNewsCategory());
    };
  }, []);

  useEffect(() => {
    if (reducer.type === FETCH_NEWS_ADD.SUCCESS) {
      setModalConfirmation(false);
      setModalSuccess(true);
    }
    if (reducer.type === FETCH_NEWS_ADD.FAILED) {
      setModalConfirmation(false);
      setModalFailed(true);
    }
  }, [reducer?.type]);

  useEffect(() => {
    setCanUpload(validate());
  }, [titleEng, detailEng, titleId, detailId, categories, author, images]);

  const validate = () => {
    if (!titleEng) {
      return false;
    }
    if (!detailEng) {
      return false;
    }
    if (!titleId) {
      return false;
    }
    if (!detailId) {
      return false;
    }
    if (!categories) {
      return false;
    }
    if (!author) {
      return false;
    }
    if (!images?.length > 0) {
      return false;
    }
    return true;
  };

  const fileRead = (val) => {
    setImages((prev) => [...prev, { url: URL.createObjectURL(val), file: val }]);
  };

  return (
    <Container fluid>
      <Form>
        <Row className="my-3">
          <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <Form.Group>
              <Form.Label className="fw-bold" style={{ color: '#5C5E61' }}>
                News Created Date<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                value={date.toISOString().substring(0, 10)}
                disabled></Form.Control>
            </Form.Group>
          </Col>
          <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <Form.Group>
              <Form.Label className="fw-bold" style={{ color: '#5C5E61' }}>
                News Published Date<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                value={date.toISOString().substring(0, 10)}
                onChange={(e) => setDate(new Date(e.target.value))}></Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-3">
          <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <Form.Group>
              <Form.Label className="fw-bold" style={{ color: '#5C5E61' }}>
                Category<span className="text-danger"> *</span>
              </Form.Label>
              <Form.Select onChange={(x) => setCategories(x.target.value)} value={categories}>
                <option value="" disabled hidden>
                  {newsReducer.isLoading && 'Loading...'}
                  {!newsReducer.isLoading &&
                    newsReducer.error &&
                    'Error retrieve Category, Please Reload!'}
                  {!newsReducer.isLoading && newsReducer.data && 'Choose Category'}
                </option>
                {newsReducer.data?.map((x) => (
                  <option key={x._id} value={x._id}>
                    {x.title.en}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <Form.Group>
              <Input
                label="Author"
                text={author}
                required={true}
                onChange={(e) => setAuthor(e.target.value)}
                error="Please fill the author’s name"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <Form.Group>
              <Input
                label="Title (English)"
                text={titleEng}
                required={true}
                onChange={(e) => setTitleEng(e.target.value)}
                error="Please fill the news title in english"
                as="textarea"
              />
            </Form.Group>
          </Col>
          <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <Form.Group>
              <Input
                label="Title (Bahasa)"
                text={titleId}
                required={true}
                onChange={(e) => setTitleId(e.target.value)}
                error="Please fill the news title in bahasa"
                as="textarea"
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="my-4">
          <RichEditor
            required
            label="Details (English)"
            value={detailEng}
            isValid={detailEng !== ''}
            error="Please Input Your Details"
            onChangeText={setDetailEng}
          />
        </Form.Group>
        <Form.Group className="my-4">
          <RichEditor
            required
            label="Details (Indonesia)"
            value={detailId}
            isValid={detailId !== ''}
            error="Please Input Your Details"
            onChangeText={setDetailId}
          />
        </Form.Group>
        <Row className="my-4">
          <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <FilePicker
              required
              ref={fileRef}
              onSuccess={(val) => {
                fileRead(val);
              }}
            />
          </Col>
          <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <Form.Group>
              <Form.Label className="fw-bold" style={{ color: '#5C5E61' }}>
                Status<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select onChange={(x) => setStatus(x.target.value)}>
                <option>Inactive</option>
                <option>Active</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {images.map((x, i) => (
            <>
              {x.url && (
                <ImagePreview
                  index={i}
                  src={x?.url}
                  key={x.url}
                  onDeletePress={() => {
                    setImages((prev) => prev.filter((y) => x.url !== y.url));
                  }}
                  onPreviewPress={(y) => {
                    if (images[y].url) {
                      window.open(images[y].url, '_blank');
                    }
                  }}
                />
              )}
            </>
          ))}
        </Row>
        <Col className="text-end my-5 mx-2">
          <Button
            type="button"
            className="btn btn-danger py-2 px-3 fw-bold text-uppercase"
            disabled={!canUpload}
            onClick={() => {
              setModalConfirmation(true);
            }}>
            ADD
          </Button>
        </Col>
      </Form>
      <PopUpConfirmation
        show={modalConfirmation}
        text="Are you sure to add new news?"
        textsubmit="YES, ADD"
        onHide={() => setModalConfirmation(false)}
        disabled={reducer.isLoading}
        onSubmit={handleSubmit}
      />
      <PopUpSuccess
        show={modalSuccess}
        textsubmit="OKAY"
        onHide={() => {
          if (status === 'Active') {
            window.open('/news');
          }
        }}
        onSubmit={() => {
          setModalSuccess(false);
          navigate(-1);
          dispatch(news.resetAddNews());
        }}
        text="You can change news status that you published by edit the news"
        textcancel={status === 'Active' ? 'VIEW SITE' : null}
      />
      <PopUpFailed
        show={modalFailed}
        textsubmit="Back"
        text={reducer?.error}
        onSubmit={() => {
          setModalFailed(false);
          dispatch(news.resetAddNews());
        }}
      />
    </Container>
  );
};

export default NewsCreate;
