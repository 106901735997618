import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { MdOutlineCancel } from 'react-icons/md';
import './index.css';

const PopUpFailed = (props) => {
  const { onSubmit, text, textsubmit, disabled } = props;
  return (
    <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className="d-flex justify-content-center">
          <MdOutlineCancel size={'10rem'} color="red" className="py-3" />
        </div>
        <h3 className="d-flex justify-content-center mb-3 pop-up-failed">Failed</h3>
        <div className="text-center">{text}</div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center border-0">
        <Button
          onClick={onSubmit}
          variant="danger"
          disabled={disabled}
          className="fw-bold text-uppercase">
          {textsubmit}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

PopUpFailed.defaultProps = {
  text: 'Failed',
  textsubmit: 'Done',
  disabled: false
};

PopUpFailed.propTypes = {
  onSubmit: PropTypes.func,
  text: PropTypes.string,
  textsubmit: PropTypes.string,
  textcancel: PropTypes.string,
  disabled: PropTypes.bool
};

export default PopUpFailed;
