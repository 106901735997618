import { newsUrl, newsExternalUrl } from './constant';
import { httpHelper } from '../utils';

export const getAll = (params) => httpHelper.get(newsUrl, { params });
export const getById = (id) => httpHelper.get(`${newsUrl}/${id}`);
export const add = (data) => httpHelper.post(newsUrl, data);
export const update = (id, data) => httpHelper.put(`${newsUrl}/${id}`, data);
export const reorder = (data) => httpHelper.put(`${newsUrl}/reorder`, data);
export const remove = (id) => httpHelper.remove(`${newsUrl}/${id}`);
export const addImage = (id, data) => httpHelper.post(`${newsUrl}/${id}/attachment`, data);
export const removeImage = (id, attachmentId) =>
  httpHelper.remove(`${newsUrl}/${id}/attachment/${attachmentId}`);

// External
export const getAllExternal = (params) =>
  httpHelper.get(newsExternalUrl, { params, isExternalApi: true });
export const getByIdExternal = (id) =>
  httpHelper.get(`${newsExternalUrl}/${id}`, { isExternalApi: true });
