import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@mui/lab';
import { Fragment } from 'react';
import { Col, Image, Nav, Row } from 'react-bootstrap';
import './index.css';
import PropTypes from 'prop-types';
import { FaChevronRight } from 'react-icons/fa';

const TimelineWithimage = ({ data, xxl, xl, lg, md, sm, xs, withShadow }) => {
  return (
    <Fragment>
      <Timeline>
        {data.map((x, i) => (
          <Fragment key={i.toString()}>
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                <Row className="d-flex justify-content-center align-items-center">
                  <Col xxl={xxl} xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
                    <Image
                      rounded
                      src={x.image}
                      className={`mb-5 ${withShadow && 'shadow'}`}
                      fluid
                      style={{ transform: 'translateY(-20%)' }}
                    />
                  </Col>
                </Row>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    backgroundColor: '#E42313'
                  }}>
                  <div className="d-flex mx-2 fw-bold">{(i + 1).toString()}</div>
                </TimelineDot>
                {i + 1 !== data.length && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Row>
                  <div
                    className="d-flex align-items-center fw-bold h4"
                    style={{ marginTop: '.6rem' }}>
                    {x.title}
                  </div>
                  <Col xxl={10}>
                    <div className="justify-content-evenly item-text mt-3 text-timeline">
                      {x.description}
                    </div>
                    {x?.onPress && (
                      <Nav.Link
                        href={`${x?.onPress?.link}`}
                        className="link-text-timeline fw-bold mt-3">
                        {x?.onPress?.title}
                        <FaChevronRight className="ms-3 link-text-timeline" />
                      </Nav.Link>
                    )}
                  </Col>
                </Row>
              </TimelineContent>
            </TimelineItem>
          </Fragment>
        ))}
      </Timeline>
    </Fragment>
  );
};

TimelineWithimage.propTypes = {
  data: PropTypes.array,
  xxl: PropTypes.number,
  xl: PropTypes.number,
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  xs: PropTypes.number,
  withShadow: PropTypes.bool
};

TimelineWithimage.defaultProps = {
  xxl: 4,
  xl: 4,
  lg: 4,
  md: 5,
  sm: 7,
  xs: 8,
  withShadow: false
};

export default TimelineWithimage;
