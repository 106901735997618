import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { TbCircleCheck } from 'react-icons/tb';
import './index.css';

const PopUpSuccess = (props) => {
  const { onSubmit, text, textsubmit, disabled, title, textcancel, onHide } = props;
  return (
    <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className="d-flex justify-content-center">
          <TbCircleCheck size={'10rem'} color="#00C853" className="py-3" />
        </div>
        <h3 className="d-flex justify-content-center mb-3 pop-up-success">{title}</h3>
        <div className="text-center">{text}</div>
      </Modal.Body>
      <Modal.Footer
        className={`${textcancel ? 'justify-content-between' : 'justify-content-center'} border-0`}>
        {textcancel && (
          <Button
            onClick={onHide}
            variant="light"
            className="popup-confirmation border-0 fw-bold text-uppercase"
            disabled={disabled}>
            {textcancel}
          </Button>
        )}
        <Button
          onClick={onSubmit}
          variant="danger"
          disabled={disabled}
          className="fw-bold text-uppercase">
          {textsubmit}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

PopUpSuccess.defaultProps = {
  text: 'Success',
  textsubmit: 'DONE',
  disabled: false,
  title: 'SUCCESS',
  textcancel: null
};

PopUpSuccess.propTypes = {
  onSubmit: PropTypes.func,
  text: PropTypes.string,
  textsubmit: PropTypes.string,
  textcancel: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  onHide: PropTypes.func
};

export default PopUpSuccess;
