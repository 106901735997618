import api from '../../../apis';
import { errorParser } from '../../../utils';

import { NEWSCATEGORY } from '../../types';

export const fetchRequest = (type) => ({
  type: NEWSCATEGORY[type].REQUEST
});

export const fetchSuccess = (type, data) => ({
  type: NEWSCATEGORY[type].SUCCESS,
  payload: data
});

export const fetchFail = (type, error) => ({
  type: NEWSCATEGORY[type].FAILED,
  payload: error
});

export const fetchDataNewsCategory = (params, isExternal) => async (dispatch) => {
  const type = 'FETCH_NEWS_CATEGORIES';
  try {
    dispatch(fetchRequest(type));
    const { data } = await (!isExternal
      ? api.newsCategory.getAll(params)
      : api.newsCategory.getAllExternal(params));
    dispatch(fetchSuccess(type, data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataDetailNewsCategory = (id, isExternal) => async (dispatch) => {
  const type = 'FETCH_NEWS_CATEGORIES_DETAIL';
  try {
    dispatch(fetchRequest(type));
    const { data } = await (!isExternal
      ? api.newsCategory.getById(id)
      : api.newsCategory.getByIdExternal(id));
    dispatch(fetchSuccess(type, data.data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataAddNewsCategory = (payload) => async (dispatch) => {
  const type = 'FETCH_NEWS_CATEGORIES_ADD';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.newsCategory.add(payload);
    dispatch(fetchSuccess(type, data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataUpdateNewsCategory = (payload) => async (dispatch) => {
  const type = 'FETCH_NEWS_CATEGORIES_UPDATE';
  try {
    dispatch(fetchRequest(type));
    await Promise.all(
      payload.map(async (x) => {
        await api.newsCategory.update(x.id, x.params);
      })
    );
    dispatch(fetchSuccess(type, { message: 'Successfully to update news category' }));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataRemoveNewsCategory = (payload) => async (dispatch) => {
  const type = 'FETCH_NEWS_CATEGORIES_REMOVE';
  try {
    dispatch(fetchRequest(type));
    await Promise.all(
      payload.map(async (x) => {
        await api.newsCategory.remove(x._id);
      })
    );
    dispatch(fetchSuccess(type, { message: 'Successfully to delete news category' }));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataAddImageNewsCategory = (id, payload) => async (dispatch) => {
  const type = 'FETCH_NEWS_CATEGORIES_ADD_IMAGE';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.newsCategory.addImage(id, payload);
    dispatch(fetchSuccess(type, data.data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataRemoveImageNewsCategory = (id, attachmentId) => async (dispatch) => {
  const type = 'FETCH_NEWS_CATEGORIES_REMOVE_IMAGE';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.newsCategory.removeImage(id, attachmentId);
    dispatch(fetchSuccess(type, data.data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const resetAddNewsCategory = () => ({
  type: NEWSCATEGORY.FETCH_NEWS_CATEGORIES_ADD.RESET
});
export const resetDeleteNewsCategory = () => ({
  type: NEWSCATEGORY.FETCH_NEWS_CATEGORIES_REMOVE.RESET
});

export const resetUpdateNewsCategory = () => ({
  type: NEWSCATEGORY.FETCH_NEWS_CATEGORIES_UPDATE.RESET
});

export const resetNewsCategory = () => ({
  type: NEWSCATEGORY.FETCH_NEWS_CATEGORIES.RESET
});

export const resetDetailNewsCategory = () => ({
  type: NEWSCATEGORY.FETCH_NEWS_CATEGORIES_DETAIL.RESET
});
