import api from '../../../apis';
import { errorParser } from '../../../utils';
import { FETCH_TERMS_AND_CONDITIONS_DELETE } from '../../types/termsandconditions';

const request = () => ({
  type: FETCH_TERMS_AND_CONDITIONS_DELETE.REQUEST
});

const success = (payload) => ({
  type: FETCH_TERMS_AND_CONDITIONS_DELETE.SUCCESS,
  payload
});

const failed = (error) => ({
  type: FETCH_TERMS_AND_CONDITIONS_DELETE.FAILED,
  payload: error
});

const fetchData = (arrayId) => async (dispatch) => {
  try {
    dispatch(request());
    await Promise.all(
      arrayId.map(async (x) => {
        await api.termsAndConditions.remove(x._id);
      })
    );
    dispatch(success({ message: 'Successfully to delete tnc' }));
  } catch (error) {
    const message = errorParser(error);
    dispatch(failed(message));
  }
};

export { fetchData };
