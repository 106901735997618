export const FETCH_PRIVACY_POLICY_LIST = {
  REQUEST: 'FETCH_PRIVACY_POLICY_LIST_REQUEST',
  SUCCESS: 'FETCH_PRIVACY_POLICY_LIST_SUCCESS',
  FAILED: 'FETCH_PRIVACY_POLICY_LIST_FAILED',
  RESET: 'FETCH_PRIVACY_POLICY_LIST_RESET'
};
export const FETCH_PRIVACY_POLICY_DETAIL = {
  REQUEST: 'FETCH_PRIVACY_POLICY_DETAIL_REQUEST',
  SUCCESS: 'FETCH_PRIVACY_POLICY_DETAIL_SUCCESS',
  FAILED: 'FETCH_PRIVACY_POLICY_DETAIL_FAILED',
  RESET: 'FETCH_PRIVACY_POLICY_DETAIL_RESET'
};
export const FETCH_PRIVACY_POLICY_CREATE = {
  REQUEST: 'FETCH_PRIVACY_POLICY_CREATE_REQUEST',
  SUCCESS: 'FETCH_PRIVACY_POLICY_CREATE_SUCCESS',
  FAILED: 'FETCH_PRIVACY_POLICY_CREATE_FAILED',
  RESET: 'FETCH_PRIVACY_POLICY_CREATE_RESET'
};
export const FETCH_PRIVACY_POLICY_UPDATE = {
  REQUEST: 'FETCH_PRIVACY_POLICY_UPDATE_REQUEST',
  SUCCESS: 'FETCH_PRIVACY_POLICY_UPDATE_SUCCESS',
  FAILED: 'FETCH_PRIVACY_POLICY_UPDATE_FAILED',
  RESET: 'FETCH_PRIVACY_POLICY_UPDATE_RESET'
};
export const FETCH_PRIVACY_POLICY_UPDATE_STATUS = {
  REQUEST: 'FETCH_PRIVACY_POLICY_UPDATE_STATUS_REQUEST',
  SUCCESS: 'FETCH_PRIVACY_POLICY_UPDATE_STATUS_SUCCESS',
  FAILED: 'FETCH_PRIVACY_POLICY_UPDATE_STATUS_FAILED',
  RESET: 'FETCH_PRIVACY_POLICY_UPDATE_STATUS_RESET'
};
export const FETCH_PRIVACY_POLICY_DELETE = {
  REQUEST: 'FETCH_PRIVACY_POLICY_DELETE_REQUEST',
  SUCCESS: 'FETCH_PRIVACY_POLICY_DELETE_SUCCESS',
  FAILED: 'FETCH_PRIVACY_POLICY_DELETE_FAILED',
  RESET: 'FETCH_PRIVACY_POLICY_DELETE_RESET'
};
