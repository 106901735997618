import { REGISTER } from '../../types';

const initialState = {
  data: null,
  isLoading: false,
  error: null
};

export const registerReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case REGISTER.FETCH_REGISTER_REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case REGISTER.FETCH_REGISTER_SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        data: payload
      };
    case REGISTER.FETCH_REGISTER_FAILED:
      return {
        ...initialState,
        isLoading: false,
        error: payload
      };

    default:
      return state;
  }
};

export const registerInternalReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case REGISTER.FETCH_REGISTER_INTERNAL_REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case REGISTER.FETCH_REGISTER_INTERNAL_SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        data: payload
      };
    case REGISTER.FETCH_REGISTER_INTERNAL_FAILED:
      return {
        ...initialState,
        isLoading: false,
        error: payload
      };

    default:
      return state;
  }
};
