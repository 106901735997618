import { privacyPolicyExternalUrl, privacyPolicyUrl, templateRegisterDoc } from './constant';
import { httpHelper } from '../utils';

export const getAll = (params) => httpHelper.get(privacyPolicyUrl, { params });
export const getById = (id) => httpHelper.get(`${privacyPolicyUrl}/${id}`);
export const add = (data) => httpHelper.post(privacyPolicyUrl, data);
export const update = (id, data) => httpHelper.put(`${privacyPolicyUrl}/${id}`, data);
export const updateStatus = (id, data) =>
  httpHelper.put(`${privacyPolicyUrl}/${id}/update-status`, data);
export const remove = (id) => httpHelper.remove(`${privacyPolicyUrl}/${id}`);

// external
export const getAllExternal = (params) =>
  httpHelper.get(privacyPolicyExternalUrl, { params, isExternalApi: true });
export const getByIdExternal = (id) =>
  httpHelper.get(`${privacyPolicyExternalUrl}/${id}`, { isExternalApi: true });

// Template register doc
export const getTemplateDoc = (token) =>
  httpHelper
    .get(`${templateRegisterDoc}/${token}`, {
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      responseType: 'arraybuffer'
    })
    .then((response) => {
      // Create a Blob with the correct MIME type
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Surat-Pernyataan-Kerahasiaan-Informasi.docx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
