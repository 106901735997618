import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, OverlayTrigger, Popover, Table, Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, PopUpConfirmation, PopUpFailed, PopUpSuccess } from '../../../../../components';
import { information } from '../../../../../stores/actions';
import { Link, useOutletContext } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FiEdit, FiEye, FiEyeOff, FiTrash, FiUser } from 'react-icons/fi';
import update from 'immutability-helper';
import {
  FETCH_INFORMATION_REMOVE,
  FETCH_INFORMATION_UPDATE
} from '../../../../../stores/types/information';
import DraggableItem from '../../Components/DraggableItem';
import { shorthand } from '../../../../../utils';
import { getStatus } from '../../utils';
import Switch from 'react-switch';
import moment from 'moment';

const InformationList = () => {
  const dispatch = useDispatch();
  const informationReducer = useSelector((state) => state.informationReducer);
  const updateReducer = useSelector((state) => state.informationUpdateReducer);
  const removeReducer = useSelector((state) => state.informationRemoveReducer);

  const [informationData, setInformationData] = useState(informationReducer.data ?? []);
  const [search] = useOutletContext();
  const [page, setPage] = useState(0);
  const [perpage, setPerpage] = useState(5);
  const [totalPage, setTotalPage] = useState(1);
  const [itemChecked, setItemChecked] = useState([]);
  const [counter, setCounter] = useState([]);

  const [modal, setModal] = useState({ visible: false });
  const [isSuccess, setIsSuccess] = useState({ visible: false });
  const [isFail, setIsFail] = useState(false);
  const [isDropped, setIsDropped] = useState(true);

  const DATE_FORMAT = 'DD/MM/YYYY';
  const NEWS_DISABLE_BUTTON = 'news-list-disable-button ';
  const NEWS_ACTIVE_BUTTON = 'news-list-active-button ';

  useEffect(() => {
    if (informationReducer.data) {
      setInformationData(informationReducer.data);
    }
  }, [informationReducer]);

  useEffect(() => {
    const delay = setTimeout(() => {
      fetching();
    }, 500);
    return () => {
      clearTimeout(delay);
      dispatch(information.resetInformation());
    };
  }, [search, page, perpage]);

  useEffect(() => {
    const delay = setTimeout(() => {
      if (informationData.length > 0 && !search && isDropped && !informationReducer.isLoading) {
        const newData = informationData.map((x, i) => ({
          ...x,
          order: informationReducer.meta?.total - (i + 1) - perpage * page
        }));
        dispatch(information.fetchDataReorderInformation(newData));
        setIsDropped(false);
      }
    }, 750);
    return () => {
      clearTimeout(delay);
    };
  }, [informationData, search, isDropped, informationReducer.isLoading]);

  useEffect(() => {
    setItemChecked([]);
    if (informationReducer.meta) {
      setTotalPage(informationReducer.meta?.totalpage);
      setCounter(informationReducer?.meta?.currpage * perpage + informationReducer?.data?.length);
    }
  }, [informationReducer]);

  useEffect(() => {
    setItemChecked([]);
  }, [page, search]);

  useEffect(() => {
    if (removeReducer.type === FETCH_INFORMATION_REMOVE.SUCCESS) {
      setModal({ visible: false });
      fetching();
      setIsSuccess({
        visible: true,
        text: 'Selected information successfully deleted',
        onSubmit: () => {
          setIsSuccess({ visible: false });
          dispatch(information.resetDeleteInformation());
        }
      });
    }
    if (removeReducer.type === FETCH_INFORMATION_REMOVE.FAILED) {
      fetching();
      setModal({ visible: false });
      setIsFail(true);
    }
    dispatch(information.resetDeleteInformation());
  }, [removeReducer?.type]);

  useEffect(() => {
    if (updateReducer.type === FETCH_INFORMATION_UPDATE.SUCCESS) {
      setModal({ visible: false });
      setIsSuccess({
        visible: true,
        text: 'Selected information successfully updated',
        onSubmit: () => {
          setIsSuccess({ visible: false });
          dispatch(information.resetUpdateInformation());
        }
      });
      fetching();
    }
    if (updateReducer.type === FETCH_INFORMATION_UPDATE.FAILED) {
      setModal({ visible: false });
      setIsFail(true);
      fetching();
    }
  }, [updateReducer?.type]);

  const fetching = () => {
    dispatch(
      information.fetchDataInformation({
        'likeor-title.en': search,
        'likeor-title.id': search,
        'sort-order': -1,
        currpage: page,
        perpage: perpage
      })
    );
  };
  const onPressNext = () => {
    if (page + 1 >= totalPage) {
      return;
    }
    setPage(page + 1);
    setIsDropped(true);
    setCounter(0);
  };

  const onPressPrev = () => {
    if (page + 1 <= 1) {
      return;
    }
    setPage(page - 1);
    setIsDropped(true);
    setCounter(0);
  };

  const onAddDelete = (val) => {
    if (!itemChecked.some((data) => data._id === val._id)) {
      setItemChecked([...itemChecked, val]);
    } else {
      setItemChecked(itemChecked.filter((x) => x._id !== val._id));
    }
  };

  const onAddAllDelete = () => {
    if (informationData?.every((x) => itemChecked.includes(x))) {
      setItemChecked([]);
    } else
      setItemChecked(
        itemChecked.concat(
          informationData?.filter((val) => !itemChecked.includes(val)).map((x) => x)
        )
      );
  };

  const onPressUpdate = (status = true) => {
    const payload = itemChecked
      .filter((x) => x.status === status)
      .map((value) => {
        const params = { status: !value.status };
        return { id: value._id, params };
      });
    setModal({
      visible: true,
      textSubmit: 'Update',
      onSubmit: () => dispatch(information.fetchDataUpdateInformation(payload)),
      text: `Are you sure to update the status of selected information?`
    });
  };

  const onPressModal = () => {
    let text = '';
    if (itemChecked.length < 1) {
      return;
    }
    if (informationReducer.data?.every((x) => itemChecked.includes(x._id))) {
      text = 'Are you sure to delete all information?';
    } else {
      text = `Are you sure to delete this ${informationReducer?.data
        ?.filter((x) => itemChecked.includes(x._id))
        .map((val) => val?.title?.en)
        .toLocaleString()} information?`;
    }
    setModal({
      visible: true,
      textSubmit: 'Delete',
      onSubmit: () => dispatch(information.fetchDataRemoveInformation(itemChecked)),
      text
    });
  };

  const onToggleStatus = (value) => {
    const params = { status: !value.status };
    setModal({
      visible: true,
      textSubmit: 'UPDATE',
      onSubmit: () => dispatch(information.fetchDataUpdateInformation([{ id: value._id, params }])),
      text: `Are you sure to update the status ${value?.title?.en} of information?`
    });
  };

  const onToggleDelete = (value) => {
    setModal({
      visible: true,
      textSubmit: 'Delete',
      onSubmit: () => dispatch(information.fetchDataRemoveInformation([value])),
      text: `Are you sure to delete ${value?.title?.en} information?`
    });
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setInformationData((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      })
    );
  }, []);

  const renderRow = useCallback(
    (x, index) => {
      const popOverRoles = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">Viewed By</Popover.Header>
          <Popover.Body className="text-capitalize">{x.roles.join(', ')}</Popover.Body>
        </Popover>
      );
      const popOverDivision = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">Viewed By</Popover.Header>
          <Popover.Body className="text-capitalize">{x?.divisions?.join(', ')}</Popover.Body>
        </Popover>
      );
      const customRender = (
        <>
          <td className="text-center align-middle">
            <div className="d-flex">
              <Form.Check
                role={'button'}
                type="checkbox"
                id={x._id}
                label={page * perpage + index + 1}
                onChange={() => onAddDelete(x)}
                checked={itemChecked.some((data) => data === x)}
              />
            </div>
          </td>
          <td>
            <Link to={`${x._id}/edit`} className="link-news-text text-item-title">
              {x.title.en}
            </Link>
            <div className="news-list-category">{x.type}</div>
          </td>
          <td className="align-middle align-self-center justify-content-center">
            <div className="d-flex flex-row gap-2">
              <div className="d-flex justify-content-center">
                <OverlayTrigger trigger="click" placement="right" overlay={popOverRoles}>
                  <Badge role="button" bg="white" pill className="border border-primary">
                    <span className="d-flex align-items-center justify-content-center gap-1">
                      <span className="rounded-circle p-1 bg-primary">
                        <FiUser size={12} />
                      </span>
                      <span className="text-primary">{x.roles.length} Roles</span>
                    </span>
                  </Badge>
                </OverlayTrigger>
              </div>
              <div className="d-flex justify-content-center">
                <OverlayTrigger trigger="click" placement="right" overlay={popOverDivision}>
                  <Badge role="button" bg="white" pill className="border border-primary">
                    <span className="d-flex align-items-center justify-content-center gap-1">
                      <span className="rounded-circle p-1 bg-primary">
                        <FiUser size={12} />
                      </span>
                      <span className="text-primary">{x?.divisions?.length} Divisions</span>
                    </span>
                  </Badge>
                </OverlayTrigger>
              </div>
            </div>
          </td>
          <td className="align-middle align-self-center justify-content-center">
            <div className="d-flex justify-content-between">
              <div>
                {moment(x.published.start).format(DATE_FORMAT)}
                {x.published.end && ` - ${moment(x.published.end).format(DATE_FORMAT)}`}
              </div>
            </div>
          </td>
          <td className="align-middle align-self-center justify-content-center">
            <div className="d-flex justify-content-between gap-2">
              <Switch
                onColor="#66DE98"
                uncheckedIcon={false}
                checkedIcon={false}
                offColor="#FFD079"
                onChange={() => onToggleStatus(x)}
                checked={!!x?.status}
              />
              <div className={`${getStatus(x.status)}`}>{x.status ? 'active' : 'inactive'}</div>
            </div>
          </td>
          <td className="align-middle align-items-center justify-content-center text-center">
            {x.visit || 0}
          </td>
          <td className="align-middle align-self-center justify-content-center">
            <div className="d-flex justify-content-between">
              <div>{x.author}</div>
            </div>
          </td>
          <td className="gap-3 align-middle align-items-center justify-content-center">
            {moment(x.created.at).format(DATE_FORMAT)}
          </td>
          <td className="gap-3 align-middle">
            <div className="gap-3 d-flex justify-content-center align-items-center">
              <Link onClick={() => onToggleDelete(x)} className="d-inline-flex">
                <FiTrash color="red" size={20} />
              </Link>
              <Link to={`${x._id}/edit`} className="d-inline-flex">
                <FiEdit color="black" size={20} />
              </Link>
            </div>
          </td>
        </>
      );
      return (
        <DraggableItem
          i={index}
          x={x}
          page={page}
          perpage={perpage}
          itemDelete={itemChecked}
          onAddDelete={onAddDelete}
          onToggleDelete={onToggleDelete}
          onToggleStatus={onToggleStatus}
          key={x._id}
          moveCard={moveCard}
          customRender={customRender}
          dropCard={() => setIsDropped(true)}
        />
      );
    },
    [itemChecked]
  );

  return (
    <div>
      <div className="d-flex justify-content-end">
        <a
          role={'button'}
          onClick={() => {
            if (itemChecked.length < 1) {
              return;
            }
            if (itemChecked.every((x) => x.status !== false)) {
              return;
            }
            onPressUpdate(false);
          }}
          className={`d-flex align-middle align-items-center my-3 ${
            itemChecked.length > 0 ? NEWS_ACTIVE_BUTTON : NEWS_DISABLE_BUTTON
          }text-decoration-none`}>
          <FiEye className="me-2 " size={20} />
          <div className="me-3">Active</div>
        </a>
        <a
          role={'button'}
          onClick={() => {
            if (itemChecked.length < 1) {
              return;
            }
            if (itemChecked.every((x) => x.status !== true)) {
              return;
            }
            onPressUpdate(true);
          }}
          className={`d-flex align-middle align-items-center my-3 ${
            itemChecked.length > 0 ? 'news-list-inactive-button ' : NEWS_DISABLE_BUTTON
          } text-decoration-none`}>
          <FiEyeOff className="me-2 " size={20} />
          <div className="me-3">Inactive</div>
        </a>
        <a
          role={'button'}
          className={`d-flex align-middle align-items-center ${
            itemChecked.length > 0 ? 'news-list-delete ' : NEWS_DISABLE_BUTTON
          } my-3 text-decoration-none`}
          onClick={() => {
            if (itemChecked.length < 1) {
              return;
            }
            onPressModal();
          }}>
          <FiTrash className="me-2" /> <div className="me-3">Delete</div>
        </a>
      </div>
      <Table striped>
        <thead className="table-light">
          <tr>
            <th width={30}></th>
            <th width={50} className="text-center text-uppercase align-middle">
              <div className="d-flex">
                <Form.Check
                  className="mb-0"
                  role={'button'}
                  type="checkbox"
                  id="check-all"
                  label={'No'}
                  onChange={onAddAllDelete}
                  checked={
                    informationReducer?.data?.length > 0 &&
                    informationReducer?.data?.every((x) => itemChecked?.includes(x))
                  }
                />
              </div>
            </th>
            <th width={200} className="text-uppercase align-middle">
              Information Title
            </th>
            <th width={100} className="text-uppercase align-middle text-center">
              Viewed By
            </th>
            <th width={150} className="text-uppercase align-middle">
              Published Date
            </th>
            <th width={100} className="text-uppercase align-middle">
              Status
            </th>
            <th width={100} className="text-center text-uppercase align-middle">
              Visit
            </th>
            <th width={100} className="text-uppercase align-middle">
              Author
            </th>
            <th width={130} className="text-uppercase align-middle">
              Created Date
            </th>
            <th width={100} className="text-center text-uppercase align-middle">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {informationReducer.isLoading && (
            <tr>
              <td colSpan={10}>
                <Loader />
              </td>
            </tr>
          )}
          {!informationReducer.isLoading &&
            informationReducer.data &&
            informationData.map((x, i) => (
              <React.Fragment key={i.toString()}>{renderRow(x, i)}</React.Fragment>
            ))}
          {!informationReducer.isLoading &&
            informationReducer.data &&
            informationReducer.data.length === 0 && (
              <tr>
                <td colSpan={10} align="center" valign="center">
                  <p>No Data</p>
                </td>
              </tr>
            )}
          {!informationReducer.isLoading && informationReducer.error && (
            <tr>
              <td colSpan={10}>
                <>{informationReducer.error}</>
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={6}>
              {page * perpage + 1} - {counter || 0} of {informationReducer.meta?.filtered || 0}
            </td>
            <td colSpan={4}>
              <div className="d-flex gap-4 align-items-center justify-content-center float-end">
                <div className="dropdown">
                  <div
                    role={'button'}
                    className="dropdown-toggle"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Rows per page : {perpage || 'All'}
                  </div>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <>
                      {shorthand.getRowsPerPage(5, 9).map((x, i) => (
                        <li key={i.toString()}>
                          <a className="d-flex dropdown-item" onClick={() => setPerpage(x.value)}>
                            {x.label.toString()}
                          </a>
                        </li>
                      ))}
                    </>
                  </ul>
                </div>
                <span>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={onPressPrev}
                    disabled={page + 1 <= 1}>
                    <FaChevronLeft size={12} />
                  </Button>
                  <div className="align-middle d-inline-block mx-2">
                    {page + 1} / {totalPage || 1}
                  </div>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={onPressNext}
                    disabled={page + 1 >= totalPage}>
                    <FaChevronRight size={12} />
                  </Button>
                </span>
              </div>
            </td>
          </tr>
        </tfoot>
      </Table>
      <PopUpConfirmation
        show={modal?.visible}
        textsubmit={modal?.textSubmit}
        disabled={removeReducer.isLoading}
        onHide={() => setModal({ visible: false })}
        {...modal}
      />
      <PopUpSuccess
        show={isSuccess.visible}
        textsubmit="Okay"
        onSubmit={isSuccess?.onSubmit}
        text={isSuccess?.text}
      />
      <PopUpFailed
        show={isFail}
        textsubmit="Back"
        text={removeReducer?.error}
        onSubmit={() => {
          setIsFail(false);
          dispatch(information.resetDeleteInformation());
        }}
      />
    </div>
  );
};

export default InformationList;
