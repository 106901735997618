import api from '../../../apis';
import { errorParser } from '../../../utils';

import { PRODUCTCATEGORY } from '../../types';

export const fetchRequest = (type) => ({
  type: PRODUCTCATEGORY[type].REQUEST
});

export const fetchSuccess = (type, data) => ({
  type: PRODUCTCATEGORY[type].SUCCESS,
  payload: data
});

export const fetchFail = (type, error) => ({
  type: PRODUCTCATEGORY[type].FAILED,
  payload: error
});

export const fetchDataProduct = (params, isExternal) => async (dispatch) => {
  const type = 'FETCH_PRODUCT_CATEGORIES';
  try {
    dispatch(fetchRequest(type));
    const { data } = await (!isExternal
      ? api.productCategory.getAll(params)
      : api.productCategory.getAllExternal(params));
    dispatch(fetchSuccess(type, data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataDetailProduct = (id, isExternal) => async (dispatch) => {
  const type = 'FETCH_PRODUCT_CATEGORIES_DETAIL';
  try {
    dispatch(fetchRequest(type));
    const { data } = await (!isExternal
      ? api.productCategory.getById(id)
      : api.productCategory.getByIdExternal(id));
    dispatch(fetchSuccess(type, data.data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataAddProduct = (payload) => async (dispatch) => {
  const type = 'FETCH_PRODUCT_CATEGORIES_ADD';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.productCategory.add(payload);
    dispatch(fetchSuccess(type, data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataUpdateProduct = (payload) => async (dispatch) => {
  const type = 'FETCH_PRODUCT_CATEGORIES_UPDATE';
  try {
    dispatch(fetchRequest(type));
    await Promise.all(
      payload.map(async (x) => {
        await api.productCategory.update(x.id, x.params);
      })
    );
    dispatch(fetchSuccess(type, { message: 'Successfully to update product category' }));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataRemoveProduct = (arrayId) => async (dispatch) => {
  const type = 'FETCH_PRODUCT_CATEGORIES_REMOVE';
  try {
    dispatch(fetchRequest(type));
    await Promise.all(
      arrayId.map(async (x) => {
        await api.productCategory.remove(x._id);
      })
    );
    dispatch(fetchSuccess(type, { message: 'Successfully to delete product category' }));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataAddImageProduct = (id, payload) => async (dispatch) => {
  const type = 'FETCH_PRODUCT_CATEGORIES_ADD_IMAGE';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.productCategory.addImage(id, payload);
    dispatch(fetchSuccess(type, data.data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataRemoveImageProduct = (id, attachmentId) => async (dispatch) => {
  const type = 'FETCH_PRODUCT_CATEGORIES_REMOVE_IMAGE';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.productCategory.removeImage(id, attachmentId);
    dispatch(fetchSuccess(type, data.data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const resetAddProduct = () => ({
  type: PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_ADD.RESET
});
export const resetDeleteProduct = () => ({
  type: PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_REMOVE.RESET
});

export const resetUpdateProduct = () => ({
  type: PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_UPDATE.RESET
});

export const resetProduct = () => ({
  type: PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES.RESET
});

export const resetDetailProduct = () => ({
  type: PRODUCTCATEGORY.FETCH_PRODUCT_CATEGORIES_DETAIL.RESET
});
