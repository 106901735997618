export const FETCH_PRODUCT = {
  REQUEST: 'FETCH_PRODUCT_REQUEST',
  SUCCESS: 'FETCH_PRODUCT_SUCCESS',
  FAILED: 'FETCH_PRODUCT_FAILED',
  RESET: 'FETCH_PRODUCT_RESET'
};

export const FETCH_PRODUCT_DETAIL = {
  REQUEST: 'FETCH_PRODUCT_DETAIL_REQUEST',
  SUCCESS: 'FETCH_PRODUCT_DETAIL_SUCCESS',
  FAILED: 'FETCH_PRODUCT_DETAIL_FAILED',
  RESET: 'FETCH_PRODUCT_DETAIL_RESET'
};

export const FETCH_PRODUCT_ADD = {
  REQUEST: 'FETCH_PRODUCT_ADD_REQUEST',
  SUCCESS: 'FETCH_PRODUCT_ADD_SUCCESS',
  FAILED: 'FETCH_PRODUCT_ADD_FAILED',
  RESET: 'FETCH_PRODUCT_ADD_RESET'
};

export const FETCH_PRODUCT_UPDATE = {
  REQUEST: 'FETCH_PRODUCT_UPDATE_REQUEST',
  SUCCESS: 'FETCH_PRODUCT_UPDATE_SUCCESS',
  FAILED: 'FETCH_PRODUCT_UPDATE_FAILED',
  RESET: 'FETCH_PRODUCT_UPDATE_RESET'
};

export const FETCH_PRODUCT_REMOVE = {
  REQUEST: 'FETCH_PRODUCT_REMOVE_REQUEST',
  SUCCESS: 'FETCH_PRODUCT_REMOVE_SUCCESS',
  FAILED: 'FETCH_PRODUCT_REMOVE_FAILED',
  RESET: 'FETCH_PRODUCT_REMOVE_RESET'
};

export const FETCH_PRODUCT_ADD_IMAGE = {
  REQUEST: 'FETCH_PRODUCT_ADD_IMAGE_REQUEST',
  SUCCESS: 'FETCH_PRODUCT_ADD_IMAGE_SUCCESS',
  FAILED: 'FETCH_PRODUCT_ADD_IMAGE_FAILED'
};

export const FETCH_PRODUCT_REMOVE_IMAGE = {
  REQUEST: 'FETCH_PRODUCT_REMOVE_IMAGE_REQUEST',
  SUCCESS: 'FETCH_PRODUCT_REMOVE_IMAGE_SUCCESS',
  FAILED: 'FETCH_PRODUCT_REMOVE_IMAGE_FAILED'
};
