import React, { useCallback, useEffect, useState } from 'react';
import { loginBackground } from '../../../../assets';
import { Col, Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { auth as authAction } from '../../../../stores/actions';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { FETCH_SEND_OTP, FETCH_VERIFY_OTP } from '../../../../stores/types/auth';
// eslint-disable-next-line no-unused-vars
import { fetchSendOtpData, fetchVerifyOtp, logout } from '../../../../stores/actions/auth';
import { Timer } from '../../../../components';
import OtpInput from 'react-otp-input';
import { shorthand } from '../../../../utils';
import apis from '../../../../apis';

const LoginVerify = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authReducer = useSelector((state) => state.authReducer);
  const { isLoading, error, auth, type } = authReducer;
  const [otp, setOtp] = useState(null);
  const [timer, setTimer] = useState(0);
  const [showTimer, setShowTimer] = useState(false);

  const onError = (textError) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: textError
    }).then(() => {
      dispatch(authAction.fetchVerifyOtpFailed(null));
      dispatch(authAction.fetchResetType());
      if (textError === 'Your Session Has Expired. Please re-login!') redirectBackwards();
    });
  };

  useEffect(() => {
    if (!auth) {
      dispatch(logout());
      redirectBackwards();
    }
  }, [auth]);

  // login after otp
  useEffect(() => {
    document.body.style = '';
    if (error) {
      onError(error);
    }
    if (auth && type === FETCH_VERIFY_OTP.SUCCESS) {
      redirect();
    }
  }, [error, auth, type]);

  useEffect(() => {
    getTimer();
  }, [type]);

  // eslint-disable-next-line no-unused-vars
  const redirect = () => {
    dispatch(authAction.fetchResetType());
    // eslint-disable-next-line prettier/prettier
    if (!location.pathname.includes('/cms/login'))
      return (location.href = process.env.REACT_APP_URL_V3);
    if (!auth?.user?.roles.includes('cms-admin')) return onError("You don't have access");
    navigate('/cms');
  };

  const redirectBackwards = () => {
    if (location.pathname.includes('cms/login')) return navigate('/cms/login');
    navigate('/login');
  };

  const getTimer = () => {
    const params = {
      userId: auth?.user?._id
    };
    apis.auth.checkOtp(params).then(({ data }) => {
      if (data.data?.canResendIn) {
        const number = data.data?.canResendIn.match(/\d+/);
        if (parseInt(number[0], 10) !== 0) setShowTimer(true);
        setTimer(parseInt(number[0], 10));
      }
    });
  };

  const RenderTimer = useCallback(
    () => (
      <>
        <Timer withText={null} initialTime={timer} onTimerEnd={() => setShowTimer(false)} />
      </>
    ),
    [timer, showTimer]
  );

  return (
    <div className="d-flex flex-row row-container-form">
      <Col xxl="6" xl="6" lg="6" className="container-image-bg-login">
        <div
          className="image-cover-fluid"
          style={{ '--img': `url(${loginBackground})`, height: '91.5vh' }}>
          <div
            className="image-overlay"
            style={{ height: '91.5vh', backgroundColor: 'transparent' }}>
            <div className="image-caption">{t('AUTH-LOGIN-004')}</div>
            <div className="image-description mt-4">{t('AUTH-LOGIN-005')}</div>
          </div>
        </div>
      </Col>
      <Col
        className="p-5 col-container-form position-relative"
        xxl="6"
        xl="6"
        lg="6"
        md="12"
        sm="12"
        xs="12">
        <h1 className="mx-lg-5 px-xl-5 font-title-page-login">{t('AUTH-LOGIN-006')}</h1>

        <React.Fragment>
          <div className="d-grid mx-lg-5 px-xl-5 my-4">
            <div>
              {t('AUTH-LOGIN-008')}{' '}
              {authReducer?.otpNewRequest?.platform === 'telegram'
                ? shorthand.maskPhoneNumber(auth?.user?.telegramUsername)
                : shorthand.maskEmail(auth?.user?.email)}
              {showTimer && (
                <React.Fragment>
                  <span>. {t('AUTH-LOGIN-009')} </span>
                  <RenderTimer />
                </React.Fragment>
              )}
              {!showTimer && (
                <span>
                  . {t('AUTH-LOGIN-010')}?{' '}
                  <a
                    onClick={() => dispatch(fetchSendOtpData(authReducer?.otpNewRequest?.platform))}
                    className="text-danger custom-a-tag-style">
                    {t('AUTH-LOGIN-003')}
                  </a>
                </span>
              )}
              <React.Fragment>
                <div className="fw-bold my-2">OTP Code</div>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                  containerStyle={{ justifyContent: 'space-between' }}
                  inputStyle={{
                    flex: 1,
                    maxWidth: '5.5rem',
                    height: '5.5rem',
                    marginRight: 3
                  }}
                />
              </React.Fragment>
              <div className="d-flex justify-content-between align-items-center mb-5 my-5">
                <Button
                  onClick={() => {
                    navigate('/login/verify');
                  }}
                  variant="outline-danger"
                  className="text-uppercase fw-bold">
                  {t('AUTH-LOGIN-001')}
                </Button>
                <Button
                  variant="danger"
                  onClick={() => dispatch(fetchVerifyOtp(otp))}
                  className="text-uppercase banner-button fw-bold ">
                  {isLoading && type !== FETCH_SEND_OTP.REQUEST && (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;
                    </>
                  )}
                  {t('AUTH-LOGIN-012')}
                </Button>
              </div>
            </div>
          </div>
        </React.Fragment>
      </Col>
    </div>
  );
};

export default LoginVerify;
