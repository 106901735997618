import { ACTIVATION } from '../../types';

const { FETCH_ACTIVATION, FETCH_RESEND } = ACTIVATION;

const initialState = {
  activation: {
    data: null,
    isLoading: false,
    error: null
  },
  resend: {
    data: null,
    isLoading: false,
    error: null
  }
};

export const activationReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_RESEND.REQUEST:
      return {
        ...state,
        resend: {
          ...initialState.resend,
          isLoading: true
        }
      };
    case FETCH_RESEND.SUCCESS:
      return {
        ...state,
        resend: {
          ...initialState.resend,
          data: payload
        }
      };
    case FETCH_RESEND.FAILED:
      return {
        ...state,
        resend: {
          ...initialState.resend,
          error: payload
        }
      };
    case FETCH_ACTIVATION.REQUEST:
      return {
        ...state,
        activation: {
          ...initialState.activation,
          isLoading: true
        }
      };
    case FETCH_ACTIVATION.SUCCESS:
      return {
        ...state,
        activation: {
          ...initialState.activation,
          data: payload
        }
      };
    case FETCH_ACTIVATION.FAILED:
      return {
        ...state,
        activation: {
          ...initialState.activation,
          error: payload
        }
      };

    default:
      return state;
  }
};
