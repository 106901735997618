import api from '../../../apis';
import { errorParser } from '../../../utils';

import { ACTIVATION } from '../../types';

const { FETCH_ACTIVATION, FETCH_RESEND } = ACTIVATION;

export const fetchRequest = (type) => ({
  type
});

export const fetchSuccess = (type, payload) => ({
  type,
  payload
});

export const fetchFail = (type, error) => ({
  type,
  payload: error
});

export const fetchDataResend =
  ({ email }) =>
  async (dispatch) => {
    try {
      dispatch(fetchRequest(FETCH_RESEND.REQUEST));
      const { data } = await api.auth.resendActivation({ email });
      dispatch(fetchSuccess(FETCH_RESEND.SUCCESS, data));
    } catch (error) {
      const message = errorParser(error);
      dispatch(fetchFail(FETCH_RESEND.FAILED, message));
    }
  };

export const fetchDataActivation =
  ({ token, email }) =>
  async (dispatch) => {
    try {
      dispatch(fetchRequest(FETCH_ACTIVATION.REQUEST));
      const { data } = await api.auth.activation({ token, email });
      dispatch(fetchSuccess(FETCH_ACTIVATION.SUCCESS, data));
    } catch (error) {
      const message = errorParser(error);
      dispatch(fetchFail(FETCH_ACTIVATION.FAILED, message));
    }
  };
