import { useTranslation } from 'react-i18next';
import {
  uploadLegalDoc1,
  uploadLegalDoc2,
  uploadLegalDoc3,
  uploadLegalDoc4,
  uploadLegalDoc5,
  uploadLegalDoc6,
  uploadLegalDoc7
} from '../../assets';
import TimelineWithimage from '../TimelineWithImage';

const UploadLegalDoc = () => {
  const { t } = useTranslation();
  const data = [
    {
      image: uploadLegalDoc1,
      title: t('LEGAL-001'),
      description: t('LEGAL-008')
    },
    {
      image: uploadLegalDoc2,
      title: t('LEGAL-002'),
      description: t('LEGAL-009')
    },
    {
      image: uploadLegalDoc3,
      title: t('LEGAL-003'),
      description: t('LEGAL-010')
    },
    {
      image: uploadLegalDoc4,
      title: t('LEGAL-004'),
      description: t('LEGAL-011')
    },
    {
      image: uploadLegalDoc5,
      title: t('LEGAL-005'),
      description: t('LEGAL-012')
    },
    {
      image: uploadLegalDoc6,
      title: t('LEGAL-006'),
      description: t('LEGAL-013')
    },
    {
      image: uploadLegalDoc7,
      title: t('LEGAL-007'),
      description: t('LEGAL-014')
    }
  ];
  return <TimelineWithimage data={data} xxl={8} xl={8} lg={8} md={11} sm={11} xs={12} />;
};

export default UploadLegalDoc;
