import { useTranslation } from 'react-i18next';
import {
  partnershipStep1,
  partnershipStep2,
  partnershipStep3,
  partnershipStep4,
  partnershipStep5
} from '../../assets';
import TimelineWithimage from '../TimelineWithImage';
import './index.css';

const PartnershipSteps = () => {
  const { t } = useTranslation();
  const data = [
    {
      image: partnershipStep1,
      title: t('GUIDE-001'),
      description: t('GUIDE-002')
    },
    {
      image: partnershipStep2,
      title: t('GUIDE-003'),
      description: t('GUIDE-004')
    },
    {
      image: partnershipStep3,
      title: t('GUIDE-005'),
      description: t('GUIDE-006')
    },
    {
      image: partnershipStep4,
      title: t('GUIDE-007'),
      description: t('GUIDE-008')
    },
    {
      image: partnershipStep5,
      title: t('GUIDE-009'),
      description: t('GUIDE-010')
    }
  ];
  return <TimelineWithimage data={data} />;
};

export default PartnershipSteps;
