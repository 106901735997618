import React, { useCallback, useEffect, useState } from 'react';
import { loginBackground } from '../../../../assets';
import { Col, Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaTelegram } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { auth as authAction } from '../../../../stores/actions';
import Swal from 'sweetalert2';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { fetchResetType, fetchSendOtpData, logout } from '../../../../stores/actions/auth';
import { useNavigate } from 'react-router-dom';
import { FETCH_SEND_OTP } from '../../../../stores/types/auth';
import { shorthand } from '../../../../utils';
import { Timer } from '../../../../components';
import apis from '../../../../apis';

const LoginOtp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authReducer);
  const { isLoading, error, auth, type } = authReducer;
  const [timer, setTimer] = useState(0);
  const [showTimer, setShowTimer] = useState(false);

  const [otpType, setOtpType] = useState('email');

  const onError = (textError) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: textError
    }).then(() => {
      dispatch(authAction.fetchLoginFail(null));
      dispatch(fetchResetType());
      if (textError === 'Your Session Has Expired. Please re-login!') {
        redirectBackwards();
      }
    });
  };

  useEffect(() => {
    const params = {
      userId: auth?.user?._id
    };
    apis.auth.checkOtp(params).then(({ data }) => {
      if (data.data?.canResendIn) {
        const number = data.data?.canResendIn.match(/\d+/);
        if (parseInt(number[0], 10) !== 0) setShowTimer(true);
        setTimer(parseInt(number[0], 10));
      }
    });
  }, []);

  // login after otp
  useEffect(() => {
    document.body.style = '';
    if (error && type === FETCH_SEND_OTP.FAILED) {
      onError(error);
    }
  }, [error, type]);

  useEffect(() => {
    if (!auth) redirectBackwards();
  }, [auth]);

  useEffect(() => {
    if (type === FETCH_SEND_OTP.SUCCESS) {
      dispatch(fetchResetType());
      navigate('otp');
    }
  }, [type]);

  const redirectBackwards = () => {
    if (location.pathname.includes('cms/login')) return navigate('/cms/login');
    navigate('/login');
  };

  const RenderTimer = useCallback(
    () => (
      <>
        {showTimer && (
          <span className="mb-2">
            {t('AUTH-LOGIN-011')}{' '}
            <Timer withText={null} initialTime={timer} onTimerEnd={() => setShowTimer(false)} />
          </span>
        )}
      </>
    ),
    [timer, showTimer]
  );

  return (
    <div className="d-flex flex-row row-container-form">
      <Col xxl="6" xl="6" lg="6" className="container-image-bg-login">
        <div
          className="image-cover-fluid"
          style={{ '--img': `url(${loginBackground})`, height: '91.5vh' }}>
          <div
            className="image-overlay"
            style={{ height: '91.5vh', backgroundColor: 'transparent' }}>
            <div className="image-caption">{t('AUTH-LOGIN-004')}</div>
            <div className="image-description mt-4">{t('AUTH-LOGIN-005')}</div>
          </div>
        </div>
      </Col>
      <Col
        className="p-5 col-container-form position-relative"
        xxl="6"
        xl="6"
        lg="6"
        md="12"
        sm="12"
        xs="12">
        <h1 className="mx-lg-5 px-xl-5 font-title-page-login">{t('AUTH-LOGIN-006')}</h1>
        <React.Fragment>
          <div className="d-grid mx-lg-5 px-xl-5 my-4">
            <div>{t('AUTH-LOGIN-007')}</div>
            <React.Fragment>
              <FormControl required className="my-3">
                <RenderTimer />
                <RadioGroup
                  value={otpType}
                  onChange={(e) => setOtpType(e.target.value)}
                  className="ms-xl-2"
                  defaultValue="email">
                  <FormControlLabel
                    className="radio-button-custom-container py-2"
                    value="email"
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: 'rgba(228, 35, 18, 1)'
                          }
                        }}
                      />
                    }
                    label={
                      <React.Fragment>
                        <div className="d-flex align-items-center justify-content-between">
                          <FiMail className="ms-2 me-3 p-2 custom-background-icon" size={40} />
                          <div>
                            <div>Email</div>
                            <div className="fw-bold">{shorthand.maskEmail(auth?.user?.email)}</div>
                          </div>
                        </div>
                      </React.Fragment>
                    }
                  />
                  {auth?.user?.telegramId && (
                    <FormControlLabel
                      className="radio-button-custom-container py-2 mb-3"
                      value="telegram"
                      control={
                        <Radio
                          sx={{
                            '&.Mui-checked': {
                              color: 'rgba(228, 35, 18, 1)'
                            }
                          }}
                        />
                      }
                      label={
                        <React.Fragment>
                          <div className="d-flex align-items-center justify-content-between">
                            <FaTelegram
                              className="ms-2 me-3 p-2 custom-background-icon"
                              size={40}
                            />
                            <div>
                              <div>Telegram</div>
                              <div className="fw-bold">
                                {shorthand.maskPhoneNumber(auth?.user?.telegramUsername)}
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      }
                    />
                  )}
                </RadioGroup>
              </FormControl>
              <div className="d-flex flex-lg-row justify-content-between gap-2 align-items-center mb-5 my-5">
                <Button
                  onClick={() => {
                    dispatch(logout());
                  }}
                  variant="outline-danger"
                  className="text-uppercase fw-bold">
                  {t('AUTH-LOGIN-001')}
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    dispatch(fetchSendOtpData(otpType));
                  }}
                  className="text-uppercase banner-button fw-bold">
                  {isLoading && (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;
                    </>
                  )}
                  {t('AUTH-LOGIN-002')}
                </Button>
              </div>
            </React.Fragment>
          </div>
        </React.Fragment>
      </Col>
    </div>
  );
};

export default LoginOtp;
