import React, { useEffect } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaChevronRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { product1 } from '../../../../assets';
import { GradientImage } from '../../../../components';
import { product as ProductActions } from '../../../../stores/actions';
import { shorthand } from '../../../../utils';
import './index.css';

const Services = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const productReducer = useSelector((state) => state.productReducer);
  const general = useSelector((state) => state.generalReducer);
  useEffect(() => {
    const params = {
      perpage: 0,
      'bool-status': true
    };
    dispatch(ProductActions.fetchDataProduct(params, true));
    return () => {
      dispatch(ProductActions.resetProduct());
    };
  }, []);
  const reviews =
    productReducer.data?.map((x) => ({
      _id: x._id,
      image: x.media[0].url,
      text: x.title[general.language?.code || 'en']
    })) || [];

  return (
    <div>
      <GradientImage image={product1} caption={t('ABOUT-028')} description={t('ABOUT-029')} />
      <Row className="m-5">
        {reviews.map((x) => (
          <Col key={x._id} xxl="4" xl="4" lg="4" md="6" sm="6" xs="12" className="my-5">
            <div className="mx-xl-5" data-aos="slide-up" data-aos-duration={shorthand.aosDuration}>
              <Image
                src={x.image}
                fluid
                rounded
                style={{
                  objectFit: 'cover',
                  borderRadius: 55,
                  width: '100%',
                  height: '30vh'
                }}
              />
              <h6 className="my-3">{x.text}</h6>
              <a href={x._id} style={{ textDecoration: 'none' }}>
                <div className="view-detail-button">
                  {t('ABOUT-026')} <FaChevronRight className="mx-3 button-arrow" />
                </div>
              </a>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};
export default Services;
