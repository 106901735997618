/* eslint-disable react/prop-types */
import { Col, Form, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import Switch from 'react-switch';
import { getStatus, itemTypes } from '../../utils';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { useRef, Fragment } from 'react';
import { dragIcon } from '../../../../../assets';
import moment from 'moment';

const DraggableItem = ({
  x,
  i,
  page,
  perpage,
  onAddDelete,
  itemDelete,
  onToggleDelete,
  onToggleStatus,
  moveCard,
  dropCard,
  customRender
}) => {
  const dropRef = useRef(null);
  const dragRef = useRef(null);
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    item: { id: x._id, order: x.order, index: i },
    type: itemTypes.CARD,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }));

  const [{ handlerId }, drop] = useDrop({
    accept: itemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: !!monitor.getHandlerId()
      };
    },
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = i;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop() {
      dropCard();
    }
  });
  const opacity = isDragging ? 0 : 1;
  drag(dragRef);
  preview(drop(dropRef));
  return (
    <Fragment key={x._id}>
      <tr key={x._id} ref={dropRef} draggable data-handler-id={handlerId} style={{ opacity }}>
        <td ref={dragRef} role={'button'} className="align-middle">
          <Image src={dragIcon} style={{ width: 20, marginBottom: 5 }} />
        </td>
        {customRender ? (
          customRender
        ) : (
          <>
            <td className="text-cente align-middle">
              <div className="d-flex">
                <Form.Check
                  role={'button'}
                  type="checkbox"
                  id={`${x._id}`}
                  label={page * perpage + i + 1}
                  onChange={() => onAddDelete(x)}
                  checked={itemDelete.some((data) => data === x)}
                />
              </div>
            </td>
            <td>
              <Link to={`${x._id}/edit`} className="link-news-text text-item-title">
                {x.title.en}
              </Link>
              <div className="news-list-category">{x.categories?.[0]?.title?.en}</div>
            </td>
            <td className="align-middle">
              <Col xxl="9" xl="9" lg="9" md="12" sm="12" xs="12">
                <Image fluid rounded src={x?.media[0]?.url} />
              </Col>
            </td>
            <td className="align-middle align-self-center justify-content-center">
              <div className="d-flex justify-content-between">
                <div>{x.author}</div>
              </div>
            </td>
            <td className="align-middle align-self-center justify-content-center text-center">
              <div className="d-flex gap-2">
                <Switch
                  onColor="#66DE98"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor="#FFD079"
                  onChange={() => {
                    onToggleStatus(x);
                  }}
                  checked={x?.status === true ? true : false}
                />
                <div className={`${getStatus(x.status)} d-inline-flex`}>
                  {x.status ? 'active' : 'inactive'}
                </div>
              </div>
            </td>
            <td className="gap-3 align-middle align-items-center justify-content-center text-center">
              {x.visit || 0}
            </td>
            <td className="gap-3 align-middle align-items-center justify-content-center text-center">
              {moment(x.published.at).format('DD-MM-YYYY') || 0}
            </td>
            <td className="gap-3 align-middle">
              <div className="gap-3 d-flex justify-content-center align-items-center">
                <Link
                  onClick={() => {
                    onToggleDelete(x);
                  }}
                  className="d-inline-flex">
                  <FiTrash color="red" size={20} />
                </Link>
                <Link to={`${x._id}/edit`} className="d-inline-flex">
                  <FiEdit color="black" size={20} />
                </Link>
              </div>
            </td>
          </>
        )}
      </tr>
    </Fragment>
  );
};

export default DraggableItem;
