import * as Icons from '../icons';
const CMS_READ_DASHBOARD = 'cms.read.dashboard';
const CMS_READ_NEWS = 'cms.read.news';
export default {
  main: [
    {
      label: 'Dashboard',
      icon: Icons.dashboard,
      permissions: CMS_READ_DASHBOARD,
      url: '/cms/home'
    },
    {
      label: 'Analytics',
      icon: Icons.analytics,
      permissions: CMS_READ_DASHBOARD,
      children: [
        {
          label: 'Analytics',
          icon: '',
          permissions: CMS_READ_DASHBOARD,
          url: '/cms/analytics'
        }
      ]
    },
    {
      label: 'Contacts',
      icon: Icons.contacts,
      permissions: CMS_READ_DASHBOARD,
      url: '/cms/contacts'
    },
    {
      label: 'Inbox',
      icon: Icons.inbox,
      permissions: CMS_READ_DASHBOARD,
      url: '/cms/inbox'
    },
    {
      label: 'Calendar',
      icon: Icons.calendar,
      permissions: CMS_READ_DASHBOARD,
      url: '/cms/calendar'
    },
    {
      label: 'Partners',
      icon: Icons.partners,
      permissions: 'cms.read.partner',
      url: '/cms/partners'
    }
  ],
  ['Site Data']: [
    {
      label: 'Site Pages',
      icon: Icons.sitePages,
      permissions: CMS_READ_DASHBOARD,
      url: '/cms/site-pages'
    },
    {
      label: 'News',
      icon: Icons.news,
      permissions: CMS_READ_NEWS,
      children: [
        {
          label: 'Categories of News and Information',
          icon: '',
          permissions: 'cms.read.news.categories',
          url: '/cms/news-categories'
        },
        {
          label: 'News and Information',
          icon: '',
          permissions: CMS_READ_NEWS,
          url: '/cms/news/landing-page',
          disabled: '/cms/news/'
        }
      ]
    },
    {
      label: 'Photos',
      icon: Icons.photos,
      permissions: 'cms.read.photos',
      url: '/cms/photos'
    },
    {
      label: 'Videos',
      icon: Icons.videos,
      permissions: 'cms.read.videos',
      url: '/cms/videos'
    },
    {
      label: 'Audio',
      icon: Icons.audio,
      permissions: 'cms.read.audio',
      children: [
        {
          label: 'Main Audio',
          icon: '',
          permissions: 'cms.read.audio',
          url: '/cms/audio'
        }
      ]
    },
    {
      label: 'Blog',
      icon: Icons.blog,
      permissions: 'cms.read.blog',
      children: [
        {
          label: 'Main Blog',
          icon: '',
          permissions: 'cms.read.blog',
          url: '/cms/blog'
        }
      ]
    },
    {
      label: 'Product',
      icon: Icons.products,
      permissions: 'cms.read.product',
      children: [
        {
          label: 'Categories of Product',
          icon: '',
          permissions: 'cms.read.product.categories',
          url: '/cms/product-categories'
        },
        {
          label: 'Main Product',
          icon: '',
          permissions: CMS_READ_NEWS,
          url: '/cms/product'
        }
      ]
    },
    {
      label: 'Ad. Banners',
      icon: Icons.adBanners,
      permissions: CMS_READ_DASHBOARD,
      url: '/cms/ad-banners'
    },
    {
      label: 'Terms & Conditions',
      icon: Icons.tnc,
      permissions: CMS_READ_DASHBOARD,
      url: '/cms/terms-and-conditions'
    },
    {
      label: 'Privacy Policy',
      icon: Icons.tnc,
      permissions: CMS_READ_DASHBOARD,
      url: '/cms/privacy-policy'
    }
  ],
  settings: [
    {
      label: 'Settings',
      icon: Icons.settings,
      permissions: 'cms.read.settings',
      children: [
        {
          label: 'Main Settings',
          icon: '',
          permissions: 'cms.read.settings',
          url: '/cms/settings'
        }
      ]
    },
    {
      label: 'Webmaster',
      icon: Icons.webmaster,
      permissions: 'cms.read.webmaster',
      children: [
        {
          label: 'Main Webmaster',
          icon: '',
          permissions: 'cms.read.webmaster',
          url: '/cms/webmaster'
        }
      ]
    },
    {
      label: 'Logout',
      icon: Icons.logout,
      function: (func) => func()
    }
  ]
};
