export const ckEditorJik = {
  toolbar: {
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'underline',
      'alignment',
      'link',
      'bulletedList',
      'numberedList',
      '|',
      'outdent',
      'indent',
      'fontSize',
      'fontFamily',
      'fontColor',
      'fontBackgroundColor',
      'undo',
      'redo',
      '|',
      'blockQuote',
      'insertTable'
    ],
    shouldNotGroupWhenFull: false
  },
  fontFamily: {
    options: [
      'default',
      'Ubuntu, Arial, sans-serif',
      'Ubuntu Mono, Courier New, Courier, monospace',
      'Open Sans'
    ]
  },
  language: 'en',
  image: {
    toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side', 'linkImage']
  },
  table: {
    contentToolbar: [
      'tableColumn',
      'tableRow',
      'mergeTableCells',
      'tableCellProperties',
      'tableProperties'
    ]
  },
  licenseKey: 'BzKQxEYnRr3E3uOao2tVDUJeAdMnLIo+8Oz5484rJlw5iHpOkkf//z+O',
  contentCss: 'index.css',
  font_names: 'Open Sans;Ubuntu, Arial, sans-serif;Ubuntu Mono, Courier New, Courier, monospace;'
};
