import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store, persistor } from './stores';
import { PersistGate } from 'redux-persist/integration/react';
import Navigation from './navigation';
import { Loader } from './components';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <DndProvider backend={HTML5Backend}>
          <Navigation />
        </DndProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
