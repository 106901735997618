import React, { useEffect } from 'react';
import { Card, Col, Image } from 'react-bootstrap';
import { FaChevronRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { about2, landing5 } from '../../../../assets';
import { GradientImage, Loader } from '../../../../components';
import { shorthand } from '../../../../utils';
import './index.css';
import { product as ProductActions } from '../../../../stores/actions';
import { useTranslation } from 'react-i18next';

const Main = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const productReducer = useSelector((state) => state.productReducer);
  const general = useSelector((state) => state.generalReducer);
  useEffect(() => {
    const params = {
      perpage: 0,
      'bool-status': true
    };
    dispatch(ProductActions.fetchDataProduct(params, true));
    return () => {
      dispatch(ProductActions.resetProduct());
    };
  }, []);
  const reviews =
    productReducer.data?.map((x) => ({
      _id: x._id,
      image: x.media[0].url,
      text: x.title[general.language?.code || 'en']
    })) || [];

  return (
    <div>
      <GradientImage image={landing5} caption={t('ABOUT-018')} description={t('ABOUT-019')} />
      <div>
        <div className="row about-apps-content-margin" style={{ '--bs-gutter-x': 0 }}>
          <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <div
              className="d-flex my-xl-5 py-4 justify-content-center h-100 mx-5 embed-responsive embed-responsive-16by9"
              data-aos-duration={shorthand.aosDuration}>
              <iframe
                height="100%"
                width="100%"
                className="embed-responsive-item"
                src={'https://www.youtube.com/embed/aSMMLTVM3PU'}
                allowFullScreen
                title="Embedded youtube"
                style={{
                  borderRadius: 12
                }}
              />
            </div>
          </Col>
          <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <div
              className="d-flex my-xl-5 ml-xl-3 flex-column justify-content-center h-100"
              data-aos="slide-up"
              data-aos-duration={shorthand.aosDuration}>
              <h1 className="mb-3 px-5">Telkom Partner Network</h1>
              <div className="mb-3 px-5 text-justify">
                {t('ABOUT-020')}
                <span className="fw-bold"> Digital Business & Technology Division.</span>
              </div>
              <div className="mb-3 px-5 text-justify">{t('ABOUT-021')}</div>
            </div>
          </Col>
        </div>
        <div className="d-lg-flex d-md-none d-sm-none d-none w-100">
          <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <div className="p-5 bg-light h-100" style={{ backgroundColor: '#F1F7FF' }}>
              <div className="px-5" data-aos="slide-up" data-aos-duration={shorthand.aosDuration}>
                <h1>{t('ABOUT-022')}</h1>
                <p>{t('ABOUT-023')}</p>
              </div>
            </div>
          </Col>
          <Col
            xxl="6"
            xl="6"
            lg="6"
            md="12"
            sm="12"
            xs="12"
            data-aos="fade-in"
            data-aos-duration={shorthand.aosDuration}>
            <Image src={about2} className="h-100" fluid style={{ backgroundSize: 'cover' }} />
          </Col>
        </div>
        <GradientImage
          className="d-lg-none d-md-relative d-sm-relative d-relative w-100"
          image={about2}>
          <div className="py-5 text-white">
            <div className="px-4">
              <h1>{t('ABOUT-022')}</h1>
              <p>{t('ABOUT-023')}</p>
            </div>
          </div>
        </GradientImage>
        <div className="d-flex flex-column m-xl-5 py-4 px-5 px-xl-0" data-aos="fade-right">
          <h1 className=""> {t('ABOUT-024')}</h1>
          <div className="">{t('ABOUT-025')}</div>
        </div>
        <div>
          <div
            className="row flex-xl-row flex-xl-nowrap flex-md-nowrap flex-sm-nowrap overflow-auto "
            style={{ marginLeft: '4rem', marginRight: '4rem' }}>
            {productReducer.isLoading && <Loader />}
            {productReducer.data && productReducer.data.length > 0 && (
              <>
                {[...reviews, { _id: 'see more', text: 'See More' }].map((x, i) => (
                  <React.Fragment key={i.toString()}>
                    {x._id !== 'see more' && (
                      <Card
                        key={i.toString()}
                        className="border-0 col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-4 mb-5 mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-0"
                        data-aos="fade-right"
                        data-aos-duration={shorthand.aosDuration}>
                        <Image
                          src={x.image}
                          fluid
                          rounded
                          style={{
                            objectFit: 'cover',
                            borderRadius: 55,
                            width: '25rem',
                            height: '30vh'
                          }}
                        />
                        <div className="mt-2 fs-6">{x.text}</div>
                        <div className="">
                          <a href={`about/${x._id}`} className="text-red fw-normal">
                            {t('ABOUT-026')}
                          </a>
                          <FaChevronRight className="align-self-center ms-2 text-red" />
                        </div>
                      </Card>
                    )}
                    {x._id == 'see more' && (
                      <Card className="border-0 col-xl-1 col-lg-2 col-md-2 col-sm-2 col-xs-4 py-auto bg-light justify-content-center">
                        <a href="about/services" style={{ textDecoration: 'none' }}>
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <FaChevronRight color="red" />
                            <div className="text-see-more"> {t('ABOUT-027')}</div>
                          </div>
                        </a>
                      </Card>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
            {productReducer.error && <span>{productReducer.error}</span>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Main;
