import { FETCH_PRIVACY_POLICY_DELETE } from '../../types/privacyPolicy';

const initialState = {
  data: null,
  error: null,
  meta: null,
  isLoading: false
};

export const privacyPolicyRemoveReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case FETCH_PRIVACY_POLICY_DELETE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case FETCH_PRIVACY_POLICY_DELETE.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case FETCH_PRIVACY_POLICY_DELETE.FAILED:
      return {
        ...initialState,
        type: type,
        error: payload
      };
    case FETCH_PRIVACY_POLICY_DELETE.RESET:
      return initialState;
    default:
      return state;
  }
};
