import React, { useEffect } from 'react';
import { ContentHeader } from '../../components';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { useDispatch, useSelector } from 'react-redux';
import { privacyPolicy } from '../../stores/actions';
import { FETCH_PRIVACY_POLICY_LIST } from '../../stores/types/privacyPolicy';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const general = useSelector((state) => state.generalReducer);
  const reducer = useSelector((state) => state.privacyPolicyListReducer);
  const { t } = useTranslation();

  useEffect(() => {
    const delay = setTimeout(() => {
      const params = {
        perpage: 0,
        'bool-status': true
      };
      dispatch(privacyPolicy.privacyPolicyList(params, true));
    }, 500);
    return () => {
      clearTimeout(delay);
      dispatch({ type: FETCH_PRIVACY_POLICY_LIST.RESET });
    };
  }, []);
  return (
    <>
      <ContentHeader caption={t('AUTH-LOGIN-017')} description="Telkom Partner Network" />
      <div className="p-5 mx-xl-5 mx-lg-4">
        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
          {reducer.data?.[0]?.description?.[general.language?.code || 'en']}
        </ReactMarkdown>
      </div>
    </>
  );
};

export default PrivacyPolicy;
