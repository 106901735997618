import { combineReducers } from 'redux';
import { generalReducer } from './general';
import { authReducer } from './auth';
import {
  newsReducer,
  newsDetailReducer,
  newsAddReducer,
  newsUpdateReducer,
  newsRemoveReducer,
  newsAddImageReducer,
  newsRemoveImageReducer,
  newsMostViewedReducer
} from './news';

import {
  productReducer,
  productDetailReducer,
  productAddReducer,
  productUpdateReducer,
  productRemoveReducer,
  productAddImageReducer,
  productRemoveImageReducer
} from './product';
import { registerReducer, registerInternalReducer } from './register';

import {
  productCategoryReducer,
  productCategoryDetailReducer,
  productCategoryAddReducer,
  productCategoryUpdateReducer,
  productCategoryRemoveReducer,
  productCategoryAddImageReducer,
  productCategoryRemoveImageReducer
} from './product/category';
import { forgotReducer } from './forgotPassword';
import { resetReducer } from './resetPassword';
import { activationReducer } from './activation';

import {
  newsCategoryReducer,
  newsCategoryDetailReducer,
  newsCategoryAddReducer,
  newsCategoryUpdateReducer,
  newsCategoryRemoveReducer,
  newsCategoryAddImageReducer,
  newsCategoryRemoveImageReducer
} from './news/category';

import {
  partnerReducer,
  partnerDetailReducer,
  partnerAddReducer,
  partnerUpdateReducer,
  partnerRemoveReducer,
  partnerAddImageReducer,
  partnerRemoveImageReducer
} from './partner';

import {
  informationReducer,
  informationDetailReducer,
  informationAddReducer,
  informationUpdateReducer,
  informationRemoveReducer,
  informationAddImageReducer,
  informationRemoveImageReducer
} from './information';

import { organizationReducer } from './organization';

import { tncListReducer } from './termsAndCondition/list';
import { tncUpdateReducer } from './termsAndCondition/update';
import { tncUpdateStatusReducer } from './termsAndCondition/updateStatus';
import { tncRemoveReducer } from './termsAndCondition/delete';
import { tncCreateReducer } from './termsAndCondition/create';
import { tncDetailReducer } from './termsAndCondition/detail';

import { privacyPolicyListReducer } from './privacyPolicy/list';
import { privacyPolicyCreateReducer } from './privacyPolicy/create';
import { privacyPolicyDetailReducer } from './privacyPolicy/detail';
import { privacyPolicyRemoveReducer } from './privacyPolicy/delete';
import { privacyPolicyUpdateReducer } from './privacyPolicy/update';
import { privacyPolicyUpdateStatusReducer } from './privacyPolicy/updateStatus';

const reducers = combineReducers({
  generalReducer,
  authReducer,
  newsReducer,
  newsDetailReducer,
  newsAddReducer,
  newsUpdateReducer,
  newsRemoveReducer,
  newsAddImageReducer,
  newsRemoveImageReducer,
  productReducer,
  productDetailReducer,
  productAddReducer,
  productUpdateReducer,
  productRemoveReducer,
  productAddImageReducer,
  productRemoveImageReducer,
  registerReducer,
  registerInternalReducer,
  productCategoryReducer,
  productCategoryDetailReducer,
  productCategoryAddReducer,
  productCategoryUpdateReducer,
  productCategoryRemoveReducer,
  productCategoryAddImageReducer,
  productCategoryRemoveImageReducer,
  forgotReducer,
  resetReducer,
  activationReducer,
  newsCategoryReducer,
  newsCategoryDetailReducer,
  newsCategoryAddReducer,
  newsCategoryUpdateReducer,
  newsCategoryRemoveReducer,
  newsCategoryAddImageReducer,
  newsCategoryRemoveImageReducer,
  newsMostViewedReducer,
  partnerReducer,
  partnerDetailReducer,
  partnerAddReducer,
  partnerUpdateReducer,
  partnerRemoveReducer,
  partnerAddImageReducer,
  partnerRemoveImageReducer,
  informationReducer,
  informationDetailReducer,
  informationAddReducer,
  informationUpdateReducer,
  informationRemoveReducer,
  informationAddImageReducer,
  informationRemoveImageReducer,
  organizationReducer,
  tncListReducer,
  tncUpdateReducer,
  tncUpdateStatusReducer,
  tncRemoveReducer,
  tncCreateReducer,
  tncDetailReducer,
  privacyPolicyListReducer,
  privacyPolicyCreateReducer,
  privacyPolicyDetailReducer,
  privacyPolicyRemoveReducer,
  privacyPolicyUpdateReducer,
  privacyPolicyUpdateStatusReducer
});

const appReducer = (state, action) => reducers(state, action);

export default appReducer;
