import { PARTNER } from '../../types';

const initialState = {
  data: null,
  error: null,
  meta: null,
  isLoading: false
};

export const partnerReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case PARTNER.FETCH_PARTNER.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case PARTNER.FETCH_PARTNER.SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        data: payload.data,
        meta: payload.meta
      };
    case PARTNER.FETCH_PARTNER.FAILED:
      return {
        ...initialState,
        isLoading: false,
        error: payload
      };
    case PARTNER.FETCH_PARTNER.RESET:
      return initialState;
    default:
      return state;
  }
};

export const partnerDetailReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case PARTNER.FETCH_PARTNER_DETAIL.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case PARTNER.FETCH_PARTNER_DETAIL.SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        data: payload
      };
    case PARTNER.FETCH_PARTNER_DETAIL.FAILED:
      return {
        ...initialState,
        error: payload
      };
    case PARTNER.FETCH_PARTNER_DETAIL.RESET:
      return initialState;
    default:
      return state;
  }
};

export const partnerAddReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case PARTNER.FETCH_PARTNER_ADD.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case PARTNER.FETCH_PARTNER_ADD.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case PARTNER.FETCH_PARTNER_ADD.FAILED:
      return {
        ...initialState,
        error: payload,
        type: type
      };
    case PARTNER.FETCH_PARTNER_ADD.RESET:
      return initialState;
    default:
      return state;
  }
};

export const partnerUpdateReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case PARTNER.FETCH_PARTNER_UPDATE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case PARTNER.FETCH_PARTNER_UPDATE.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case PARTNER.FETCH_PARTNER_UPDATE.FAILED:
      return {
        ...initialState,
        error: payload,
        type: type
      };
    case PARTNER.FETCH_PARTNER_UPDATE.RESET:
      return initialState;
    default:
      return state;
  }
};

export const partnerReorderReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case PARTNER.FETCH_PARTNER_REORDER.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case PARTNER.FETCH_PARTNER_REORDER.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case PARTNER.FETCH_PARTNER_REORDER.FAILED:
      return {
        ...initialState,
        error: payload,
        type: type
      };
    case PARTNER.FETCH_PARTNER_REORDER.RESET:
      return initialState;
    default:
      return state;
  }
};

export const partnerRemoveReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case PARTNER.FETCH_PARTNER_REMOVE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case PARTNER.FETCH_PARTNER_REMOVE.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case PARTNER.FETCH_PARTNER_REMOVE.FAILED:
      return {
        ...initialState,
        error: payload,
        type: type
      };
    case PARTNER.FETCH_PARTNER_REMOVE.RESET:
      return initialState;
    default:
      return state;
  }
};

export const partnerAddImageReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case PARTNER.FETCH_PARTNER_ADD_IMAGE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case PARTNER.FETCH_PARTNER_ADD_IMAGE.SUCCESS:
      return {
        ...initialState,
        data: payload
      };
    case PARTNER.FETCH_PARTNER_ADD_IMAGE.FAILED:
      return {
        ...initialState,
        error: payload
      };
    default:
      return state;
  }
};

export const partnerRemoveImageReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case PARTNER.FETCH_PARTNER_REMOVE_IMAGE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case PARTNER.FETCH_PARTNER_REMOVE_IMAGE.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case PARTNER.FETCH_PARTNER_REMOVE_IMAGE.FAILED:
      return {
        ...initialState,
        error: payload
      };
    default:
      return state;
  }
};
