import { FETCH_PRIVACY_POLICY_CREATE } from '../../types/privacyPolicy';

const initialState = {
  data: null,
  error: null,
  meta: null,
  isLoading: false
};

export const privacyPolicyCreateReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case FETCH_PRIVACY_POLICY_CREATE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case FETCH_PRIVACY_POLICY_CREATE.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case FETCH_PRIVACY_POLICY_CREATE.FAILED:
      return {
        ...initialState,
        type: type,
        error: payload
      };
    case FETCH_PRIVACY_POLICY_CREATE.RESET:
      return initialState;
    default:
      return state;
  }
};
