import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';
import { shorthand } from '../../utils';
import { ReactComponent as CustomHeaderImage } from '../../assets/custom-header-image.svg';
import Button from '@mui/material/Button';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigateWithFallback } from '../../hooks';
import { useTranslation } from 'react-i18next';

const ContentHeader = ({ caption, description }) => {
  const navigateBack = useNavigateWithFallback();
  const { t } = useTranslation();

  return (
    <div className="ps-4 pt-4 pt-md-0 ps-md-5 container-image d-flex flex-row justify-content-between flex-wrap">
      <div
        className="d-flex ps-sm-3 ms-sm-3 ps-lg-5 ms-lg-5 flex-column justify-content-start justify-content-md-center"
        data-aos="fade-right"
        data-aos-duration={shorthand.aosDuration}>
        <Button variant="text" className="align-self-start ps-0 mb-3" onClick={navigateBack}>
          <FaChevronLeft color="red" />{' '}
          <span className="text-danger fw-bold">{t('AUTH-LOGIN-001')}</span>
        </Button>
        <h1 color="black" className="d-none d-sm-block title-custom-header fw-bold">
          {caption}
        </h1>
        <h4 color="black" className="d-block d-sm-none title-custom-header fw-bold">
          {caption}
        </h4>
        <h5 className="d-none d-sm-block description-custom-header">{description}</h5>
        <h6 className="d-sm-none description-custom-header">{description}</h6>
      </div>
      <CustomHeaderImage className="ms-auto me-sm-1 ms-md-0 align-self-end justify-content-end" />
    </div>
  );
};

ContentHeader.propTypes = {
  caption: PropTypes.string,
  description: PropTypes.string
};

export default ContentHeader;
