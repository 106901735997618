import { fetchData as privacyPolicyList } from './list';
import { fetchData as privacyPolicyDelete } from './delete';
import { fetchData as privacyPolicyDetail } from './detail';
import { fetchData as privacyPolicyUpdate } from './update';
import { fetchData as privacyPolicyUpdateStatus } from './updateStatus';
import { fetchData as privacyPolicyCreate } from './create';

export default {
  privacyPolicyList,
  privacyPolicyDelete,
  privacyPolicyDetail,
  privacyPolicyUpdate,
  privacyPolicyUpdateStatus,
  privacyPolicyCreate
};
