import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RiMailLine, RiMapPin2Line, RiPhoneLine, RiTimeLine } from 'react-icons/ri';
import { shorthand } from '../../utils';
import './index.css';

const ContactInfo = () => {
  const { t } = useTranslation();
  const Description = [
    {
      icon: RiMapPin2Line,
      title: t('CONTACT-002'),
      description: `PT Telekomunikasi Indonesia (PERSERO), Tbk 
      (Telkom Indonesia)
      Digital Business & Technology Division
      Jl. Kebon Sirih no. 12 
      Central Jakarta, Indonesia, 10110`
    },
    {
      icon: RiMailLine,
      title: t('CONTACT-003'),
      description: `info.partner@telkom.co.id`
    },
    {
      icon: RiPhoneLine,
      title: t('CONTACT-004'),
      description: `+(62) 8211 4070 007
      ${t('CONTACT-006')}`
    },
    {
      icon: RiTimeLine,
      title: t('CONTACT-005'),
      description: `${t('CONTACT-007')}
      08:00 AM to 05:00 PM
      GMT+7`
    }
  ];

  return (
    <>
      {Description.map((x, i) => (
        <div
          key={i.toString()}
          className="align-items-center justify-content-center"
          data-aos="slide-up"
          data-aos-duration={shorthand.aosDuration}>
          <Row>
            <Col xxl="1" xl="1" lg="1" md="1" sm="1" xs="1">
              <div className="d-flex contact-icon-background justify-content-center me-sm-5 ">
                <x.icon size={18} style={{ fill: 'red' }} className="align-self-center" />
              </div>
            </Col>
            <Col xxl="10" xl="10" lg="10" md="10" sm="10" xs="11">
              <div className="ms-4">
                <h4>{x.title}</h4>
              </div>
              <div className="mx-4 my-3 text-description">{x.description}</div>
            </Col>
          </Row>
        </div>
      ))}
    </>
  );
};

export default ContactInfo;
