export const itemTypes = {
  CARD: 'card'
};

export const getStatus = (status = false) => {
  if (status) return 'activeNews';
  else return 'inactiveNews';
};

export const roles = [
  {
    id: 'companion',
    label: 'Companion'
  },
  {
    id: 'partnership-unit',
    label: 'Unit Functional Partnership'
  },
  {
    id: 'partnership-initiation',
    label: 'Partnership Initiation'
  },
  {
    id: 'partner-selector',
    label: 'Partner Selector'
  },
  {
    id: 'legal',
    label: 'Legal Compliance'
  },
  {
    id: 'approver',
    label: 'Approver'
  },
  {
    id: 'division-admin',
    label: 'Division Admin'
  },
  {
    id: 'default',
    label: 'Default User'
  },
  {
    id: 'partner',
    label: 'Partner'
  },
  {
    id: 'super-admin',
    label: 'Super Admin'
  }
];
