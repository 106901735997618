import { newsCategoriesExternalUrl, newsCategoriesUrl } from './constant';
import { httpHelper } from '../utils';

export const getAll = (params) => httpHelper.get(newsCategoriesUrl, { params });
export const getById = (id) => httpHelper.get(`${newsCategoriesUrl}/${id}`);
export const add = (data) => httpHelper.post(newsCategoriesUrl, data);
export const update = (id, data) => httpHelper.put(`${newsCategoriesUrl}/${id}`, data);
export const remove = (id) => httpHelper.remove(`${newsCategoriesUrl}/${id}`);
export const addImage = (id, data) =>
  httpHelper.post(`${newsCategoriesUrl}/${id}/attachment`, data);
export const removeImage = (id, attachmentId) =>
  httpHelper.remove(`${newsCategoriesUrl}/${id}/attachment/${attachmentId}`);

// External
export const getAllExternal = (params) =>
  httpHelper.get(newsCategoriesExternalUrl, { params, isExternalApi: true });
export const getByIdExternal = (id) =>
  httpHelper.get(`${newsCategoriesExternalUrl}/${id}`, { isExternalApi: true });
