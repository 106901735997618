import { useState } from 'react';
import { Button, Image } from 'react-bootstrap';

// eslint-disable-next-line react/prop-types
const ImagePreview = ({ index, src, onDeletePress, onPreviewPress }) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <div
      className="bg-image my-3"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{
        width: 250
      }}>
      <div className="position-relative">
        <div
          className="bg-white"
          style={{
            position: 'absolute',
            inset: 0,
            opacity: isHovering ? 0.8 : 0.1
          }}
        />
        {isHovering && (
          <span
            className="position-absolute d-flex gap-2 align-items-center justify-content-center"
            style={{ inset: 0 }}>
            {onPreviewPress && (
              <Button variant="danger" onClick={() => onPreviewPress(index)}>
                Preview
              </Button>
            )}
            {onDeletePress && (
              <Button variant="danger" onClick={() => onDeletePress(index)}>
                Delete
              </Button>
            )}
          </span>
        )}
        <Image rounded fluid style={{ height: 150, width: 250 }} src={src} />
      </div>
    </div>
  );
};

export default ImagePreview;
