import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Col,
  Container,
  Row,
  Image,
  Stack,
  Carousel,
  Toast,
  ToastContainer
} from 'react-bootstrap';
import { calendarIcon, facebookIcon, linkIcon, mailIcon, twitterIcon } from '../../../assets';
import './index.css';
import { useEffect, useState } from 'react';
import { news } from '../../../stores/actions';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { shorthand } from '../../../utils';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

const Detail = () => {
  const dispatch = useDispatch();
  const newsDetailReducer = useSelector((state) => state.newsDetailReducer);
  const newsReducer = useSelector((state) => state.newsReducer);
  const general = useSelector((state) => state.generalReducer);
  const [page] = useState(0);
  const [perpage] = useState(5);
  const [showToast, setShowToast] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    const delay = setTimeout(() => {
      dispatch(news.fetchDataDetailNews(id, true));
      dispatch(
        news.fetchDataNews(
          {
            perpage: 3,
            'bool-status': true
          },
          true
        )
      );
    }, 500);
    return () => {
      clearTimeout(delay);
      dispatch(news.resetNews());
    };
  }, [page, perpage]);

  return (
    <Row className="my-5">
      {newsDetailReducer.error && <span>{newsDetailReducer.error}</span>}
      <Col>
        {newsDetailReducer?.isLoading && <Loader />}
        {!newsDetailReducer?.isLoading && (
          <Col
            className="landing-apps-content-margin"
            xxl="13"
            xl="13"
            lg="13"
            md="10"
            sm="10"
            xs="10">
            <div className="mt-4">
              <Carousel className="ms-0">
                {newsDetailReducer.data?.media.map((x) => (
                  <Carousel.Item key={x._id}>
                    <Image
                      src={x.url}
                      fluid
                      rounded
                      className="w-100"
                      style={{
                        objectFit: 'cover',
                        borderRadius: 55,
                        // width: '100%',
                        height: '60vh'
                      }}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
              <h2 className="fw-bold mt-5">
                {newsDetailReducer.data?.title[general.language?.code || 'en']}
              </h2>
              <Row className="border-bottom pb-3 mt-3 mb-3">
                <Col>
                  <Stack direction="horizontal">
                    <div>
                      <Image src={calendarIcon} width={25} height={25} fluid />
                    </div>
                    <div className="text-muted ms-2 align-self-center">
                      {moment(newsDetailReducer.data?.published.at).utc().format('DD MMMM YYYY')}
                    </div>
                  </Stack>
                </Col>
                <Col className="text-end">by {newsDetailReducer.data?.author}</Col>
              </Row>
              <div className="d-flex flex-row justify-content-between">
                <div className="text-red fw-normal">
                  #{newsDetailReducer.data?.categories[0].title[general.language?.code || 'en']}
                </div>
                <div className="d-flex flex-row align-items-center">
                  <div className="ms-3 me-3">Share</div>
                  <Image className="ms-3 me-3" src={facebookIcon} width={18} height={18} fluid />
                  <Image className="ms-3 me-3" src={twitterIcon} width={18} height={18} fluid />
                  <Image className="ms-3 me-3" src={mailIcon} width={18} height={18} fluid />
                  <Image
                    className="ms-3 me-3"
                    src={linkIcon}
                    width={18}
                    height={18}
                    fluid
                    role="button"
                    onClick={() => {
                      setShowToast(true);
                      navigator.clipboard.writeText(window.location.href);
                    }}
                  />
                </div>
              </div>
              <div id="newsHtml" className="mt-5 pb-3">
                <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                  {newsDetailReducer.data?.description[general.language?.code || 'en']}
                </ReactMarkdown>
              </div>
              {/* <div
                id="newsHtml"
                className="text-body fs-6 mt-5 pb-3"
                dangerouslySetInnerHTML={{
                  __html: newsDetailReducer.data?.description[general.language?.code || 'en']
                }}
              /> */}
            </div>
          </Col>
        )}
      </Col>
      <Col xxl="4" xl="4" lg="4" md="10" sm="10" xs="10" className="mb-5">
        <Container>
          <h2 className="mb-4 fw-bold">Most Viewed</h2>
          {newsReducer?.isLoading &&
            [1, 2, 3].map((x) => (
              <Col key={x} xxl="12" xl="12" lg="12" md="10" sm="10" xs="10">
                <Skeleton className="my-3" height={200} />
                <Skeleton />
              </Col>
            ))}
          {newsReducer.data?.map((x, i) => (
            <a
              key={i.toString()}
              data-aos="slide-up"
              data-aos-duration={shorthand.aosDuration}
              className="text-decoration-none text-black"
              href={`/news/${x._id}`}>
              <Col className="mt-3 mb-5" xxl="10" xl="10" lg="10" md="12" sm="12" xs="12">
                <Image
                  src={x.media[0].url}
                  fluid
                  rounded
                  style={{
                    objectFit: 'cover',
                    borderRadius: 55,
                    width: '100%',
                    height: '25vh'
                  }}
                />

                <p className="mt-3 fw-bold">{x.title[general.language?.code || 'en']}</p>
              </Col>
            </a>
          ))}
          {newsReducer.error && <span>{newsReducer.error}</span>}
        </Container>
      </Col>
      <ToastContainer position="bottom-center">
        <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
          <Toast.Body>Link Copied</Toast.Body>
        </Toast>
      </ToastContainer>
    </Row>
  );
};

export default Detail;
