export const FETCH_RESEND = {
  REQUEST: 'FETCH_RESEND_REQUEST',
  SUCCESS: 'FETCH_RESEND_SUCCESS',
  FAILED: 'FETCH_RESEND_FAILED',
  RESET: 'FETCH_RESEND_RESET'
};

export const FETCH_ACTIVATION = {
  REQUEST: 'FETCH_ACTIVATION_REQUEST',
  SUCCESS: 'FETCH_ACTIVATION_SUCCESS',
  FAILED: 'FETCH_ACTIVATION_FAILED',
  RESET: 'FETCH_ACTIVATION_RESET'
};
