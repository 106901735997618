import { FORGOT } from '../../types';

const initialState = {
  data: null,
  isLoading: false,
  error: null
};

export const forgotReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FORGOT.FETCH_FORGOT_REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case FORGOT.FETCH_FORGOT_SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        data: payload
      };
    case FORGOT.FETCH_FORGOT_FAILED:
      return {
        ...initialState,
        isLoading: false,
        error: payload
      };

    default:
      return state;
  }
};
