import api from '../../../apis';
import { errorParser } from '../../../utils';

import { PRODUCT } from '../../types';

export const fetchRequest = (type) => ({
  type: PRODUCT[type].REQUEST
});

export const fetchSuccess = (type, data) => ({
  type: PRODUCT[type].SUCCESS,
  payload: data
});

export const fetchFail = (type, error) => ({
  type: PRODUCT[type].FAILED,
  payload: error
});

export const fetchDataProduct = (params, isExternal) => async (dispatch) => {
  const type = 'FETCH_PRODUCT';
  try {
    dispatch(fetchRequest(type));
    const { data } = await (!isExternal
      ? api.product.getAll(params)
      : api.product.getAllExternal(params));
    dispatch(fetchSuccess(type, data)); //
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataDetailProduct = (id, isExternal) => async (dispatch) => {
  const type = 'FETCH_PRODUCT_DETAIL';
  try {
    dispatch(fetchRequest(type));
    const { data } = await (!isExternal
      ? api.product.getById(id)
      : api.product.getByIdExternal(id));
    dispatch(fetchSuccess(type, data.data)); //
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataAddProduct = (payload) => async (dispatch) => {
  const type = 'FETCH_PRODUCT_ADD';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.product.add(payload);
    dispatch(fetchSuccess(type, data.data)); //
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataUpdateProduct = (payload) => async (dispatch) => {
  const type = 'FETCH_PRODUCT_UPDATE';
  try {
    dispatch(fetchRequest(type));
    await Promise.all(
      payload.map(async (x) => {
        await api.product.update(x.id, x.params);
      })
    );
    dispatch(fetchSuccess(type, { message: 'Successfully to update product' }));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataRemoveProduct = (arrayId) => async (dispatch) => {
  const type = 'FETCH_PRODUCT_REMOVE';
  try {
    dispatch(fetchRequest(type));
    await Promise.all(
      arrayId.map(async (x) => {
        await api.product.remove(x._id);
      })
    );
    dispatch(fetchSuccess(type, { message: 'Successfully to delete product' }));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataAddImageProduct = (id, payload) => async (dispatch) => {
  const type = 'FETCH_PRODUCT_ADD_IMAGE';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.product.addImage(id, payload);
    dispatch(fetchSuccess(type, data.data)); //
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataRemoveImageProduct = (id, attachmentId) => async (dispatch) => {
  const type = 'FETCH_PRODUCT_REMOVE_IMAGE';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.product.removeImage(id, attachmentId);
    dispatch(fetchSuccess(type, data.data)); //
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const resetAddProduct = () => ({
  type: PRODUCT.FETCH_PRODUCT_ADD.RESET
});
export const resetDeleteProduct = () => ({
  type: PRODUCT.FETCH_PRODUCT_REMOVE.RESET
});

export const resetUpdateProduct = () => ({
  type: PRODUCT.FETCH_PRODUCT_UPDATE.RESET
});

export const resetProduct = () => ({
  type: PRODUCT.FETCH_PRODUCT.RESET
});

export const resetDetailProduct = () => ({
  type: PRODUCT.FETCH_PRODUCT_DETAIL.RESET
});
