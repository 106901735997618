import api from '../../../apis';
import { errorParser } from '../../../utils';

import { NEWS } from '../../types';

export const fetchRequest = (type) => ({
  type: NEWS[type].REQUEST
});

export const fetchSuccess = (type, data) => ({
  type: NEWS[type].SUCCESS,
  payload: data
});

export const fetchFail = (type, error) => ({
  type: NEWS[type].FAILED,
  payload: error
});

export const fetchDataNews = (params, isExternal) => async (dispatch) => {
  const type = 'FETCH_NEWS';
  try {
    dispatch(fetchRequest(type));
    const { data } = await (!isExternal
      ? api.news.getAll(params)
      : api.news.getAllExternal(params));
    dispatch(fetchSuccess(type, data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataNewsMostViewed = (params, isExternal) => async (dispatch) => {
  const type = 'FETCH_NEWS_MOST_VIEWED';
  try {
    dispatch(fetchRequest(type));
    const { data } = await (!isExternal
      ? api.news.getAll(params)
      : api.news.getAllExternal(params));
    dispatch(fetchSuccess(type, data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataDetailNews = (id, isExternal) => async (dispatch) => {
  const type = 'FETCH_NEWS_DETAIL';
  try {
    dispatch(fetchRequest(type));
    const { data } = await (!isExternal ? api.news.getById(id) : api.news.getByIdExternal(id));
    dispatch(fetchSuccess(type, data.data)); //
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataAddNews = (payload) => async (dispatch) => {
  const type = 'FETCH_NEWS_ADD';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.news.add(payload);
    dispatch(fetchSuccess(type, data.data)); //
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataUpdateNews = (payload) => async (dispatch) => {
  const type = 'FETCH_NEWS_UPDATE';
  try {
    dispatch(fetchRequest(type));
    await Promise.all(
      payload.map(async (x) => {
        await api.news.update(x.id, x.params);
      })
    );
    dispatch(fetchSuccess(type, { message: 'Successfully to update news' }));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataReorderNews = (payload) => async (dispatch) => {
  const type = 'FETCH_NEWS_REORDER';
  try {
    dispatch(fetchRequest(type));
    await api.news.reorder(payload);
    dispatch(fetchSuccess(type, { message: 'Successfully to reorder news' }));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataRemoveNews = (payload) => async (dispatch) => {
  const type = 'FETCH_NEWS_REMOVE';
  try {
    dispatch(fetchRequest(type));
    await Promise.all(
      payload.map(async (x) => {
        await api.news.remove(x._id);
      })
    );
    dispatch(fetchSuccess(type, { message: 'Successfully to delete news' }));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataAddImageNews = (id, payload) => async (dispatch) => {
  const type = 'FETCH_NEWS_ADD_IMAGE';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.news.addImage(id, payload);
    dispatch(fetchSuccess(type, data.data)); //
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const fetchDataRemoveImageNews = (id, attachmentId) => async (dispatch) => {
  const type = 'FETCH_NEWS_REMOVE_IMAGE';
  try {
    dispatch(fetchRequest(type));
    const { data } = await api.news.removeImage(id, attachmentId);
    dispatch(fetchSuccess(type, data.data)); //
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchFail(type, message));
  }
};

export const resetAddNews = () => ({
  type: NEWS.FETCH_NEWS_ADD.RESET
});
export const resetDeleteNews = () => ({
  type: NEWS.FETCH_NEWS_REMOVE.RESET
});

export const resetUpdateNews = () => ({
  type: NEWS.FETCH_NEWS_UPDATE.RESET
});

export const resetNews = () => ({
  type: NEWS.FETCH_NEWS.RESET
});

export const resetDetailNews = () => ({
  type: NEWS.FETCH_NEWS_DETAIL.RESET
});
