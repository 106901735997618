import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { newsCategory } from '../../../../../stores/actions';
import { FETCH_NEWS_CATEGORIES_UPDATE } from '../../../../../stores/types/newsCategory';
import { Input, PopUpConfirmation, PopUpFailed, PopUpSuccess } from '../../../../../components';

const NewsCategoryEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const reducer = useSelector((state) => state.newsCategoryUpdateReducer);
  const detailReducer = useSelector((state) => state.newsCategoryDetailReducer);
  //   const categoryReducer = useSelector((state) => state.newsCategoryReducer);

  // state for modal
  const [modalShow, setModalShow] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFail, setIsFail] = useState(false);

  //validation
  const [canSubmit, setCanSubmit] = useState(false);

  // input
  const [titleEng, setTitleEng] = useState('');
  const [titleIna, setTitleIna] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {
    const params = {
      perpage: 100
    };
    dispatch(newsCategory.fetchDataNewsCategory(params));
    return () => {
      dispatch(newsCategory.resetNewsCategory());
    };
  }, []);

  useEffect(() => {
    const delay = setTimeout(() => {
      dispatch(newsCategory.fetchDataDetailNewsCategory(id));
    }, 500);
    return () => {
      clearTimeout(delay);
      dispatch(newsCategory.resetDetailNewsCategory());
    };
  }, []);

  useEffect(() => {
    if (detailReducer.data) {
      setTitleEng(detailReducer.data.title?.en);
      setTitleIna(detailReducer.data.title?.id);
      setType(detailReducer.data.type);
    }
  }, [detailReducer.data]);

  const submit = () => {
    const params = {
      title: {
        en: titleEng,
        id: titleIna
      }
    };
    dispatch(newsCategory.fetchDataUpdateNewsCategory([{ id: detailReducer?.data?._id, params }]));
  };

  useEffect(() => {
    if (reducer.type === FETCH_NEWS_CATEGORIES_UPDATE.SUCCESS) {
      setModalShow(false);
      setIsSuccess(true);
    }
    if (reducer.type === FETCH_NEWS_CATEGORIES_UPDATE.FAILED) {
      setModalShow(false);
      setIsFail(true);
    }
  }, [reducer?.type]);

  useEffect(() => {
    setCanSubmit(validate());
  }, [titleEng, titleIna]);

  const validate = () => {
    if (!titleEng) {
      return false;
    }
    if (!titleIna) {
      return false;
    }
    return true;
  };

  return (
    <Container fluid>
      <Form className="my-5">
        <Row className="my-4">
          <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <Form.Group>
              <Form.Label className="fw-bold" style={{ color: '#5C5E61' }}>
                Type<span className="text-danger"> *</span>
              </Form.Label>
              <Form.Select onChange={(x) => setType(x.target.value)} value={type}>
                <option value="" disabled hidden>
                  Choose between News or Information
                </option>
                <option value="news">News</option>
                <option value="information">Information</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <Form.Group>
              <Input
                label="Title (English)"
                text={titleEng}
                required={true}
                onChange={(e) => setTitleEng(e.target.value)}
                error="Please fill the news title in english"
                as="textarea"
              />
            </Form.Group>
          </Col>
          <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <Form.Group>
              <Input
                label="Title (Bahasa)"
                text={titleIna}
                required={true}
                onChange={(e) => setTitleIna(e.target.value)}
                error="Please fill the news title in bahasa"
                as="textarea"
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="d-inline-flex float-end py-5">
          <Button
            type="button"
            className="btn btn-danger py-2 px-3"
            disabled={!canSubmit}
            onClick={() => {
              setModalShow(true);
            }}>
            UPDATE
          </Button>
        </div>
      </Form>
      <PopUpConfirmation
        show={modalShow}
        text={`Are you sure to update ${detailReducer?.data?.title?.en} ?`}
        textsubmit="YES, UPDATE"
        onHide={() => setModalShow(false)}
        disabled={reducer.isLoading}
        onSubmit={submit}
      />
      <PopUpSuccess
        show={isSuccess}
        textsubmit="OKAY"
        onSubmit={() => {
          setIsSuccess(false);
          dispatch(newsCategory.resetUpdateNewsCategory());
          navigate(-1);
        }}
        text="You can change news status that you published by edit the news"
      />
      <PopUpFailed
        show={isFail}
        textsubmit="Back"
        text={reducer?.error}
        onSubmit={() => {
          setIsFail(false);
          dispatch(newsCategory.resetUpdateNewsCategory());
        }}
      />
    </Container>
  );
};

export default NewsCategoryEdit;
