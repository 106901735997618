import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import DATA from './DATA';
const Picker = ({ label = '', data = DATA, value, onChange, required = false }) => {
  return (
    <div>
      <label>
        {label}
        {required && <span className="input-text-color"> *</span>}
      </label>
      <div className="dropdown">
        <div className="form-select" data-bs-toggle="dropdown" id="dropdownMenuLink">
          {value?.title?.en || value?.title}
        </div>
        <ul className="dropdown-menu form-control" aria-labelledby="dropdownMenuLink">
          <>
            {data?.map((x, i) => (
              <li key={i.toString()}>
                <div
                  className="dropdown-item"
                  onClick={() => {
                    onChange(x);
                  }}>
                  {x?.title?.en || x?.title}
                </div>
              </li>
            ))}
          </>
        </ul>
      </div>
    </div>
  );
};

Picker.propTypes = {
  label: PropTypes.string,
  data: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func,
  required: PropTypes.bool
};

export default forwardRef(Picker);
