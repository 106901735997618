import React, { useEffect } from 'react';
import { Col, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../components';
import { product as ProductActions } from '../../../../stores/actions';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import './index.css';

const Detail = () => {
  const { aboutId } = useParams();
  const dispatch = useDispatch();
  const productReducer = useSelector((state) => state.productDetailReducer);
  const general = useSelector((state) => state.generalReducer);
  useEffect(() => {
    dispatch(ProductActions.fetchDataDetailProduct(aboutId, true));
    return () => {
      dispatch(ProductActions.resetDetailProduct());
    };
  }, []);

  return (
    <div>
      {productReducer.isLoading && <Loader />}
      {productReducer.data && (
        <div>
          <h4 className="d-flex mx-5 my-5 px-xl-5 title">
            {productReducer?.data?.categories
              ?.map((x) => x?.title[[general.language?.code || 'en']])
              .join(', ')}
          </h4>
          <h2 className="d-flex mx-5 px-xl-5">
            {productReducer?.data?.title[general.language?.code || 'en']}
          </h2>
          <div className="d-flex mx-5 px-xl-5 mt-5 mb-3">
            <Image
              src={productReducer?.data?.media[0]?.url}
              fluid
              rounded
              style={{
                objectFit: 'contain',
                borderRadius: 55,
                width: '55vw',
                maxHeight: '50vh'
              }}
            />
          </div>
          <Col xxl="8" xl="8" lg="8" md="12" sm="12" xs="12" className="pb-xl-5">
            <div id="productHtml" className="mx-5 mb-5 px-xl-5">
              <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                {productReducer.data?.description[general.language?.code || 'en']}
              </ReactMarkdown>
            </div>
            {/* <div
              id="productHtml"
              className="mx-5 mb-5 px-xl-5 text"
              dangerouslySetInnerHTML={{
                __html: productReducer?.data?.description[general.language?.code || 'en']
              }}
            /> */}
          </Col>
        </div>
      )}
      {productReducer.error && <span>{productReducer.error}</span>}
    </div>
  );
};
export default Detail;
