import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { ckEditorJik as config } from './helper';
import './index.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';

const RichEditor = ({ label, value, error, onChangeText, isValid, required }) => {
  const [showErr, setShowErr] = useState(false);

  return (
    <div>
      <Form.Label className="fw-bold" style={{ color: '#5C5E61' }}>
        {required && <span className="text-danger">*</span>}
        {label}
      </Form.Label>
      <div className={isValid || value !== '' || !value ? '' : 'ckeditor-error'}>
        <CKEditor
          editor={ClassicEditor}
          data={value}
          config={config}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            // console.log("Editor is ready to use!", editor);
            editor.editing.view.change((writer) => {
              writer.setStyle('min-height', '200px', editor.editing.view.document.getRoot());
            });
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            onChangeText(data);
          }}
          onFocus={() => {
            setShowErr(true);
          }}
        />
      </div>
      {(!isValid || value === '') && showErr && <div className="text-danger">{error}</div>}
    </div>
  );
};

RichEditor.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onChangeText: PropTypes.func,
  isValid: PropTypes.bool,
  required: PropTypes.bool
};

RichEditor.defaultProps = {
  label: '',
  value: '',
  error: '',
  onChangeText: () => {},
  isValid: true,
  required: false
};

export default RichEditor;
