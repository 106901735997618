import { calendarIcon, eyeIcon, newsImage } from '../../../assets';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Image, ListGroup, Pagination, Row, Stack } from 'react-bootstrap';
import { FaChevronRight, FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { news, newsCategory } from '../../../stores/actions';
import './index.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { shorthand } from '../../../utils';
import { GradientImage } from '../../../components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Main = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const newsReducer = useSelector((state) => state.newsReducer);
  const newsMostViewedReducer = useSelector((state) => state.newsMostViewedReducer);
  const newsCategoryReducer = useSelector((state) => state.newsCategoryReducer);
  const general = useSelector((state) => state.generalReducer);
  const [category, setCategory] = useState({ title: { en: 'All', id: 'Semua' } });
  const [page, setPage] = useState(0);
  const [perpage] = useState(6);

  useEffect(() => {
    const delay = setTimeout(() => {
      fetching();
    }, 500);
    return () => {
      clearTimeout(delay);
      dispatch(news.resetNews());
    };
  }, [page, perpage, category]);

  const fetching = () => {
    dispatch(
      news.fetchDataNews(
        {
          perpage,
          currpage: page,
          'sort-order': -1,
          'filterobjid-categories': category._id,
          'bool-status': true
        },
        true
      )
    );
    dispatch(
      news.fetchDataNewsMostViewed(
        {
          perpage: 4,
          'sort-visit': -1,
          'bool-status': true
        },
        true
      )
    );
    dispatch(newsCategory.fetchDataNewsCategory({ perpage: 0, 'bool-status': true }, true));
  };
  return (
    <Col>
      <GradientImage image={newsImage} caption={'News'} description={t('NEWS-001')} />
      <Container className="mt-5">
        <h3 data-aos="slide-up" data-aos-duration={shorthand.aosDuration} className="fw-bold">
          Category
        </h3>
        {newsReducer?.isLoading && (
          <Col xxl="4" xl="4" lg="4" md="10" sm="10" xs="10">
            <Skeleton count={1} height={30} borderRadius="1rem" />
          </Col>
        )}
        <ListGroup horizontal className="mt-3">
          {[{ title: { en: 'All', id: 'Semua' } }, ...newsCategoryReducer.data].map((x, i) => (
            <ListGroup.Item
              key={i.toString()}
              role="button"
              className={`border px-4 py-0 me-3 rounded-pill fw-normal ${
                category._id === x._id ? 'text-red border-danger' : 'text-black bg-white '
              } `}
              onClick={() => {
                setCategory(x);
              }}
              style={{ backgroundColor: '#FDF7F7', fontSize: 14 }}>
              {x.title[general.language?.code || 'en']}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Container>
      <Container>
        <Row className="flex-nowrap overflow-auto">
          {newsReducer?.isLoading &&
            [1, 2, 3, 4].map((x) => (
              <Col key={x} xxl="3" xl="3" lg="3" md="10" sm="10" xs="10">
                <Skeleton className="my-3" height={200} />
                <Skeleton count={2} />
              </Col>
            ))}
        </Row>
        {newsReducer?.data?.length === 0 && <div className="mt-5">No Data</div>}
        <Row className="overflow-auto mt-5">
          {newsReducer?.data?.map((x) => (
            <Col
              key={x._id}
              xxl="4"
              xl="4"
              lg="4"
              md="6"
              sm="6"
              xs="12"
              className="my-3"
              role="button"
              onClick={() => {
                navigate(`/news/${x._id}`);
              }}
              // data-aos="slide-up"
              // data-aos-duration={shorthand.aosDuration}
            >
              <Image
                src={x.media[0]?.url}
                fluid
                rounded
                style={{
                  objectFit: 'cover',
                  borderRadius: 55,
                  width: '100%',
                  height: '35vh'
                }}
              />
              <Row className="my-3">
                <Col>
                  <ListGroup horizontal className="align-content-center">
                    <ListGroup.Item className="border-0 p-0">
                      <Image src={calendarIcon} fluid width={20} />
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0 p-0 ms-1 text-secondary mt-1">
                      {moment(x.published.at).format('DD MMMM YYYY')}
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col>
                  <Stack direction="horizontal" className="justify-content-end">
                    <div className="border-0 p-0 ">
                      <Image src={eyeIcon} fluid width={20} />
                    </div>
                    <div className="border-0 p-0 ms-1 text-secondary mt-1">{x?.visit || 0}</div>
                  </Stack>
                </Col>
              </Row>
              <h5 className="fw-bold text-description-news">
                {x?.title[general.language?.code || 'en']}
              </h5>
              <div className="mt-3">
                <a href={`/news/${x._id}`} className="text-red fw-normal">
                  READ
                </a>
                <FaChevronRight className="align-self-center ms-2 text-red" />
              </div>
            </Col>
          ))}
        </Row>
        <Pagination className="mt-5 justify-content-center">
          <Pagination.Prev
            onClick={() => {
              if (newsReducer.meta.page + 1 <= 1) {
                return;
              }
              setPage((prev) => prev - 1);
              window.scrollTo(0, 0);
            }}
            className="me-1">
            <FaArrowLeft color={page + 1 <= 1 ? 'gray' : 'black'} />
          </Pagination.Prev>
          {[...Array(newsReducer.meta?.totalpage)].map((_, i) => (
            <Pagination.Item
              key={i}
              onClick={() => {
                setPage(i);
                window.scrollTo(0, 0);
              }}
              className="mx-1">
              <div className={`fw-bold ${page === i ? 'text-danger' : 'text-black'}`}>{i + 1}</div>
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => {
              if (page + 1 >= newsReducer.meta?.totalpage) {
                return;
              }
              setPage((prev) => prev + 1);
              window.scrollTo(0, 0);
            }}
            className="ms-1">
            <FaArrowRight color={page + 1 >= newsReducer.meta?.totalpage ? 'gray' : 'black'} />
          </Pagination.Next>
        </Pagination>
        <h5 className="mt-5 fw-bold">Most Viewed</h5>
        <Row className="overflow-auto mt-3">
          {newsMostViewedReducer?.data?.map((x) => (
            <>
              {x.status && (
                <Col
                  key={x._id}
                  xxl="3"
                  xl="3"
                  lg="3"
                  md="6"
                  sm="6"
                  xs="12"
                  className="my-3"
                  // data-aos="slide-up"
                  // data-aos-duration={shorthand.aosDuration}
                  onClick={() => navigate(`/news/${x._id}`)}
                  role="button">
                  <Image
                    src={x.media[0]?.url}
                    fluid
                    rounded
                    style={{
                      objectFit: 'cover',
                      borderRadius: 55,
                      width: '100%',
                      height: '25vh'
                    }}
                  />
                  <h6 className="fw-bold text-description-news mt-3">
                    {x?.title[general.language?.code || 'en']}
                  </h6>
                  <div className="mt-3">
                    <a href={`/news/${x._id}`} className="text-red fw-normal">
                      READ
                    </a>
                    <FaChevronRight className="align-self-center ms-2 text-red" />
                  </div>
                </Col>
              )}
            </>
          ))}
        </Row>
      </Container>
    </Col>
  );
};

export default Main;
