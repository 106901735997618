import { productCategoriesExternalUrl, productCategoriesUrl } from './constant';
import { httpHelper } from '../utils';

export const getAll = (params) => httpHelper.get(productCategoriesUrl, { params });
export const getById = (id) => httpHelper.get(`${productCategoriesUrl}/${id}`);
export const add = (data) => httpHelper.post(productCategoriesUrl, data);
export const update = (id, data) => httpHelper.put(`${productCategoriesUrl}/${id}`, data);
export const remove = (id) => httpHelper.remove(`${productCategoriesUrl}/${id}`);
export const addImage = (id, data) =>
  httpHelper.post(`${productCategoriesUrl}/${id}/attachment`, data);
export const removeImage = (id, attachmentId) =>
  httpHelper.remove(`${productCategoriesUrl}/${id}/attachment/${attachmentId}`);

// External
export const getAllExternal = (params) =>
  httpHelper.get(productCategoriesExternalUrl, { params, isExternalApi: true });
export const getByIdExternal = (id) =>
  httpHelper.get(`${productCategoriesExternalUrl}/${id}`, { isExternalApi: true });
