import { useTranslation } from 'react-i18next';
import {
  registerStep1,
  registerStep2,
  registerStep3,
  registerStep4,
  registerStep5
} from '../../assets';
import TimelineWithimage from '../TimelineWithImage';

const RegisterSteps = () => {
  const { t } = useTranslation();
  const data = [
    {
      image: registerStep1,
      title: t('RGSTR-001'),
      description: t('RGSTR-002'),
      onPress: {
        title: t('RGSTR-003'),
        link: '/register'
      }
    },
    {
      image: registerStep2,
      title: t('RGSTR-004'),
      description: t('RGSTR-005')
    },
    {
      image: registerStep3,
      title: t('RGSTR-006'),
      description: t('RGSTR-007')
    },
    {
      image: registerStep4,
      title: t('RGSTR-008'),
      description: t('RGSTR-009')
    },
    {
      image: registerStep5,
      title: t('RGSTR-010'),
      description: t('RGSTR-011')
    }
  ];
  return <TimelineWithimage data={data} xxl={6} xl={6} lg={7} md={8} sm={10} xs={11} withShadow />;
};

export default RegisterSteps;
