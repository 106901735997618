import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Form, Table, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, PopUpConfirmation, PopUpFailed, PopUpSuccess } from '../../../../components';
import { news } from '../../../../stores/actions';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FiEye, FiEyeOff, FiTrash } from 'react-icons/fi';
import './index.css';
import update from 'immutability-helper';

import { FETCH_NEWS_REMOVE, FETCH_NEWS_UPDATE } from '../../../../stores/types/news';
import DraggableItem from '../Components/DraggableItem';
import { fetchDataReorderNews } from '../../../../stores/actions/news';
import { shorthand } from '../../../../utils';
import InformationList from '../Information/List';
import PropTypes from 'prop-types';

const NewsList = ({ tab }) => {
  const dispatch = useDispatch();
  const newsReducer = useSelector((state) => state.newsReducer);
  const updateReducer = useSelector((state) => state.newsUpdateReducer);
  const removeReducer = useSelector((state) => state.newsRemoveReducer);

  const [newsData, setNewsData] = useState(newsReducer.data ?? []);
  const [search] = useOutletContext();
  const [page, setPage] = useState(0);
  const [perpage, setPerpage] = useState(5);
  const [totalPage, setTotalPage] = useState(1);
  const [itemDelete, setItemDelete] = useState([]);
  const [counter, setCounter] = useState([]);

  const [modal, setModal] = useState({ visible: false });
  const [isSuccess, setIsSuccess] = useState({ visible: false });
  const [isFail, setIsFail] = useState(false);
  const [isDropped, setIsDropped] = useState(true);

  const [activeTab, setActiveTab] = useState(tab || 'landing-page');
  const navigate = useNavigate();

  const NEWS_DISABLE_BUTTON = 'news-list-disable-button ';
  const NEWS_ACTIVE_BUTTON = 'news-list-active-button ';

  useEffect(() => {
    if (!tab) {
      navigate('landing-page');
    }
  }, [tab]);

  useEffect(() => {
    if (newsReducer.data) {
      setNewsData(newsReducer.data);
    }
  }, [newsReducer.data]);

  useEffect(() => {
    const delay = setTimeout(() => {
      fetching();
    }, 500);
    return () => {
      clearTimeout(delay);
      dispatch(news.resetNews());
    };
  }, [search, page, perpage]);

  useEffect(() => {
    const delay = setTimeout(() => {
      if (newsData.length > 0 && !search && isDropped && !newsReducer.isLoading) {
        const newData = newsData.map((x, i) => ({
          ...x,
          order: newsReducer.meta?.total - (i + 1) - perpage * page
        }));
        dispatch(fetchDataReorderNews(newData));
        setIsDropped(false);
      }
    }, 750);
    return () => {
      clearTimeout(delay);
    };
  }, [newsData, search, isDropped, newsReducer.isLoading]);

  useEffect(() => {
    setItemDelete([]);
    if (newsReducer.meta) {
      setTotalPage(newsReducer.meta?.totalpage);
      setCounter(newsReducer?.meta?.currpage * perpage + newsReducer?.data?.length);
    }
  }, [newsReducer]);

  useEffect(() => {
    setItemDelete([]);
  }, [page, search]);

  useEffect(() => {
    if (removeReducer.type === FETCH_NEWS_REMOVE.SUCCESS) {
      setModal({ visible: +false });
      fetching();
      setIsSuccess({
        visible: +true,
        text: 'Selected news successfully deleted',
        onSubmit: () => {
          setIsSuccess({ visible: +false });
          dispatch(news.resetDeleteNews());
        }
      });
    }
    if (removeReducer.type === FETCH_NEWS_REMOVE.FAILED) {
      fetching();
      setModal({ visible: +false });
      setIsFail(true);
    }
    dispatch(news.resetDeleteNews());
  }, [removeReducer?.type]);

  useEffect(() => {
    if (updateReducer.type === FETCH_NEWS_UPDATE.SUCCESS) {
      setModal({ visible: +false });
      setIsSuccess({
        visible: +true,
        text: 'Selected news successfully updated',
        onSubmit: () => {
          setIsSuccess({ visible: +false });
          dispatch(news.resetUpdateNews());
        }
      });
      fetching();
    }
    if (updateReducer.type === FETCH_NEWS_UPDATE.FAILED) {
      setModal({ visible: +false });
      setIsFail(true);
      fetching();
    }
  }, [updateReducer?.type]);

  const fetching = () => {
    dispatch(
      news.fetchDataNews({
        'likeor-title.en': search,
        'likeor-title.id': search,
        'sort-order': -1,
        currpage: page,
        perpage: perpage
      })
    );
  };
  const onPressNext = () => {
    if (page + 1 >= totalPage) {
      return;
    }
    if (newsReducer.isLoading) {
      return;
    }
    setPage(page + 1);
    setIsDropped(true);
    setCounter(0);
  };

  const onPressPrev = () => {
    if (page + 1 <= 1) {
      return;
    }
    if (newsReducer.isLoading) {
      return;
    }
    setPage(page - 1);
    setIsDropped(true);
    setCounter(0);
  };

  const onAddDelete = (val) => {
    if (!itemDelete.some((data) => data._id === val._id)) {
      setItemDelete([...itemDelete, val]);
    } else {
      setItemDelete(itemDelete.filter((x) => x._id !== val._id));
    }
  };

  const onAddAllDelete = () => {
    if (newsData?.every((x) => itemDelete.includes(x))) {
      setItemDelete([]);
    } else {
      setItemDelete(
        itemDelete.concat(newsData?.filter((val) => !itemDelete.includes(val)).map((x) => x))
      );
    }
  };

  const onPressUpdate = (status = true) => {
    const payload = itemDelete
      .filter((x) => x.status === status)
      .map((value) => {
        const params = {
          title: {
            en: value.title.en,
            id: value.title.id
          },
          status: value.status === true ? false : true,
          description: {
            en: value.description.en,
            id: value.description.id
          },
          categories: [value.categories[0]._id],
          published: {
            at: value.published.at
          },
          author: value.author
        };
        return { id: value._id, params };
      });
    setModal({
      visible: +true,
      textSubmit: 'UPDATE',
      onSubmit: () => dispatch(news.fetchDataUpdateNews(payload)),
      text: `Are you sure to update the status of selected news?`
    });
  };

  const onPressModal = () => {
    let text = '';
    if (itemDelete.length < 1) {
      return;
    }
    if (newsReducer.data?.every((x) => itemDelete.includes(x._id))) {
      text = 'Are you sure to delete all news?';
    } else {
      text = `Are you sure to delete this ${newsReducer?.data
        ?.filter((x) => itemDelete.includes(x._id))
        .map((val) => val?.title?.en)
        .toLocaleString()} news?`;
    }
    setModal({
      visible: +true,
      textSubmit: 'Delete',
      onSubmit: () => dispatch(news.fetchDataRemoveNews(itemDelete)),
      text: `${text}`
    });
  };

  const onToggleStatus = (value) => {
    const params = {
      title: {
        en: value.title.en,
        id: value.title.id
      },
      status: value.status === true ? false : true,
      description: {
        en: value.description.en,
        id: value.description.id
      },
      categories: value.categories.map((x) => x._id),
      author: value.author,
      published: {
        at: value.published.at
      }
    };
    setModal({
      visible: +true,
      textSubmit: 'UPDATE',
      onSubmit: () => dispatch(news.fetchDataUpdateNews([{ id: value._id, params }])),
      text: `Are you sure to update the status ${value?.title?.en} of news?`
    });
  };

  const onToggleDelete = (value) => {
    setModal({
      visible: +true,
      textSubmit: 'Delete',
      onSubmit: () => dispatch(news.fetchDataRemoveNews([value])),
      text: `Are you sure to delete ${value?.title?.en} news?`
    });
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setNewsData((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      })
    );
  }, []);

  const renderRow = useCallback(
    (x, index) => {
      return (
        <DraggableItem
          i={index}
          x={x}
          page={page}
          perpage={perpage}
          itemDelete={itemDelete}
          onAddDelete={onAddDelete}
          onToggleDelete={onToggleDelete}
          onToggleStatus={onToggleStatus}
          key={x._id}
          moveCard={moveCard}
          dropCard={() => setIsDropped(true)}
        />
      );
    },
    [itemDelete]
  );

  const ViewList = () => (
    <Container fluid>
      <div className="d-flex justify-content-end">
        <a
          role={'button'}
          onClick={() => {
            if (itemDelete.length < 1) {
              return;
            }
            if (itemDelete.every((x) => x.status !== false)) {
              return;
            }
            onPressUpdate(false);
          }}
          className={`d-flex align-middle align-items-center my-3 ${
            itemDelete.length > 0 ? NEWS_ACTIVE_BUTTON : NEWS_DISABLE_BUTTON
          }text-decoration-none`}>
          <FiEye className="me-2 " size={20} />
          <div className="me-3">Active</div>
        </a>
        <a
          role={'button'}
          onClick={() => {
            if (itemDelete.length < 1) {
              return;
            }
            if (itemDelete.every((x) => x.status !== true)) {
              return;
            }
            onPressUpdate(true);
          }}
          className={`d-flex align-middle align-items-center my-3 ${
            itemDelete.length > 0 ? 'news-list-inactive-button ' : NEWS_DISABLE_BUTTON
          } text-decoration-none`}>
          <FiEyeOff className="me-2 " size={20} />
          <div className="me-3">Inactive</div>
        </a>
        <a
          role={'button'}
          className={`d-flex align-middle align-items-center ${
            itemDelete.length > 0 ? 'news-list-delete ' : NEWS_DISABLE_BUTTON
          } my-3 text-decoration-none`}
          onClick={() => {
            if (itemDelete.length < 1) {
              return;
            }
            onPressModal();
          }}>
          <FiTrash className="me-2" /> <div className="me-3">Delete</div>
        </a>
      </div>
      <Table striped>
        <thead className="table-light">
          <tr>
            <th></th>
            <th width={50} className="text-center text-uppercase">
              <div className="d-flex">
                <Form.Check
                  role={'button'}
                  type="checkbox"
                  id="check-all"
                  label={'No'}
                  onChange={onAddAllDelete}
                  checked={newsReducer?.data?.every((x) => itemDelete?.includes(x)) ? true : false}
                />
              </div>
            </th>
            <th width={'40%'} className="text-uppercase">
              Title
            </th>
            <th width={200} className="text-center text-uppercase">
              Photo
            </th>
            <th width={250} className="text-center text-uppercase">
              Author
            </th>
            <th width={100} className="text-center text-uppercase">
              Status
            </th>
            <th width={100} className="text-center text-uppercase">
              Visit
            </th>
            <th width={200} className="text-center text-uppercase">
              Published
            </th>
            <th width={300} className="text-center text-uppercase">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {newsReducer.isLoading && (
            <tr>
              <td colSpan={9}>
                <Loader />
              </td>
            </tr>
          )}
          {!newsReducer.isLoading &&
            newsReducer.data &&
            newsData.map((x, i) => (
              <React.Fragment key={i.toString()}>{renderRow(x, i)}</React.Fragment>
            ))}
          {!newsReducer.isLoading && newsReducer.data && newsReducer.data.length === 0 && (
            <tr>
              <td colSpan={9} align="center" valign="center">
                <p>No Data</p>
              </td>
            </tr>
          )}
          {!newsReducer.isLoading && newsReducer.error && (
            <tr>
              <td colSpan={9}>
                <>{newsReducer.error}</>
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="5">
              {counter || 0} of {newsReducer.meta?.filtered || 0}
            </td>
            <td colSpan="2">
              <div className="dropdown align-items-end">
                <div
                  role={'button'}
                  className="dropdown-toggle"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Rows per page : {perpage || 'All'}
                </div>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <>
                    {shorthand.getRowsPerPage(5, 9).map((x, i) => (
                      <li key={i.toString()}>
                        <a className="d-flex dropdown-item" onClick={() => setPerpage(x.value)}>
                          {x.label.toString()}
                        </a>
                      </li>
                    ))}
                  </>
                </ul>
              </div>
            </td>
            <td colSpan="2">
              <div className="float-end">
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={onPressPrev}
                  disabled={page + 1 <= 1}>
                  <FaChevronLeft size={12} />
                </Button>
                <div className="align-middle d-inline-block mx-2">
                  {page + 1} / {totalPage || 1}
                </div>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={onPressNext}
                  disabled={page + 1 >= totalPage}>
                  <FaChevronRight size={12} />
                </Button>
              </div>
            </td>
          </tr>
        </tfoot>
      </Table>
      <PopUpConfirmation
        show={modal?.visible}
        textsubmit={modal?.textSubmit}
        disabled={removeReducer.isLoading}
        onHide={() => setModal({ visible: +false })}
        {...modal}
      />
      <PopUpSuccess
        show={isSuccess.visible}
        textsubmit="OKAY"
        onSubmit={isSuccess?.onSubmit}
        text={isSuccess?.text}
      />
      <PopUpFailed
        show={isFail}
        textsubmit="Back"
        text={removeReducer?.error}
        onSubmit={() => {
          setIsFail(false);
          dispatch(news.resetDeleteNews());
        }}
      />
    </Container>
  );

  const onSelectTab = (tab) => {
    setActiveTab(tab);
    navigate(`/cms/news/${tab}`);
  };

  return (
    <Tabs id="custom-tabs" activeKey={activeTab} onSelect={onSelectTab} className="custom-tabs">
      <Tab eventKey="landing-page" title="Landing Page" tabAttrs={{ className: 'custom-tab left' }}>
        <ViewList />
      </Tab>
      <Tab eventKey="dashboard" title="Dashboard" tabAttrs={{ className: 'custom-tab right' }}>
        <InformationList />
      </Tab>
    </Tabs>
  );
};

NewsList.propTypes = {
  tab: PropTypes.string
};

export default NewsList;
