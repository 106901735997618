/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import './index.css';
import { func, array } from 'prop-types';
import {
  Menu,
  Item,
  useContextMenu
} from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { TbExternalLink } from 'react-icons/tb';

const MENU_ID = "menu-id";
function StepperTPN ({ steps = [], activeStep: initialStep, setParentActiveStep, currentStep }) {
  const { show } = useContextMenu({ id: MENU_ID })
  const [activeStep, setActiveStep] = useState(initialStep);

  useEffect(() => {
    setActiveStep(initialStep)
  }, [initialStep])
  const handleItemClick = ({ props }) => {
    const win = window.open(props, '_blank');
    win.focus();
  }
  const displayMenu = (e, title) => {
    show(e, {
      props: title
    });
  }

  return (
    <ul
      className="progressbar scrolling-wrapper-flexbox"
      style={{ justifyContent:'center'}}
    >
      {
        steps.map((item,idx) => {
          return (
            <li
              key={idx}
              className={classnames('item', {
                current: currentStep === idx ? true : false,
                active: activeStep === idx ? true : false,
                done: idx < activeStep
              })}
              onClick={ () => {
                if (setParentActiveStep)
                  return setParentActiveStep(idx)
                else
                  return false
              }}       
              data-number={idx+1}     
              onContextMenu={item?.link ? e => displayMenu(e, item.link) : null}
            >
              {item.title}
            </li>
          )
        })
      }
      <Menu id={MENU_ID}>
        <Item onClick={handleItemClick}>Open in New Tab &nbsp; <TbExternalLink size={18} /></Item>
      </Menu>
    </ul>
  )
}

StepperTPN.propTypes = {
  steps: array,
  style: func,
  setParentActiveStep: func,
}

export default StepperTPN;