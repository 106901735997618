export const FETCH_PRODUCT_CATEGORIES = {
  REQUEST: 'FETCH_PRODUCT_CATEGORIES_REQUEST',
  SUCCESS: 'FETCH_PRODUCT_CATEGORIES_SUCCESS',
  FAILED: 'FETCH_PRODUCT_CATEGORIES_FAILED',
  RESET: 'FETCH_PRODUCT_CATEGORIES_RESET'
};

export const FETCH_PRODUCT_CATEGORIES_DETAIL = {
  REQUEST: 'FETCH_PRODUCT_CATEGORIES_DETAIL_REQUEST',
  SUCCESS: 'FETCH_PRODUCT_CATEGORIES_DETAIL_SUCCESS',
  FAILED: 'FETCH_PRODUCT_CATEGORIES_DETAIL_FAILED',
  RESET: 'FETCH_PRODUCT_CATEGORIES_DETAIL_RESET'
};

export const FETCH_PRODUCT_CATEGORIES_ADD = {
  REQUEST: 'FETCH_PRODUCT_CATEGORIES_ADD_REQUEST',
  SUCCESS: 'FETCH_PRODUCT_CATEGORIES_ADD_SUCCESS',
  FAILED: 'FETCH_PRODUCT_CATEGORIES_ADD_FAILED',
  RESET: 'FETCH_PRODUCT_CATEGORIES_ADD_RESET'
};

export const FETCH_PRODUCT_CATEGORIES_UPDATE = {
  REQUEST: 'FETCH_PRODUCT_CATEGORIES_UPDATE_REQUEST',
  SUCCESS: 'FETCH_PRODUCT_CATEGORIES_UPDATE_SUCCESS',
  FAILED: 'FETCH_PRODUCT_CATEGORIES_UPDATE_FAILED',
  RESET: 'FETCH_PRODUCT_CATEGORIES_UPDATE_RESET'
};

export const FETCH_PRODUCT_CATEGORIES_REMOVE = {
  REQUEST: 'FETCH_PRODUCT_CATEGORIES_REMOVE_REQUEST',
  SUCCESS: 'FETCH_PRODUCT_CATEGORIES_REMOVE_SUCCESS',
  FAILED: 'FETCH_PRODUCT_CATEGORIES_REMOVE_FAILED',
  RESET: 'FETCH_PRODUCT_CATEGORIES_REMOVE_RESET'
};

export const FETCH_PRODUCT_CATEGORIES_ADD_IMAGE = {
  REQUEST: 'FETCH_PRODUCT_CATEGORIES_ADD_IMAGE_REQUEST',
  SUCCESS: 'FETCH_PRODUCT_CATEGORIES_ADD_IMAGE_SUCCESS',
  FAILED: 'FETCH_PRODUCT_CATEGORIES_ADD_IMAGE_FAILED',
  RESET: 'FETCH_PRODUCT_CATEGORIES_ADD_IMAGE_RESET'
};

export const FETCH_PRODUCT_CATEGORIES_REMOVE_IMAGE = {
  REQUEST: 'FETCH_PRODUCT_CATEGORIES_REMOVE_IMAGE_REQUEST',
  SUCCESS: 'FETCH_PRODUCT_CATEGORIES_REMOVE_IMAGE_SUCCESS',
  FAILED: 'FETCH_PRODUCT_CATEGORIES_REMOVE_IMAGE_FAILED',
  RESET: 'FETCH_PRODUCT_CATEGORIES_REMOVE_IMAGE_RESET'
};
