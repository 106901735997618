import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { contactUs } from '../../assets';
import { ContactInfo, GradientImage } from '../../components';
import { shorthand } from '../../utils';
import './index.css';

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <GradientImage
          image={contactUs}
          caption={t('CONTACT-008')}
          description={t('CONTACT-000')}
        />
        <div className="landing-apps-content-margin">
          <div
            className="m-5 m-md-n5"
            data-aos="slide-up"
            data-aos-duration={shorthand.aosDuration}>
            <Row>
              <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
                <h1 className="mb-5">{t('CONTACT-001')}</h1>
                <ContactInfo />
              </Col>
              <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
                <div className="mt-5">
                  <iframe
                    width="100%"
                    height="450rem"
                    id="gmap_canvas"
                    src={
                      'https://maps.google.com/maps?q=Telkom%20Multimedia%20Tower&t=&z=15&ie=UTF8&iwloc=&output=embed'
                    }
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    data-aos="slide-up"
                    data-aos-duration={shorthand.aosDuration}
                    className="mt-5 maps"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
