import api from '../../../apis';
import { errorParser } from '../../../utils';

import { REGISTER } from '../../types';

const {
  FETCH_REGISTER_FAILED,
  FETCH_REGISTER_REQUEST,
  FETCH_REGISTER_SUCCESS,
  FETCH_REGISTER_INTERNAL_FAILED,
  FETCH_REGISTER_INTERNAL_REQUEST,
  FETCH_REGISTER_INTERNAL_SUCCESS
} = REGISTER;

export const fetchRegisterRequest = () => ({
  type: FETCH_REGISTER_REQUEST
});

export const fetchRegisterSuccess = (auth) => ({
  type: FETCH_REGISTER_SUCCESS,
  payload: auth
});

export const fetchRegisterFail = (error) => ({
  type: FETCH_REGISTER_FAILED,
  payload: error
});

export const fetchRegisterInternalRequest = () => ({
  type: FETCH_REGISTER_INTERNAL_REQUEST
});

export const fetchRegisterInternalSuccess = (auth) => ({
  type: FETCH_REGISTER_INTERNAL_SUCCESS,
  payload: auth
});

export const fetchRegisterInternalFail = (error) => ({
  type: FETCH_REGISTER_INTERNAL_FAILED,
  payload: error
});

export const fetchDataRegister = (payload) => async (dispatch) => {
  try {
    dispatch(fetchRegisterRequest());
    const { data } = await api.auth.register(payload);
    dispatch(fetchRegisterSuccess(data.data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchRegisterFail(message));
  }
};

export const fetchDataRegisterInternal = (payload, token) => async (dispatch) => {
  try {
    dispatch(fetchRegisterRequest());
    const { data } = await api.auth.registerInternal(payload, token);
    dispatch(fetchRegisterSuccess(data.data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchRegisterFail(message));
  }
};

export const fetchDataRegisterInternalVerif = (token) => async (dispatch) => {
  try {
    dispatch(fetchRegisterInternalRequest());
    const { data } = await api.auth.verifyRegisterInternal(token);
    dispatch(fetchRegisterInternalSuccess(data.data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(fetchRegisterInternalFail(message));
  }
};
