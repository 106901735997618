import React from 'react';
import { Col, Figure } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { aboutPartnershipGuide } from '../../assets';
import { shorthand } from '../../utils';

const AboutPartnership = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div
        className="d-flex mx-5 px-xl-5 mb-3"
        data-aos="fade-right"
        data-aos-duration={shorthand.aosDuration}>
        <Figure>
          <Figure.Image width={730} height={412.71} src={aboutPartnershipGuide} />
        </Figure>
      </div>
      <Col xxl="8" xl="8" lg="8" md="12" sm="12" xs="12" className="pb-xl-5">
        <div
          className="mx-5 mb-5 px-xl-5 text"
          dangerouslySetInnerHTML={{ __html: t('ABOUT-001') }}
          data-aos="fade-right"
          data-aos-duration={shorthand.aosDuration}
        />
      </Col>
    </div>
  );
};
export default AboutPartnership;
