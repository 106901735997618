import React, { useEffect, useState } from 'react';
import { forgotBackground, recoveryEmail } from '../../assets';
import { Col, Form, Button, InputGroup, Spinner } from 'react-bootstrap';
import { BiUser } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword as forgotPasswordActions } from '../../stores/actions';
import Swal from 'sweetalert2';
import { useForm } from '../../hooks';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { InformationScreen } from '../../components';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const general = useSelector((state) => state.generalReducer);
  const forgotReducer = useSelector((state) => state.forgotReducer);
  const { isLoading, error, data } = forgotReducer;

  const [email, setEmail, emailValid, emailTouched, setEmailTouched, EmailError] = useForm(
    general.username || null,
    (x) => x
  );
  const [captcha, setCaptcha, captchaValid, captchaTouched, setCaptchaTouched, CaptchaError] =
    useForm(null, (x) => x);
  const [isValid, setIsValid] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setEmailTouched(true);
    setCaptchaTouched(true);
    if (!isValid) {
      return false;
    }
    dispatch(forgotPasswordActions.fetchDataForgot({ email, captcha: captcha || undefined }));
    return;
  };

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error
      }).then(() => {
        dispatch(forgotPasswordActions.fetchForgotFail(null));
      });
    }
    if (data) {
      console.log(data);
    }
  }, [error, data]);

  useEffect(() => {
    setIsValid(emailValid && (captchaValid || process.env.REACT_APP_RECAPTCHA_ENABLE === 'false'));
  }, [email, captcha]);

  const renderSuccess = () => (
    <InformationScreen
      title="Recovery Email Sent"
      image={recoveryEmail}
      caption={
        <>
          Your request to reset your password has been successful. We sent you an email{' '}
          <span className="fw-bold">({email})</span> with instruction on how to reset your password.
        </>
      }
      button={{
        text: 'GOT IT',
        action: () => navigate('/login')
      }}>
      <br />
      <Form.Label className="mt-5 justify-content-center text-secondary">
        Not receiving an email?&nbsp;
        <a
          className="text-decoration-none text-danger font-weight-bold"
          role="button"
          onClick={handleSubmit}>
          Resend
        </a>
      </Form.Label>
    </InformationScreen>
  );

  return (
    <>
      {data && renderSuccess()}
      {!data && (
        <div className="d-flex flex-row row-container-form">
          <Col xxl="6" xl="6" lg="6" className="container-image-bg-forgot">
            <div
              className="image-cover-fluid"
              style={{ '--img': `url(${forgotBackground})`, height: '91.5vh' }}>
              <div
                className="image-overlay"
                style={{ height: '91.5vh', backgroundColor: 'transparent' }}>
                <div className="image-caption">Forgot Password</div>
                <div className="image-description mt-4">
                  Please enter your registered email address and we will send you further
                  instruction on how to reset your password.
                </div>
              </div>
            </div>
          </Col>
          <Col
            className="d-flex flex-column justify-content-center px-5 col-container-form"
            xxl="6"
            xl="6"
            lg="6"
            md="12"
            sm="12"
            xs="12">
            <div className="mx-lg-5 px-xl-5 mb-4">
              <h1 className="fw-bold">{t('LANG-041')}</h1>
              <span className="text-secondary">
                {t('LANG-052')}{' '}
                <span
                  role="button"
                  onClick={() => navigate('/guides/reset-password')}
                  className="text-red fw-bold">
                  {t('LANG-004')}
                </span>
              </span>
            </div>

            <Form className="mx-lg-5 px-xl-5" onSubmit={handleSubmit}>
              <Form.Group className="mt-3 mb-4" controlId="formBasicEmail">
                <Form.Label className="fw-semibold">
                  {t('LANG-047')} <span className="text-danger">*</span>
                </Form.Label>
                <InputGroup
                  className={`${
                    !emailValid && emailTouched ? 'border border-danger rounded' : ''
                  }`}>
                  <InputGroup.Text className="bg-transparent border-end-0">
                    <BiUser />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder={t('LANG-075')}
                    className="border-start-0"
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={() => setEmailTouched(true)}
                    value={email}
                  />
                </InputGroup>
                <EmailError message={t('LANG-030')} />
              </Form.Group>
              <div className="d-flex flex-column justify-content-center align-items-center">
                {process.env.REACT_APP_RECAPTCHA_ENABLE === 'true' && (
                  <div
                    className={`mb-4 ${
                      !captchaValid && captchaTouched ? 'border border-danger' : ''
                    }`}>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      onChange={(value) => {
                        setCaptcha(value);
                        setCaptchaTouched(true);
                      }}
                      onExpired={() => setCaptcha(null)}
                      hl={general.language?.code || 'en'}
                    />
                    <CaptchaError message="Please verify that you are not a robot" />
                  </div>
                )}
              </div>
              <div className="d-flex flex-column text-center">
                <Button
                  variant="danger"
                  type="submit"
                  className="text-uppercase banner-button fw-bold">
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;Loading...
                    </>
                  ) : (
                    t('LANG-043')
                  )}
                </Button>
                <br />
                <Form.Label className="mt-5 justify-content-center text-secondary">
                  {t('LANG-042')}&nbsp;
                  <a href="/login" className="text-decoration-none text-danger fw-bold">
                    {t('LANG-006')}
                  </a>
                </Form.Label>
              </div>
            </Form>
          </Col>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
