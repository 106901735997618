/* eslint-disable react/prop-types */
import React from 'react';
import Select, { components } from 'react-select';
import './index.css';
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} onChange={() => null} />
        <label style={{ color: 'black' }}>&nbsp;{props.label}</label>
      </components.Option>
    </div>
  );
};

const OptionsSelect = (props) => {
  const { placeholder, isMulti, value, onChange, optionOutside, error = '' } = props;
  const handleRemoveValue = (e) => {
    if (!onChange) return;
    const { name: buttonName } = e.currentTarget;
    const removedValue = value.find((val) => val.value === buttonName);
    if (!removedValue) return;
    onChange(
      value.filter((val) => val.value !== buttonName),
      { action: 'remove-value', removedValue }
    );
  };

  return (
    <div>
      {optionOutside ? (
        <div className="values-container">
          {isMulti
            ? value.map((val) => (
                <div className="value" key={val.value}>
                  {val.label}
                  <button className="button-custom" name={val.value} onClick={handleRemoveValue}>
                    &nbsp;✕
                  </button>
                </div>
              ))
            : null}
        </div>
      ) : (
        ''
      )}
      <Select
        placeholder={placeholder}
        closeMenuOnSelect={false}
        {...props}
        controlShouldRenderValue={optionOutside ? !isMulti : isMulti}
        hideSelectedOptions={false}
        components={{ Option }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: undefined
          }
        })}
      />
      {error !== '' && <p style={{ color: '#FF5630' }}>{error}</p>}
    </div>
  );
};

export default OptionsSelect;
