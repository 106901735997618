import jwtDecode from 'jwt-decode';
import { store } from '../stores';

/**
 * Function to decode token and get item in token
 * @param {String} field value available for field is roles | permissions
 * @returns {Object} object in token
 */
const getItemInToken = (field) => {
  const state = store?.getState();
  const usedToken = state?.authReducer?.auth?.access_token;
  const result = jwtDecode(usedToken)?.[field];
  return result || jwtDecode(usedToken);
};

const aosDuration = 1000;

const EXCEL_FORMAT = 'application/vnd.ms-excel';
const EXCEL_POWERPOINT = 'application/vnd.ms-powerpoint';

const mimeType = {
  doc: 'application/msword',
  dot: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  dotx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  docm: 'application/vnd.ms-word.document.macroEnabled.12',
  dotm: 'application/vnd.ms-word.template.macroEnabled.12',
  xls: EXCEL_FORMAT,
  xlt: EXCEL_FORMAT,
  xla: EXCEL_FORMAT,
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xltx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  xltm: 'application/vnd.ms-excel.template.macroEnabled.12',
  xlam: 'application/vnd.ms-excel.addin.macroEnabled.12',
  xlsb: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  ppt: EXCEL_POWERPOINT,
  pot: EXCEL_POWERPOINT,
  pps: EXCEL_POWERPOINT,
  ppa: EXCEL_POWERPOINT,
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  potx: 'application/vnd.openxmlformats-officedocument.presentationml.template',
  ppsx: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  ppam: 'application/vnd.ms-powerpoint.addin.macroEnabled.12',
  pptm: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  potm: 'application/vnd.ms-powerpoint.template.macroEnabled.12',
  ppsm: 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
  mdb: 'application/vnd.ms-access',
  pdf: 'application/pdf',
  apk: 'application/vnd.android.package-archive'
};

const removeDuplicateArrayItems = (array) =>
  [...new Set(array.map(JSON.stringify))].map(JSON.parse);

const getRowsPerPage = (interval = 5, totalElement = 21) =>
  Array(totalElement)
    .fill()
    .map((x, i) => ({ label: i === 0 ? 'All' : i * interval, value: i * interval }));

const maskEmail = (email = '') => {
  return email.replace(/(..)(.{1,4})(?=.*@)/g, (_, a, b) => a + '*'.repeat(b.length));
};

const maskPhoneNumber = (phoneNumber = '') => {
  return phoneNumber.replace(
    /(.{3})(.*)(.{3})/g,
    (match, a, middle, b) => a + '*'.repeat(middle.length) + b
  );
};

const isMarkdown = (content) => {
  const markdownPatterns = [
    /^#{1,6} /, // Headers (#, ##, ###, etc.)
    /^\s*[-+*] /, // Unordered lists (-, +, *)
    /^\s*\d+\./, // Ordered lists (1., 2., 3., etc.)
    /!\[.*\]\(.*\)/, // Images (![alt text](url))
    /\[.*\]\(.*\)/, // Links ([text](url))
    /\*\*[^*]+\*\*/, // Bold (**bold**)
    /__[^_]+__/, // Bold (__bold__)
    /\*[^*]+\*/, // Italic (*italic*)
    /_[^_]+_/, // Italic (_italic_)
    /```[\s\S]*```/, // Code blocks (```code```)
    /`[^`]+`/, // Inline code (`code`)
    /^> / // Blockquotes (>)
  ];

  const htmlPattern = /<\/?[a-z][\s\S]*>/i; // Regular expression to match HTML tags

  // Check if any of the Markdown patterns match the content
  const isMd = markdownPatterns.some((pattern) => pattern.test(content));

  // Check if the content is HTML
  const isHtml = htmlPattern.test(content);

  // Return true only if it matches Markdown patterns and not HTML
  return isMd && !isHtml;
};

export default {
  getItemInToken,
  aosDuration,
  mimeType,
  removeDuplicateArrayItems,
  getRowsPerPage,
  maskEmail,
  maskPhoneNumber,
  isMarkdown
};
