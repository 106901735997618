import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Nav,
  Breadcrumb,
  Image,
  NavDropdown,
  Button,
  InputGroup,
  Form
} from 'react-bootstrap';
import * as FAIcon from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import api from '../../apis';
import { logoWhite } from '../../assets';
import { auth as authAction } from '../../stores/actions';
import menus from '../../navigation/menus';
import './index.css';

const CMSLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const auth = useSelector((state) => state.authReducer.auth);
  const breadcrumbData = location.pathname.split('/').filter((x) => x);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(authAction.logout());
  };

  useEffect(() => {
    if (location.pathname === '/cms') {
      navigate('/cms/home');
    }
  }, [location.pathname]);

  const getBreadcrumbText = (text) => {
    let title = '';
    switch (text) {
      case 'cms':
        return 'Home';
      case 'home':
        return 'Dashboard';
      case 'create':
        title = breadcrumbData[breadcrumbData.length - 2];
        if (title === 'landing-page') {
          {
            title = 'News';
          }
        } else if (title === 'dashboard') {
          title = 'Information';
        }
        return `Add ${title?.replace('-', ' ')}`;
      case 'edit':
        title = breadcrumbData[breadcrumbData.length - 3];
        if (title === 'landing-page') {
          title = 'News';
        } else if (title === 'dashboard') {
          title = 'Information';
        }
        return `Edit ${title?.replace('-', ' ')}`;
      default:
        return text?.replace('-', ' ');
    }
  };

  return (
    <Container fluid className="g-0">
      <Row className="min-vh-100 g-0">
        <Col className="col-auto bg-black">
          <Nav defaultActiveKey="/cms/home" className="flex-column">
            <div className="my-3 mx-3">
              <Image src={logoWhite} alt="logo" fluid />
            </div>
            <div
              style={{
                maxHeight: '90vh',
                overflow: 'scroll',
                paddingBottom: '20px',
                width: '250px'
              }}
              className="g-0">
              {Object.keys(menus).map((x) => (
                <React.Fragment key={x}>
                  <span
                    className="text-white text-capitalize mb-1 mt-3 ms-1"
                    style={{ fontSize: 12 }}>
                    {x}
                  </span>
                  {menus[x].map((y, j) => {
                    const { pathname } = window.location;

                    return (
                      <React.Fragment key={j.toString()}>
                        {!y.children?.length ? (
                          <Nav.Link
                            onClick={() => {
                              if (y.function) {
                                return y.function(handleLogout);
                              }
                              return navigate(y.url);
                            }}
                            role="button"
                            className={`d-flex gap-4 align-items-center px-3 ${
                              pathname.includes(y.url)
                                ? 'text-white fw-bold navbar-active'
                                : 'text-secondary fw-bold'
                            } px-1`}
                            disabled={pathname.includes(y.url)}>
                            <img src={y.icon} width={24} height={24} />
                            <span
                              style={{ fontSize: 18 }}
                              className={`${pathname.includes(y.url) ? 'text-white' : null}`}>
                              {y.label}
                            </span>
                          </Nav.Link>
                        ) : (
                          <>
                            <Nav.Link
                              className={`d-flex gap-4 align-items-centertext-white px-3 ${
                                y?.children?.some((x) => x?.url.includes(pathname))
                                  ? 'text-white fw-bold navbar-active'
                                  : 'text-secondary fw-bold'
                              }`}
                              role="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#content-${y.label}`}
                              aria-expanded="false"
                              aria-controls="collapse">
                              <img src={y.icon} width={24} height={24} />
                              <span
                                style={{ fontSize: 18 }}
                                className={`${
                                  y?.children?.some((x) => x?.url.includes(pathname))
                                    ? 'text-white fw-bold'
                                    : 'text-secondary'
                                }`}>
                                {y.label}
                              </span>
                              <span className="ms-auto">
                                <FAIcon.FaCaretDown />
                              </span>
                            </Nav.Link>
                            <div className="collapse" id={`content-${y.label}`}>
                              {y.children?.map((z, k) => (
                                <Nav.Link
                                  key={k.toString()}
                                  onClick={() => {
                                    if (z.function) {
                                      return z.function(handleLogout);
                                    }
                                    return navigate(z.url);
                                  }}
                                  role="button">
                                  <div
                                    className={`navbar-child d-flex gap-4 align-items-center ms-3 px-0`}>
                                    <span className="ms-2" />
                                    <span
                                      className={` ${
                                        pathname === z.url || pathname.includes(z.disabled)
                                          ? 'text-white fw-bold'
                                          : 'navbar-child-text'
                                      } `}>
                                      {z.label}
                                    </span>
                                  </div>
                                </Nav.Link>
                              ))}
                            </div>
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              ))}
            </div>
          </Nav>
        </Col>
        <Col className="bg-light px-0">
          <div className="bg-white d-flex align-items-center border px-2">
            <div>
              <Button
                as="a"
                size="sm"
                href="/"
                target="_blank"
                style={{ backgroundColor: '#E42312' }}
                className="fw-bold text-uppercase">
                VIEW SITE
              </Button>
            </div>
            <div className="ms-auto me-2">
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Type to search"
                  className="border-end-0"
                  required
                  onChange={(e) => setSearch(e.target.value)}
                />
                <InputGroup.Text className="bg-transparent border-start-0" role="button">
                  <FAIcon.FaSearch />
                </InputGroup.Text>
              </InputGroup>
            </div>
            <div className="border border-1 py-3"></div>
            <Nav>
              <NavDropdown
                id="account"
                align="end"
                title={
                  <span className="fw-bold text-muted">
                    <Image
                      src={`${api.constant.baseUrl}/users/${auth.user?._id}/avatar`}
                      alt="logo"
                      className="shadow-sm border-1 me-2"
                      roundedCircle
                      width={40}
                      height={40}
                    />
                    {auth.user?.fullname}
                  </span>
                }>
                <NavDropdown.Item
                  className="d-flex gap-2 align-items-center text-danger fw-bold"
                  onClick={handleLogout}>
                  {FAIcon.FaSignOutAlt()}
                  <span>Logout</span>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </div>
          <div className="px-2 pt-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <Breadcrumb>
                  {breadcrumbData.map((x, i) => (
                    <Breadcrumb.Item
                      key={i.toString()}
                      active={i === breadcrumbData.length - 1}
                      linkAs="a"
                      linkProps={{
                        className: `text-decoration-none text-dark`
                      }}
                      onClick={() =>
                        navigate(
                          `/cms/${
                            x === 'cms'
                              ? 'home'
                              : breadcrumbData.slice(1, breadcrumbData.indexOf(x) + 1).join('/')
                          }`
                        )
                      }
                      role="button"
                      className={`text-capitalize`}>
                      {getBreadcrumbText(x)}
                    </Breadcrumb.Item>
                  ))}
                </Breadcrumb>
                <span className="text-uppercase fw-bold h5 mb-1">
                  {breadcrumbData[breadcrumbData.length - 1]?.replace('-', ' ') === 'create' &&
                    getBreadcrumbText(breadcrumbData[breadcrumbData.length - 1])}
                </span>
              </div>
              {[
                'product',
                'product-categories',
                'landing-page',
                'dashboard',
                'news-categories',
                'partners',
                'terms-and-conditions',
                'privacy-policy'
              ].includes(breadcrumbData[breadcrumbData.length - 1]) && (
                <div className="me-3 align-items-center">
                  <button
                    type="button"
                    className="btn fw-bold"
                    onClick={() => navigate(`${location.pathname}/create`)}
                    style={{ backgroundColor: '#E42312', color: 'white' }}>
                    <FAIcon.FaPlus className="me-2" /> ADD ITEM
                  </button>
                </div>
              )}
            </div>

            <div
              className="bg-white rounded shadow-sm overflow-auto mt-2"
              style={{ height: '80vh' }}>
              <Outlet context={[search, setSearch]} />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CMSLayout;
