import { FETCH_PRIVACY_POLICY_LIST } from '../../types/privacyPolicy';

const initialState = {
  data: null,
  error: null,
  meta: null,
  isLoading: false
};

export const privacyPolicyListReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case FETCH_PRIVACY_POLICY_LIST.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case FETCH_PRIVACY_POLICY_LIST.SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        data: payload.data,
        meta: payload.meta
      };
    case FETCH_PRIVACY_POLICY_LIST.FAILED:
      return {
        ...initialState,
        isLoading: false,
        error: payload
      };
    case FETCH_PRIVACY_POLICY_LIST.RESET:
      return initialState;
    default:
      return state;
  }
};
