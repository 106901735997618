export const baseUrl = process.env.REACT_APP_GATEWAY_URL; // for login
export const baseUrlAPI = process.env.REACT_APP_BACKEND_URL; // for API
export const baseExternalAPI = process.env.REACT_APP_EXTERNAL_URL; // for External

// v3 API
export const loginUrl = `${baseUrl}/auth/login`;
export const refreshUrl = `${baseUrl}/auth/token`;
export const registerUrl = `${baseUrl}/register`;
export const registerInternalUrl = `${baseUrl}/invitations/register-internal`;
export const forgotPasswordUrl = `${baseUrl}/forgot-password`;
export const resetPasswordUrl = `${baseUrl}/reset-password`;
export const activationUrl = `${baseUrl}/activation`;
export const resendActivationUrl = `${baseUrl}/resend-activation`;
export const reqOtpUrl = `${baseUrl}/auth/request-otp`;
export const verifyOtpUrl = `${baseUrl}/auth/verify-otp`;
export const checkOtpUrl = `${baseUrl}/auth/check-expired-last-otp`;
export const verifyRegisterInternalUrl = `${baseUrl}/invitations/register-internal/check-invitation`;
export const templateRegisterDoc = `${baseUrl}/invitations/register-internal/download-template`;

// CMS API
export const newsUrl = `${baseUrlAPI}/cms/news`;
export const newsCategoriesUrl = `${baseUrlAPI}/cms/news-categories`;
export const productUrl = `${baseUrlAPI}/cms/product`;
export const productCategoriesUrl = `${baseUrlAPI}/cms/product-categories`;
export const partnerUrl = `${baseUrlAPI}/cms/partner`;
export const informationUrl = `${baseUrlAPI}/cms/information`;
export const organizationUrl = `${baseUrl}/organizations`;
export const termsAndConditionsUrl = `${baseUrlAPI}/cms/terms-and-conditions`;
export const privacyPolicyUrl = `${baseUrlAPI}/cms/privacy-policy`;

// External API
export const newsExternalUrl = `${baseExternalAPI}/cms/news`;
export const productExternalUrl = `${baseExternalAPI}/cms/product`;
export const newsCategoriesExternalUrl = `${baseExternalAPI}/cms/news-categories`;
export const productCategoriesExternalUrl = `${baseExternalAPI}/cms/product-categories`;
export const termsAndConditionsExternalUrl = `${baseExternalAPI}/cms/terms-and-conditions`;
export const privacyPolicyExternalUrl = `${baseExternalAPI}/cms/privacy-policy`;
