import { productUrl, productExternalUrl } from './constant';
import { httpHelper } from '../utils';

export const getAll = (params) => httpHelper.get(productUrl, { params });
export const getById = (id) => httpHelper.get(`${productUrl}/${id}`);
export const add = (data) => httpHelper.post(productUrl, data);
export const update = (id, data) => httpHelper.put(`${productUrl}/${id}`, data);
export const remove = (id) => httpHelper.remove(`${productUrl}/${id}`);
export const addImage = (id, data) => httpHelper.post(`${productUrl}/${id}/attachment`, data);
export const removeImage = (id, attachmentId) =>
  httpHelper.remove(`${productUrl}/${id}/attachment/${attachmentId}`);

// External
export const getAllExternal = (params) =>
  httpHelper.get(productExternalUrl, { params, isExternalApi: true });
export const getByIdExternal = (id) =>
  httpHelper.get(`${productExternalUrl}/${id}`, { isExternalApi: true });
