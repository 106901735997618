import { GENERAL } from '../../types';

const changeLanguage = (language) => ({
  type: GENERAL.CHANGE_LANGUAGE,
  payload: language
});

const rememberUsername = (username) => ({
  type: GENERAL.REMEMBER_USERNAME,
  payload: username
});

export default { changeLanguage, rememberUsername };
