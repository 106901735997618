import { ORGANIZATION } from '../../types';

const initialState = {
  data: null,
  error: null,
  meta: null,
  isLoading: false
};

export const organizationReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ORGANIZATION.FETCH_ORGANIZATION.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case ORGANIZATION.FETCH_ORGANIZATION.SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        data: payload.data,
        meta: payload.meta
      };
    case ORGANIZATION.FETCH_ORGANIZATION.FAILED:
      return {
        ...initialState,
        isLoading: false,
        error: payload
      };
    case ORGANIZATION.FETCH_ORGANIZATION.RESET:
      return initialState;
    default:
      return state;
  }
};
