import React, { useEffect, useState } from 'react';
import { Button, Container, Form, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, PopUpConfirmation, PopUpFailed, PopUpSuccess } from '../../../../../components';
import { newsCategory } from '../../../../../stores/actions';
import { Link, useOutletContext } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FiEdit, FiEye, FiEyeOff, FiTrash } from 'react-icons/fi';
import './index.css';
import Switch from 'react-switch';
import {
  FETCH_NEWS_CATEGORIES_REMOVE,
  FETCH_NEWS_CATEGORIES_UPDATE
} from '../../../../../stores/types/newsCategory';

const NewsCategoryList = () => {
  const dispatch = useDispatch();
  const newsCategoryReducer = useSelector((state) => state.newsCategoryReducer);
  const updateReducer = useSelector((state) => state.newsCategoryUpdateReducer);
  const removeReducer = useSelector((state) => state.newsCategoryRemoveReducer);

  const [search] = useOutletContext();
  const [page, setPage] = useState(0);
  const [perpage, setPerpage] = useState(5);
  const [totalPage, setTotalPage] = useState(1);
  const [itemDelete, setItemDelete] = useState([]);

  const [modal, setModal] = useState({ visible: false });
  const [isSuccess, setIsSuccess] = useState({ visible: false });
  const [isFail, setIsFail] = useState(false);
  const [counter, setCounter] = useState([]);

  const NEWS_DISABLE_BUTTON = 'news-list-disable-button ';
  const NEWS_ACTIVE_BUTTON = 'news-list-active-button ';

  useEffect(() => {
    const delay = setTimeout(() => {
      fetching();
    }, 500);
    return () => {
      clearTimeout(delay);
      dispatch(newsCategory.resetNewsCategory());
    };
  }, [search, page, perpage]);

  useEffect(() => {
    setItemDelete([]);
    if (newsCategoryReducer.meta) {
      setTotalPage(newsCategoryReducer.meta?.totalpage);
    }
    setCounter(newsCategoryReducer?.meta?.currpage * perpage + newsCategoryReducer?.data?.length);
  }, [newsCategoryReducer]);

  useEffect(() => {
    setItemDelete([]);
  }, [page, search]);

  useEffect(() => {
    if (removeReducer.type === FETCH_NEWS_CATEGORIES_REMOVE.SUCCESS) {
      setModal({ visible: +false });
      fetching();
      setIsSuccess({
        visible: +true,
        text: 'Selected category successfully deleted',
        onSubmit: () => {
          setIsSuccess({ visible: +false });
          dispatch(newsCategory.resetDeleteNewsCategory());
        }
      });
    }
    if (removeReducer.type === FETCH_NEWS_CATEGORIES_REMOVE.FAILED) {
      fetching();
      setModal({ visible: +false });
      setIsFail(true);
    }
  }, [removeReducer?.type]);

  useEffect(() => {
    if (updateReducer.type === FETCH_NEWS_CATEGORIES_UPDATE.SUCCESS) {
      setModal({ visible: +false });
      setIsSuccess({
        visible: +true,
        text: 'Selected category successfully updated',
        onSubmit: () => {
          setIsSuccess({ visible: +false });
          dispatch(newsCategory.resetUpdateNewsCategory());
        }
      });
      fetching();
    }
    if (updateReducer.type === FETCH_NEWS_CATEGORIES_UPDATE.FAILED) {
      setModal({ visible: +false });
      setIsFail(true);
      fetching();
    }
  }, [updateReducer?.type]);

  const fetching = () => {
    dispatch(
      newsCategory.fetchDataNewsCategory({
        'likeor-title.en': search,
        'likeor-title.id': search,
        currpage: page,
        perpage: perpage
      })
    );
  };
  const onPressNext = () => {
    if (page + 1 >= totalPage) {
      return;
    }
    setPage(page + 1);
    setCounter(0);
  };

  const onPressPrev = () => {
    if (page + 1 <= 1) {
      return;
    }
    setPage(page - 1);
    setCounter(0);
  };

  const getStatus = (status = false) => {
    if (status) return 'activeNews';
    else return 'inactiveNews';
  };

  const onAddDelete = (val) => {
    if (!itemDelete.some((data) => data._id === val._id)) {
      setItemDelete([...itemDelete, val]);
    } else {
      setItemDelete(itemDelete.filter((x) => x._id !== val._id));
    }
  };

  const onAddAllDelete = () => {
    if (newsCategoryReducer.data?.every((x) => itemDelete.includes(x))) {
      setItemDelete([]);
    } else
      setItemDelete(
        itemDelete.concat(
          newsCategoryReducer.data?.filter((val) => !itemDelete.includes(val)).map((x) => x)
        )
      );
  };

  const onPressUpdate = (status = true) => {
    const payload = itemDelete
      .filter((x) => x.status === status)
      .map((value) => {
        const params = {
          title: {
            en: value.title.en,
            id: value.title.id
          },
          status: value.status === true ? false : true
        };
        return { id: value._id, params };
      });
    setModal({
      visible: +true,
      textSubmit: 'UPDATE',
      onSubmit: () => dispatch(newsCategory.fetchDataUpdateNewsCategory(payload)),
      text: `Are you sure to update the status of selected news?`
    });
  };

  const onPressModal = () => {
    let text = '';
    if (itemDelete.length < 1) {
      return;
    }
    if (newsCategoryReducer.data?.every((x) => itemDelete.includes(x._id))) {
      text = 'Are you sure to delete all newss?';
    } else {
      text = `Are you sure to delete this ${newsCategoryReducer?.data
        ?.filter((x) => itemDelete.includes(x._id))
        .map((val) => val?.title?.en)
        .toLocaleString()} news?`;
    }
    setModal({
      visible: +true,
      textSubmit: 'Delete',
      onSubmit: () => dispatch(newsCategory.fetchDataRemoveNewsCategory(itemDelete)),
      text: `${text}`
    });
  };

  const onToggleStatus = (value) => {
    const params = {
      title: {
        en: value.title.en,
        id: value.title.id
      },
      status: value.status === true ? false : true,
      categories: JSON.stringify(value.categories)
    };
    setModal({
      visible: +true,
      textSubmit: 'UPDATE',
      onSubmit: () =>
        dispatch(newsCategory.fetchDataUpdateNewsCategory([{ id: value._id, params }])),
      text: `Are you sure to update the status ${value?.title?.en} of news?`
    });
  };

  const onToggleDelete = (value) => {
    setModal({
      visible: +true,
      textSubmit: 'Delete',
      onSubmit: () => dispatch(newsCategory.fetchDataRemoveNewsCategory([value])),
      text: `Are you sure to delete ${value?.title?.en} category?`
    });
  };

  return (
    <Container fluid>
      <div className="d-flex justify-content-end">
        <a
          role={'button'}
          onClick={() => {
            if (itemDelete.length < 1) {
              return;
            }
            if (itemDelete.every((x) => x.status !== false)) {
              return;
            }
            onPressUpdate(false);
          }}
          className={`d-flex align-middle align-items-center my-3 ${
            itemDelete.length > 0 ? NEWS_ACTIVE_BUTTON : NEWS_DISABLE_BUTTON
          }text-decoration-none`}>
          <FiEye className="me-2 " size={20} />
          <div className="me-3">Active</div>
        </a>
        <a
          role={'button'}
          onClick={() => {
            if (itemDelete.length < 1) {
              return;
            }
            if (itemDelete.every((x) => x.status !== true)) {
              return;
            }
            onPressUpdate(true);
          }}
          className={`d-flex align-middle align-items-center my-3 ${
            itemDelete.length > 0 ? 'news-list-inactive-button ' : NEWS_DISABLE_BUTTON
          } text-decoration-none`}>
          <FiEyeOff className="me-2 " size={20} />
          <div className="me-3">Inactive</div>
        </a>
        <a
          role={'button'}
          className={`d-flex align-middle align-items-center ${
            itemDelete.length > 0 ? 'news-list-delete ' : NEWS_DISABLE_BUTTON
          } my-3 text-decoration-none`}
          onClick={() => {
            if (itemDelete.length < 1) {
              return;
            }
            onPressModal();
          }}>
          <FiTrash className="me-2" /> <div className="me-3">Delete</div>
        </a>
      </div>
      <Table striped>
        <thead className="table-light">
          <tr>
            <th width={50} className="text-center">
              <div className="d-flex">
                <Form.Check
                  role={'button'}
                  type="checkbox"
                  id="check-all"
                  label={'No'}
                  onChange={onAddAllDelete}
                  checked={
                    newsCategoryReducer?.data?.every((x) => itemDelete?.includes(x)) ? true : false
                  }
                />
              </div>
            </th>
            <th width={'40%'}>Title</th>
            <th width={150} className="text-center">
              Contents
            </th>
            <th width={150} className="text-center">
              Status
            </th>
            <th width={150} className="text-center">
              Visit
            </th>
            <th width={150} className="text-center">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {newsCategoryReducer.isLoading && (
            <tr>
              <td colSpan={6}>
                <Loader />
              </td>
            </tr>
          )}
          {!newsCategoryReducer.isLoading &&
            newsCategoryReducer.data &&
            newsCategoryReducer.data.map((x, i) => (
              <tr key={x._id}>
                <td className="text-center">
                  <div className="d-flex">
                    <Form.Check
                      role={'button'}
                      type="checkbox"
                      id={`${x._id}`}
                      label={page * perpage + i + 1}
                      onChange={() => onAddDelete(x)}
                      checked={itemDelete.some((data) => data === x)}
                    />
                  </div>
                </td>
                <td>
                  <Link to={`${x._id}/edit`} className="link-news-text">
                    {x.title.en}
                  </Link>
                </td>
                <td className="text-center">0</td>
                <td className="align-middle align-self-center justify-content-center text-center">
                  <div className="d-flex justify-content-between">
                    <Switch
                      onColor="#66DE98"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offColor="#FFD079"
                      onChange={() => {
                        onToggleStatus(x);
                      }}
                      checked={x?.status === true ? true : false}
                    />
                    <div className={`${getStatus(x.status)} d-inline-flex`}>
                      {x.status ? 'active' : 'inactive'}
                    </div>
                  </div>
                </td>
                <td className="gap-3 align-middle align-items-center justify-content-center text-center">
                  0
                </td>
                <td className="gap-3 align-middle">
                  <div className="gap-3 d-flex justify-content-center align-items-center">
                    <FiTrash
                      role={'button'}
                      onClick={() => {
                        onToggleDelete(x);
                      }}
                      color="red"
                    />
                    <Link to={`${x._id}/edit`} className="d-inline-flex">
                      <FiEdit color="black" />
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          {!newsCategoryReducer.isLoading &&
            newsCategoryReducer.data &&
            newsCategoryReducer.data.length === 0 && (
              <tr>
                <td colSpan={6} align="center" valign="center">
                  <p>No Data</p>
                </td>
              </tr>
            )}
          {!newsCategoryReducer.isLoading && newsCategoryReducer.error && (
            <tr>
              <td colSpan={4}>
                <>{newsCategoryReducer.error}</>
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="4">
              {counter || 0} of {newsCategoryReducer.meta?.filtered || 0}
            </td>
            <td colSpan={1}>
              <div className="dropdown align-items-end">
                <div
                  role={'button'}
                  className="dropdown-toggle"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Rows per page : {perpage}
                </div>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <>
                    {[5, 10, 15, 20].map((x, i) => (
                      <li key={i.toString()}>
                        <a className="d-flex dropdown-item" onClick={() => setPerpage(x)}>
                          {x.toString()}
                        </a>
                      </li>
                    ))}
                  </>
                </ul>
              </div>
            </td>
            <td>
              <div className="float-end">
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={onPressPrev}
                  disabled={page + 1 <= 1}>
                  <FaChevronLeft size={12} />
                </Button>
                <div className="align-middle d-inline-block mx-2">
                  {page + 1} / {totalPage || 1}
                </div>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={onPressNext}
                  disabled={page + 1 >= totalPage}>
                  <FaChevronRight size={12} />
                </Button>
              </div>
            </td>
          </tr>
        </tfoot>
      </Table>
      <PopUpConfirmation
        show={modal?.visible}
        textsubmit={modal?.textSubmit}
        disabled={removeReducer.isLoading}
        onHide={() => setModal({ visible: +false })}
        {...modal}
      />
      <PopUpSuccess
        show={isSuccess.visible}
        textsubmit="OKAY"
        onSubmit={isSuccess?.onSubmit}
        text={isSuccess?.text}
      />
      <PopUpFailed
        show={isFail}
        textsubmit="Back"
        text={removeReducer?.error}
        onSubmit={() => {
          setIsFail(false);
          dispatch(newsCategory.resetDeleteNewsCategory());
        }}
      />
    </Container>
  );
};

export default NewsCategoryList;
