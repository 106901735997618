import { FETCH_TERMS_AND_CONDITIONS_UPDATE } from '../../types/termsandconditions';

const initialState = {
  data: null,
  error: null,
  meta: null,
  isLoading: false
};

export const tncUpdateReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case FETCH_TERMS_AND_CONDITIONS_UPDATE.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case FETCH_TERMS_AND_CONDITIONS_UPDATE.SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        data: payload.data,
        type: type
      };
    case FETCH_TERMS_AND_CONDITIONS_UPDATE.FAILED:
      return {
        ...initialState,
        isLoading: false,
        error: payload,
        type: type
      };
    case FETCH_TERMS_AND_CONDITIONS_UPDATE.RESET:
      return initialState;
    default:
      return state;
  }
};
