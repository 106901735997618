import { NEWS } from '../../types';

const initialState = {
  data: null,
  error: null,
  meta: null,
  isLoading: false
};

export const newsReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case NEWS.FETCH_NEWS.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case NEWS.FETCH_NEWS.SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        data: payload.data,
        meta: payload.meta
      };
    case NEWS.FETCH_NEWS.FAILED:
      return {
        ...initialState,
        isLoading: false,
        error: payload
      };
    case NEWS.FETCH_NEWS.RESET:
      return initialState;
    default:
      return state;
  }
};

export const newsMostViewedReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case NEWS.FETCH_NEWS_MOST_VIEWED.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case NEWS.FETCH_NEWS_MOST_VIEWED.SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        data: payload.data,
        meta: payload.meta
      };
    case NEWS.FETCH_NEWS_MOST_VIEWED.FAILED:
      return {
        ...initialState,
        isLoading: false,
        error: payload
      };
    case NEWS.FETCH_NEWS_MOST_VIEWED.RESET:
      return initialState;
    default:
      return state;
  }
};

export const newsDetailReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case NEWS.FETCH_NEWS_DETAIL.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case NEWS.FETCH_NEWS_DETAIL.SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        data: payload
      };
    case NEWS.FETCH_NEWS_DETAIL.FAILED:
      return {
        ...initialState,
        error: payload
      };
    case NEWS.FETCH_NEWS_DETAIL.RESET:
      return initialState;
    default:
      return state;
  }
};

export const newsAddReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case NEWS.FETCH_NEWS_ADD.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case NEWS.FETCH_NEWS_ADD.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case NEWS.FETCH_NEWS_ADD.FAILED:
      return {
        ...initialState,
        error: payload,
        type: type
      };
    case NEWS.FETCH_NEWS_ADD.RESET:
      return initialState;
    default:
      return state;
  }
};

export const newsUpdateReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case NEWS.FETCH_NEWS_UPDATE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case NEWS.FETCH_NEWS_UPDATE.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case NEWS.FETCH_NEWS_UPDATE.FAILED:
      return {
        ...initialState,
        error: payload,
        type: type
      };
    case NEWS.FETCH_NEWS_UPDATE.RESET:
      return initialState;
    default:
      return state;
  }
};

export const newsReorderReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case NEWS.FETCH_NEWS_REORDER.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case NEWS.FETCH_NEWS_REORDER.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case NEWS.FETCH_NEWS_REORDER.FAILED:
      return {
        ...initialState,
        error: payload,
        type: type
      };
    case NEWS.FETCH_NEWS_REORDER.RESET:
      return initialState;
    default:
      return state;
  }
};

export const newsRemoveReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case NEWS.FETCH_NEWS_REMOVE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case NEWS.FETCH_NEWS_REMOVE.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case NEWS.FETCH_NEWS_REMOVE.FAILED:
      return {
        ...initialState,
        error: payload,
        type: type
      };
    case NEWS.FETCH_NEWS_REMOVE.RESET:
      return initialState;
    default:
      return state;
  }
};

export const newsAddImageReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case NEWS.FETCH_NEWS_ADD_IMAGE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case NEWS.FETCH_NEWS_ADD_IMAGE.SUCCESS:
      return {
        ...initialState,
        data: payload
      };
    case NEWS.FETCH_NEWS_ADD_IMAGE.FAILED:
      return {
        ...initialState,
        error: payload
      };
    default:
      return state;
  }
};

export const newsRemoveImageReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case NEWS.FETCH_NEWS_REMOVE_IMAGE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case NEWS.FETCH_NEWS_REMOVE_IMAGE.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case NEWS.FETCH_NEWS_REMOVE_IMAGE.FAILED:
      return {
        ...initialState,
        error: payload
      };
    default:
      return state;
  }
};
