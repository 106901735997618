import api from '../../../apis';
import { errorParser } from '../../../utils';
import { FETCH_PRIVACY_POLICY_DETAIL } from '../../types/privacyPolicy';

const request = () => ({
  type: FETCH_PRIVACY_POLICY_DETAIL.REQUEST
});

const success = (payload) => ({
  type: FETCH_PRIVACY_POLICY_DETAIL.SUCCESS,
  payload
});

const failed = (error) => ({
  type: FETCH_PRIVACY_POLICY_DETAIL.FAILED,
  payload: error
});

const fetchData = (id) => async (dispatch) => {
  try {
    dispatch(request());
    const { data } = await api.privacyPolicy.getById(id);
    dispatch(success(data));
  } catch (error) {
    const message = errorParser(error);
    dispatch(failed(message));
  }
};

export { fetchData };
