import { FETCH_TERMS_AND_CONDITIONS_DELETE } from '../../types/termsandconditions';

const initialState = {
  data: null,
  error: null,
  meta: null,
  isLoading: false
};

export const tncRemoveReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case FETCH_TERMS_AND_CONDITIONS_DELETE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case FETCH_TERMS_AND_CONDITIONS_DELETE.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case FETCH_TERMS_AND_CONDITIONS_DELETE.FAILED:
      return {
        ...initialState,
        type: type,
        error: payload
      };
    case FETCH_TERMS_AND_CONDITIONS_DELETE.RESET:
      return initialState;
    default:
      return state;
  }
};
