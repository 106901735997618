export const FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST';
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_FAILED = 'FETCH_LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const FETCH_AUTH_LOGIN = {
  REQUEST: 'FETCH_AUTH_LOGIN_REQUEST',
  SUCCESS: 'FETCH_AUTH_LOGIN_SUCCESS',
  FAILED: 'FETCH_AUTH_LOGIN_FAILED',
  RESET_TYPE: 'FETCH_AUTH_LOGIN_RESET_TYPE'
};

export const FETCH_SEND_OTP = {
  REQUEST: 'FETCH_SEND_OTP_REQUEST',
  SUCCESS: 'FETCH_SEND_OTP_SUCCESS',
  FAILED: 'FETCH_SEND_OTP_FAILED'
};

export const FETCH_VERIFY_OTP = {
  REQUEST: 'FETCH_VERIFY_OTP_REQUEST',
  SUCCESS: 'FETCH_VERIFY_OTP_SUCCESS',
  FAILED: 'FETCH_VERIFY_OTP_FAILED'
};
