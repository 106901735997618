import api from '../../../apis';
import { errorParser } from '../../../utils';

import { FORGOT } from '../../types';

const { FETCH_FORGOT_FAILED, FETCH_FORGOT_REQUEST, FETCH_FORGOT_SUCCESS } = FORGOT;

export const fetchForgotRequest = () => ({
  type: FETCH_FORGOT_REQUEST
});

export const fetchForgotSuccess = (payload) => ({
  type: FETCH_FORGOT_SUCCESS,
  payload
});

export const fetchForgotFail = (error) => ({
  type: FETCH_FORGOT_FAILED,
  payload: error
});

export const fetchDataForgot =
  ({ email, captcha }) =>
  async (dispatch) => {
    try {
      dispatch(fetchForgotRequest());
      const { data } = await api.auth.forgotPassword({ email, captcha });
      dispatch(fetchForgotSuccess(data));
    } catch (error) {
      const message = errorParser(error);
      dispatch(fetchForgotFail(message));
    }
  };
