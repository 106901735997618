import { INFORMATION } from '../../types';

const initialState = {
  data: null,
  error: null,
  meta: null,
  isLoading: false
};

export const informationReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case INFORMATION.FETCH_INFORMATION.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case INFORMATION.FETCH_INFORMATION.SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        data: payload.data,
        meta: payload.meta
      };
    case INFORMATION.FETCH_INFORMATION.FAILED:
      return {
        ...initialState,
        isLoading: false,
        error: payload
      };
    case INFORMATION.FETCH_INFORMATION.RESET:
      return initialState;
    default:
      return state;
  }
};

export const informationDetailReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case INFORMATION.FETCH_INFORMATION_DETAIL.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case INFORMATION.FETCH_INFORMATION_DETAIL.SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        data: payload
      };
    case INFORMATION.FETCH_INFORMATION_DETAIL.FAILED:
      return {
        ...initialState,
        error: payload
      };
    case INFORMATION.FETCH_INFORMATION_DETAIL.RESET:
      return initialState;
    default:
      return state;
  }
};

export const informationAddReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case INFORMATION.FETCH_INFORMATION_ADD.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case INFORMATION.FETCH_INFORMATION_ADD.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case INFORMATION.FETCH_INFORMATION_ADD.FAILED:
      return {
        ...initialState,
        error: payload,
        type: type
      };
    case INFORMATION.FETCH_INFORMATION_ADD.RESET:
      return initialState;
    default:
      return state;
  }
};

export const informationUpdateReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case INFORMATION.FETCH_INFORMATION_UPDATE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case INFORMATION.FETCH_INFORMATION_UPDATE.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case INFORMATION.FETCH_INFORMATION_UPDATE.FAILED:
      return {
        ...initialState,
        error: payload,
        type: type
      };
    case INFORMATION.FETCH_INFORMATION_UPDATE.RESET:
      return initialState;
    default:
      return state;
  }
};

export const informationReorderReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case INFORMATION.FETCH_INFORMATION_REORDER.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case INFORMATION.FETCH_INFORMATION_REORDER.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case INFORMATION.FETCH_INFORMATION_REORDER.FAILED:
      return {
        ...initialState,
        error: payload,
        type: type
      };
    case INFORMATION.FETCH_INFORMATION_REORDER.RESET:
      return initialState;
    default:
      return state;
  }
};

export const informationRemoveReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case INFORMATION.FETCH_INFORMATION_REMOVE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case INFORMATION.FETCH_INFORMATION_REMOVE.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case INFORMATION.FETCH_INFORMATION_REMOVE.FAILED:
      return {
        ...initialState,
        error: payload,
        type: type
      };
    case INFORMATION.FETCH_INFORMATION_REMOVE.RESET:
      return initialState;
    default:
      return state;
  }
};

export const informationAddImageReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case INFORMATION.FETCH_INFORMATION_ADD_IMAGE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case INFORMATION.FETCH_INFORMATION_ADD_IMAGE.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type
      };
    case INFORMATION.FETCH_INFORMATION_ADD_IMAGE.FAILED:
      return {
        ...initialState,
        error: payload,
        type
      };
    default:
      return state;
  }
};

export const informationRemoveImageReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case INFORMATION.FETCH_INFORMATION_REMOVE_IMAGE.REQUEST:
      return {
        ...initialState,
        isLoading: true
      };
    case INFORMATION.FETCH_INFORMATION_REMOVE_IMAGE.SUCCESS:
      return {
        ...initialState,
        data: payload,
        type: type
      };
    case INFORMATION.FETCH_INFORMATION_REMOVE_IMAGE.FAILED:
      return {
        ...initialState,
        error: payload,
        type
      };
    default:
      return state;
  }
};
