import React from 'react';
import { Container, Row, Col, Nav, NavDropdown, Image } from 'react-bootstrap';
import { FaWhatsapp, FaRegEnvelope, FaRegMap, FaChevronRight } from 'react-icons/fa';
import { TbCalendarStats } from 'react-icons/tb';
import { logo } from '../../assets';
import { useTranslation } from 'react-i18next';
import './index.css';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="text-lg-start text-muted footer-background">
      <Container className={`text-md-start ${location.pathname !== '/' && 'mt-5'} pt-5 g-0`}>
        <Row className="mt-3">
          <Col xs="12" sm="12" md="12" lg="2" xl="2" className="mx-auto mb-4">
            <Image src={logo} alt="logo" fluid />
          </Col>

          <Col xs="12" sm="6" md="6" lg="2" xl="2" className="mx-auto mb-4">
            <h6 className="fw-bold mb-4 ">{t('LANG-012')}</h6>
            <Nav.Link href="/" className="mb-3 text-footer-item">
              {t('LANG-001')}
            </Nav.Link>
            <NavDropdown
              title={
                <span>
                  {t('LANG-002')}
                  <FaChevronRight className="ms-1" size={10} />
                </span>
              }
              id="about"
              className="mb-3 text-footer-item">
              <NavDropdown.Item href="/about">{t('LANG-008')}</NavDropdown.Item>
              <NavDropdown.Item href="/about/services">{t('LANG-009')}</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/news" className="mb-3 text-footer-item">
              {t('LANG-003')}
            </Nav.Link>
            <NavDropdown
              title={
                <span>
                  {t('LANG-004')}
                  <FaChevronRight className="ms-1" size={10} />
                </span>
              }
              id="help"
              className="mb-3 text-footer-item">
              <NavDropdown.Item href="/faq">{t('LANG-010')}</NavDropdown.Item>
              <NavDropdown.Item href="/guides">{t('LANG-011')}</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/contact" className="mb-3 text-footer-item">
              {t('LANG-005')}
            </Nav.Link>
            <NavDropdown
              title={
                <span>
                  Legal
                  <FaChevronRight className="ms-1" size={10} />
                </span>
              }
              id="help"
              className="mb-3 text-footer-item">
              <NavDropdown.Item href="/terms-and-conditions">
                {t('AUTH-LOGIN-016')}
              </NavDropdown.Item>
              <NavDropdown.Item href="/privacy-policy">{t('AUTH-LOGIN-017')}</NavDropdown.Item>
            </NavDropdown>
          </Col>

          <Col xs="12" sm="6" md="6" lg="2" xl="2" className="mx-auto mb-4">
            <h6 className="fw-bold mb-4">{t('LANG-013')}</h6>
            <Nav.Link href="/login" className="mb-3 text-footer-item">
              {t('LANG-006')}
            </Nav.Link>
            {/* <Nav.Link href="/register" className="mb-3 text-footer-item">
              {t('LANG-007')}
            </Nav.Link> */}
            <Nav.Link href="/guides" className="mb-3 text-footer-item">
              {t('LANG-011')}
            </Nav.Link>
          </Col>

          <Col xs="12" sm="6" md="6" lg="3" xl="3" className="mx-auto mb-4">
            <h6 className="fw-bold mb-4">{t('LANG-014')}</h6>
            <Row className="mb-3 text-footer-item">
              <Col xs="1" sm="1" md="1" lg="1" xl="1">
                <FaWhatsapp className="me-2" />
              </Col>
              <Col
                as="a"
                href="https://wa.me/6282114070007"
                target="_blank"
                className="text-decoration-none text-reset">
                +(62) 8211 4070 007
              </Col>
            </Row>
            <Row className="mb-3 text-footer-item">
              <Col xs="1" sm="1" md="1" lg="1" xl="1">
                <FaRegEnvelope className="me-3" />
              </Col>
              <Col>
                <a
                  className="text-decoration-none text-reset"
                  href="mailto:info.partner@telkom.co.id">
                  info.partner@telkom.co.id
                </a>
              </Col>
            </Row>
            <Row className="mb-3 text-footer-item">
              <Col xs="1" sm="1" md="1" lg="1" xl="1">
                <TbCalendarStats className="me-3" size={18} />
              </Col>
              <Col>
                <div>
                  <p>Monday to Friday </p>
                  <p>08:00 AM to 05:00 PM GMT+7</p>
                </div>
              </Col>
            </Row>
          </Col>

          <Col xs="12" sm="6" md="6" lg="3" xl="3" xxl="3" className="mx-auto mb-md-0 mb-4 g-0">
            <h6 className="fw-bold mb-4">{t('LANG-015')}</h6>
            <Row
              as="a"
              href="https://goo.gl/maps/A9ce96Nk5tPVM3rKA"
              className="text-decoration-none text-reset"
              target="_blank">
              <Col xs="1" sm="1" md="1" lg="1" xl="1" className="text-footer-item">
                <FaRegMap className="me-3" />
              </Col>
              <Col className="text-footer-item text">
                {`PT Telekomunikasi Indonesia (PERSERO), Tbk \n (Telkom Indonesia)\nDigital Business & Technology Division\nJl. Kebon Sirih no. 12,\n Central Jakarta, Indonesia,\n10110`}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <div className="text-center p-4">
        <div className="mx-auto w-75 border-top" />
        <br />© {new Date().getFullYear()} All Right Reserved.&nbsp;
        <a className="text-reset text-decoration-none" href={process.env.REACT_APP_BASE_URL}>
          Telkom Partner Network
        </a>
      </div>
    </div>
  );
};

export default Footer;
