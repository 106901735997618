export const FETCH_PARTNER = {
  REQUEST: 'FETCH_PARTNER_REQUEST',
  SUCCESS: 'FETCH_PARTNER_SUCCESS',
  FAILED: 'FETCH_PARTNER_FAILED',
  RESET: 'FETCH_PARTNER_RESET'
};

export const FETCH_PARTNER_DETAIL = {
  REQUEST: 'FETCH_PARTNER_DETAIL_REQUEST',
  SUCCESS: 'FETCH_PARTNER_DETAIL_SUCCESS',
  FAILED: 'FETCH_PARTNER_DETAIL_FAILED',
  RESET: 'FETCH_PARTNER_DETAIL_RESET'
};

export const FETCH_PARTNER_ADD = {
  REQUEST: 'FETCH_PARTNER_ADD_REQUEST',
  SUCCESS: 'FETCH_PARTNER_ADD_SUCCESS',
  FAILED: 'FETCH_PARTNER_ADD_FAILED',
  RESET: 'FETCH_PARTNER_ADD_RESET'
};

export const FETCH_PARTNER_UPDATE = {
  REQUEST: 'FETCH_PARTNER_UPDATE_REQUEST',
  SUCCESS: 'FETCH_PARTNER_UPDATE_SUCCESS',
  FAILED: 'FETCH_PARTNER_UPDATE_FAILED',
  RESET: 'FETCH_PARTNER_UPDATE_RESET'
};

export const FETCH_PARTNER_REORDER = {
  REQUEST: 'FETCH_PARTNER_REORDER_REQUEST',
  SUCCESS: 'FETCH_PARTNER_REORDER_SUCCESS',
  FAILED: 'FETCH_PARTNER_REORDER_FAILED',
  RESET: 'FETCH_PARTNER_REORDER_RESET'
};

export const FETCH_PARTNER_REMOVE = {
  REQUEST: 'FETCH_PARTNER_REMOVE_REQUEST',
  SUCCESS: 'FETCH_PARTNER_REMOVE_SUCCESS',
  FAILED: 'FETCH_PARTNER_REMOVE_FAILED',
  RESET: 'FETCH_PARTNER_REMOVE_RESET'
};

export const FETCH_PARTNER_ADD_IMAGE = {
  REQUEST: 'FETCH_PARTNER_ADD_IMAGE_REQUEST',
  SUCCESS: 'FETCH_PARTNER_ADD_IMAGE_SUCCESS',
  FAILED: 'FETCH_PARTNER_ADD_IMAGE_FAILED'
};

export const FETCH_PARTNER_REMOVE_IMAGE = {
  REQUEST: 'FETCH_PARTNER_REMOVE_IMAGE_REQUEST',
  SUCCESS: 'FETCH_PARTNER_REMOVE_IMAGE_SUCCESS',
  FAILED: 'FETCH_PARTNER_REMOVE_IMAGE_FAILED'
};
