import {
  loginUrl,
  registerUrl,
  forgotPasswordUrl,
  resetPasswordUrl,
  resendActivationUrl,
  activationUrl,
  reqOtpUrl,
  verifyOtpUrl,
  checkOtpUrl,
  registerInternalUrl,
  verifyRegisterInternalUrl
} from './constant';
import { httpHelper } from '../utils';

export const login = ({ email, password, captcha }) =>
  httpHelper.post(loginUrl, {
    email,
    password,
    apps: 'tpn',
    reCaptchaResponse: captcha
  });

export const register = (payload) => httpHelper.post(registerUrl, payload);

export const registerInternal = (payload, token) =>
  httpHelper.post(`${registerInternalUrl}/${token}`, payload);

export const forgotPassword = ({ email, captcha }) =>
  httpHelper.post(forgotPasswordUrl, {
    email,
    reCaptchaResponse: captcha
  });

export const resetPassword = ({ uuid, email, password }) =>
  httpHelper.post(`${resetPasswordUrl}/${uuid}?email=${email}`, { password });

export const resendActivation = ({ email }) =>
  httpHelper.post(`${resendActivationUrl}/?email=${email}`);

export const activation = ({ token, email }) =>
  httpHelper.post(`${activationUrl}/?token=${token}&email=${email}`);

export const requestOtp = (data) => httpHelper.post(reqOtpUrl, data, { noToken: true });

export const verifyOtp = (data) => httpHelper.post(verifyOtpUrl, data, { noToken: true });

export const checkOtp = (data) => httpHelper.post(checkOtpUrl, data, { noToken: true });

export const verifyRegisterInternal = (token) =>
  httpHelper.get(`${verifyRegisterInternalUrl}/${token}`);
