/* eslint-disable react/no-children-prop */
import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { news, newsCategory } from '../../../../stores/actions';
import { FETCH_NEWS_UPDATE } from '../../../../stores/types/news';
import {
  FilePicker,
  Input,
  PopUpConfirmation,
  PopUpFailed,
  PopUpSuccess,
  RichEditor
} from '../../../../components';
import ImagePreview from '../Components/PickedImage';
import { shorthand } from '../../../../utils';
import ReactMarkdown from 'react-markdown';

const NewsEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const reducer = useSelector((state) => state.newsUpdateReducer);
  const detailReducer = useSelector((state) => state.newsDetailReducer);
  const categoryReducer = useSelector((state) => state.newsCategoryReducer);
  // state for modal
  const [modalConfirmation, setModalConfirmation] = useState({
    show: false,
    text: '',
    textSubmit: '',
    disabled: null,
    onSubmit: () => null
  });
  const [isSuccess, setIsSuccess] = useState({
    show: false,
    textSubmit: 'OKAY',
    onSubmit: () => {
      setIsSuccess(false);
      dispatch(news.resetUpdateNews());
      navigate(-1);
    },
    text: 'You can change news status that you published by edit the news'
  });
  const [isFail, setIsFail] = useState({
    show: false
  });

  // state for validation
  const [canUpload, setCanUpload] = useState(false);

  // input
  const [titleEng, setTitleEng] = useState('');
  const [detailEng, setDetailEng] = useState('');
  const [titleIna, setTitleIna] = useState('');
  const [detailIna, setDetailIna] = useState('');
  const [categories, setCategories] = useState('');
  const [status, setStatus] = useState('Inactive');
  const [date, setDate] = useState(new Date());
  const [created, setCreated] = useState(new Date());
  const [author, setAuthor] = useState('');
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [convert, setConverted] = useState(false);
  const [tempDescIna, setTempDescIna] = useState('');
  const [tempDescEng, setTempDescEng] = useState('');
  const fileRef = useRef();

  const attachmentConfirmDefault = {
    show: true,
    text: `Are you sure to delete this image?`,
    textSubmit: 'YES, DELETE',
    disabled: reducer?.isLoading,
    onSubmit: () => submitRemoveAttachment()
  };

  const updateConfirmDefault = {
    show: true,
    text: `Are you sure to update ${detailReducer?.data?.title?.en}?`,
    textSubmit: 'YES, UPDATE',
    disabled: reducer?.isLoading,
    onSubmit: () => submit()
  };

  const updateSuccessDefault = {
    show: true,
    textSubmit: 'OKAY',
    onSubmit: () => {
      setIsSuccess(false);
      dispatch(news.resetUpdateNews());
      navigate(-1);
    },
    text: 'You can change news status that you published by edit the news'
  };

  const attachmentFailDefault = {
    show: true,
    text: 'Atleast 1 image needed'
  };

  const updateFailDefault = {
    show: true,
    text: reducer.error
  };

  useEffect(() => {
    const params = {
      perpage: 100,
      'filter-type': 'news',
      'bool-status': true
    };
    dispatch(newsCategory.fetchDataNewsCategory(params));
    return () => {
      dispatch(newsCategory.resetNewsCategory());
    };
  }, []);

  useEffect(() => {
    const delay = setTimeout(() => {
      dispatch(news.fetchDataDetailNews(id));
    }, 500);
    return () => {
      clearTimeout(delay);
      dispatch(news.resetDetailNews());
    };
  }, []);

  useEffect(() => {
    if (detailReducer.data) {
      setTitleEng(detailReducer.data.title.en);
      setTitleIna(detailReducer.data.title.id);
      setDetailEng(detailReducer.data.description.en);
      setDetailIna(detailReducer.data.description.id);
      setCategories(detailReducer?.data?.categories[0]?._id);
      setAuthor(detailReducer?.data?.author);
      setStatus(detailReducer?.data?.status ? 'Active' : 'Inactive');
      setImages(detailReducer?.data?.media);
      setDate(new Date(detailReducer?.data?.published.at));
      setCreated(new Date(detailReducer?.data?.created.at));
    }
  }, [detailReducer.data]);

  useEffect(() => {
    const el = document.querySelector('.react-markdown-eng');
    const id = document.querySelector('.react-markdown-ind');
    if (detailReducer.data) {
      if (el && shorthand.isMarkdown(detailReducer.data.description.en)) {
        const mdHTML = el.innerHTML;
        setTempDescEng(mdHTML);
      }
      if (id && shorthand.isMarkdown(detailReducer.data.description.id)) {
        const idHTML = id.innerHTML;
        setTempDescIna(idHTML);
      }
      setConverted(true);
    }
  }, [detailReducer.data]);

  useEffect(() => {
    if (convert === true) {
      if (tempDescEng) setDetailEng(tempDescEng);
      if (tempDescIna) setDetailIna(tempDescIna);
    }
  }, [convert]);

  useEffect(() => {
    if (selectedImage !== null) {
      setModalConfirmation(attachmentConfirmDefault);
    }
  }, [selectedImage]);

  const submit = () => {
    const params = {
      title: {
        en: titleEng,
        id: titleIna
      },
      status: status === 'Active',
      description: {
        en: detailEng,
        id: detailIna
      },
      categories: [categories],
      author: author,
      published: {
        at: date
      }
    };
    if (images.some((x) => x?.file)) {
      const form = new FormData();
      images.filter((x) => x?.file).forEach((x) => form.append('media', x.file));
      dispatch(news.fetchDataAddImageNews(detailReducer?.data?._id, form));
    }
    dispatch(news.fetchDataUpdateNews([{ id: detailReducer?.data?._id, params }]));
  };

  const submitRemoveAttachment = () => {
    if (images.length === 1) {
      setModalConfirmation({ show: false });
      setIsFail(attachmentFailDefault);
      return;
    }
    if (images[selectedImage]?.url) {
      dispatch(
        news.fetchDataRemoveImageNews(
          detailReducer?.data?._id,
          detailReducer?.data?.media?.[selectedImage]?._id
        )
      );
    }
    setImages((prev) => prev.filter((_, i) => i !== selectedImage));
    setSelectedImage(null);
    setModalConfirmation({ show: false });
  };

  useEffect(() => {
    if (reducer.type === FETCH_NEWS_UPDATE.SUCCESS) {
      setModalConfirmation({ show: false });
      setIsSuccess(updateSuccessDefault);
    }
    if (reducer.type === FETCH_NEWS_UPDATE.FAILED) {
      setModalConfirmation({ show: false });
      setIsFail(updateFailDefault);
    }
    dispatch(news.resetUpdateNews());
  }, [reducer?.type]);

  useEffect(() => {
    setCanUpload(validate());
  }, [titleEng, detailEng, titleIna, detailIna, categories, author, images]);

  const fileRead = (val) => {
    setImages((prev) => [...prev, { url: URL.createObjectURL(val), file: val }]);
  };

  const validate = () => {
    if (!titleEng) {
      return false;
    }
    if (!detailEng) {
      return false;
    }
    if (!titleIna) {
      return false;
    }
    if (!detailIna) {
      return false;
    }
    if (!categories) {
      return false;
    }
    if (!author) {
      return false;
    }
    if (!images?.length > 0) {
      return false;
    }
    return true;
  };

  return (
    <>
      <ReactMarkdown
        children={detailReducer?.data?.description?.en}
        className="react-markdown-eng d-none"
      />
      <ReactMarkdown
        children={detailReducer?.data?.description?.id}
        className="react-markdown-ind d-none"
      />
      <Container fluid>
        <Form className="my-5">
          <Row className="my-3">
            <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
              <Form.Group>
                <Form.Label className="fw-bold" style={{ color: '#5C5E61' }}>
                  News Created Date<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={created.toISOString().substring(0, 10)}
                  disabled></Form.Control>
              </Form.Group>
            </Col>
            <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
              <Form.Group>
                <Form.Label className="fw-bold" style={{ color: '#5C5E61' }}>
                  News Published Date<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={date.toISOString().substring(0, 10)}
                  onChange={(e) => setDate(new Date(e.target.value))}></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="my-3">
            <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
              <Form.Group>
                <Form.Label className="fw-bold" style={{ color: '#5C5E61' }}>
                  Category of News<span className="text-danger">*</span>
                </Form.Label>
                <Form.Select onChange={(x) => setCategories(x.target.value)} value={categories}>
                  {categoryReducer.data?.map((x) => (
                    <option key={x._id} value={x._id}>
                      {x.title.en}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
              <Form.Group>
                <Input
                  label="Author"
                  text={author}
                  required={true}
                  onChange={(e) => setAuthor(e.target.value)}
                  error="Please fill the author’s name"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="my-4">
            <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
              <Form.Group>
                <Input
                  label="Title (English)"
                  text={titleEng}
                  required={true}
                  onChange={(e) => setTitleEng(e.target.value)}
                  error="Please fill the news title in english"
                  as="textarea"
                />
              </Form.Group>
            </Col>
            <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
              <Form.Group>
                <Input
                  label="Title (Bahasa)"
                  text={titleIna}
                  required={true}
                  onChange={(e) => setTitleIna(e.target.value)}
                  error="Please fill the news title in bahasa"
                  as="textarea"
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="my-3">
            <RichEditor
              required
              label="Details (English)"
              value={detailEng}
              isValid={detailEng !== ''}
              error="Please Input Your Details"
              onChangeText={setDetailEng}
            />
          </div>
          <div className="my-3">
            <RichEditor
              required
              label="Details (Indonesia)"
              value={detailIna}
              isValid={detailIna !== ''}
              error="Tolong masukan deskripsi anda"
              onChangeText={setDetailIna}
            />
          </div>
          <Row>
            <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
              <FilePicker
                ref={fileRef}
                required
                onSuccess={(val) => {
                  fileRead(val);
                }}
                text={detailReducer?.data?.media?.[0]?.newFileName}
              />
            </Col>
            <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
              <Form.Group>
                <Form.Label className="fw-bold" style={{ color: '#5C5E61' }}>
                  Status<span className="text-danger">*</span>
                </Form.Label>
                <Form.Select onChange={(x) => setStatus(x.target.value)} value={status}>
                  <option value="Inactive">Inactive</option>
                  <option value="Active">Active</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {images.map((x, i) => (
              <React.Fragment key={i.toString}>
                {x?.url && (
                  <ImagePreview
                    index={i}
                    src={x?.url}
                    key={x}
                    onDeletePress={(z) => {
                      if (images[z]?.file) {
                        setImages((prev) => prev.filter((y) => x.url !== y.url));
                        setSelectedImage(null);
                      } else {
                        setSelectedImage(z);
                      }
                    }}
                    onPreviewPress={(y) => {
                      if (images[y].url) {
                        window.open(images[y].url, '_blank');
                      } else {
                        window.open(images[y], '_blank');
                      }
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </Row>
          <div className="d-inline-flex float-end py-5 gap-2">
            <Button
              type="button"
              className="btn btn-secondary py-2 px-3"
              onClick={() => {
                setModalConfirmation({
                  show: true,
                  text: `Are you sure to cancel update?`,
                  textSubmit: 'YES',
                  textcancel: 'NO',
                  onSubmit: () => navigate(-1)
                });
              }}>
              CANCEL
            </Button>
            <Button
              type="button"
              className="py-2 px-3"
              disabled={!canUpload}
              onClick={() => {
                setModalConfirmation(updateConfirmDefault);
              }}
              style={{ backgroundColor: '#E42312' }}>
              UPDATE
            </Button>
          </div>
        </Form>
        <PopUpConfirmation
          show={modalConfirmation.show}
          text={modalConfirmation.text}
          textsubmit={modalConfirmation.textSubmit}
          onHide={() => setModalConfirmation(false)}
          disabled={modalConfirmation.disabled}
          onSubmit={modalConfirmation.onSubmit}
          textcancel={modalConfirmation.textcancel}
        />
        <PopUpSuccess
          show={isSuccess.show}
          textsubmit="OKAY"
          onSubmit={() => {
            setIsSuccess(false);
            dispatch(news.resetUpdateNews());
            navigate(-1);
          }}
          text="You can change news status that you published by edit the news"
        />
        <PopUpFailed
          show={isFail.show}
          textsubmit="Back"
          text={isFail.text}
          onSubmit={() => {
            setIsFail({ show: false });
            setSelectedImage(null);
            dispatch(news.resetUpdateNews());
          }}
        />
      </Container>
    </>
  );
};

export default NewsEdit;
