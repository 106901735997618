import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function useNavigateWithFallback() {
  const navigate = useNavigate();
  const location = useLocation();
  const [previousLocation, setPreviousLocation] = useState(null);

  useEffect(() => {
    // Update previous location on every navigation
    setPreviousLocation(location);
  }, [location]);

  const navigateBack = () => {
    if (previousLocation && previousLocation.pathname !== location.pathname) {
      navigate(-1); // Go back to the previous page
    } else {
      navigate('/'); // Navigate to the main page if no previous page
    }
  };

  return navigateBack;
}

export default useNavigateWithFallback;
