import React, { useEffect, useState } from 'react';
import { resetBackground, resetPasswordSuccess } from '../../assets';
import { Col, Form, Button, InputGroup, Spinner } from 'react-bootstrap';
import { BiLockAlt } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword as resetPasswordAction } from '../../stores/actions';
import Swal from 'sweetalert2';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useForm } from '../../hooks';
import { InformationScreen } from '../../components';

const ResetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [searchParams] = useSearchParams();
  const resetReducer = useSelector((state) => state.resetReducer);
  const { isLoading, error, data } = resetReducer;

  const [password, setPassword, passwordValid, passwordTouched, setPasswordTouched] = useForm(
    null,
    (x) => x
  );
  const [
    confirmPassword,
    setConfirmPassword,
    confirmPasswordValid,
    confirmPasswordTouched,
    setConfirmPasswordTouched,
    ConfirmPasswordError
  ] = useForm(null, (x) => x && x === password);
  const [showPassword, setShowPassword] = useState('password');
  const [showConfirmPassword, setShowConfirmPassword] = useState('password');
  const [isValid, setIsValid] = useState(false);

  const handleShowPassword = (type) => {
    if (type === 'password') {
      if (showPassword === 'password') {
        return setShowPassword('text');
      }
      return setShowPassword('password');
    }
    if (type === 'confirm') {
      if (showConfirmPassword === 'password') {
        return setShowConfirmPassword('text');
      }
      return setShowConfirmPassword('password');
    }
    return;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPasswordTouched(true);
    setConfirmPasswordTouched(true);
    if (!isValid) {
      return false;
    }
    dispatch(
      resetPasswordAction.fetchDataReset({
        uuid,
        email: decodeURI(searchParams.get('email')),
        password
      })
    );
    return;
  };

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error
      }).then(() => {
        dispatch(resetPasswordAction.fetchResetFail(null));
      });
    }
  }, [error, data]);

  useEffect(() => {
    setIsValid(passwordValid && confirmPasswordValid);
  }, [password, /*captcha,*/ confirmPassword]);

  const renderSuccess = () => (
    <InformationScreen
      title="Password Updated"
      image={resetPasswordSuccess}
      caption="Your password has been successfully updated. Please login using your new password."
      button={{
        text: t('LANG-006'),
        action: () => navigate('/login')
      }}
    />
  );

  return (
    <>
      {data && renderSuccess()}
      {!data && (
        <div className="d-flex flex-row row-container-form">
          <Col xxl="6" xl="6" lg="6" className="container-image-bg-reset">
            <div
              className="image-cover-fluid"
              style={{ '--img': `url(${resetBackground})`, height: '91.5vh' }}>
              <div
                className="image-overlay"
                style={{ height: '91.5vh', backgroundColor: 'transparent' }}>
                <div className="image-caption">Reset Password</div>
                <div className="image-description mt-4">Please create your new password.</div>
              </div>
            </div>
          </Col>
          <Col className="p-5 col-container-form" xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <h1 className="mx-lg-5 px-xl-5 fw-bold">{t('LANG-044')}</h1>
            <Form className="my-5 mx-lg-5 px-xl-5" onSubmit={handleSubmit}>
              <Form.Group className="my-3" controlId="formBasicPassword">
                <Form.Label className="fw-semibold">
                  {t('LANG-022')}&nbsp;<span className="text-danger">*</span>
                </Form.Label>
                <InputGroup
                  className={`${
                    !passwordValid && passwordTouched ? 'border border-danger rounded' : ''
                  }`}>
                  <InputGroup.Text className="bg-transparent border-end-0">
                    <BiLockAlt />
                  </InputGroup.Text>
                  <Form.Control
                    type={showPassword}
                    placeholder={t('LANG-032')}
                    className="border-end-0 border-start-0"
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={() => setPasswordTouched(true)}
                  />
                  <InputGroup.Text
                    className="bg-transparent border-start-0"
                    onClick={() => handleShowPassword('password')}
                    role="button">
                    {showPassword === 'password' ? <FaRegEyeSlash /> : <FaRegEye />}
                  </InputGroup.Text>
                </InputGroup>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('LANG-035').replace(
                      /text-muted/g,
                      !passwordValid && passwordTouched ? 'text-danger' : 'text-muted'
                    )
                  }}
                />
              </Form.Group>
              <Form.Group className="my-3" controlId="formBasicConfirmPassword">
                <Form.Label className="fw-semibold">
                  {t('LANG-028')}&nbsp;<span className="text-danger">*</span>
                </Form.Label>
                <InputGroup
                  className={`${
                    !confirmPasswordValid && confirmPasswordTouched
                      ? 'border border-danger rounded'
                      : ''
                  }`}>
                  <InputGroup.Text className="bg-transparent border-end-0">
                    <BiLockAlt />
                  </InputGroup.Text>
                  <Form.Control
                    type={showConfirmPassword}
                    placeholder={t('LANG-033')}
                    className="border-end-0 border-start-0"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onBlur={() => setConfirmPasswordTouched(true)}
                  />
                  <InputGroup.Text
                    className="bg-transparent border-start-0"
                    onClick={() => handleShowPassword('confirm')}
                    role="button">
                    {showConfirmPassword === 'password' ? <FaRegEyeSlash /> : <FaRegEye />}
                  </InputGroup.Text>
                </InputGroup>
                <ConfirmPasswordError message="Please re-enter your password correctly" />
              </Form.Group>
              <div className="text-center">
                <Button
                  variant="danger"
                  type="submit"
                  className="text-uppercase banner-button fw-bold">
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      &nbsp;Loading...
                    </>
                  ) : (
                    t('LANG-045')
                  )}
                </Button>
                <br />
                <Form.Label className="my-3 justify-content-center text-secondary">
                  {t('LANG-042')}&nbsp;
                  <a href="/login" className="text-decoration-none text-danger fw-bold">
                    {t('LANG-006')}
                  </a>
                </Form.Label>
              </div>
            </Form>
          </Col>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
